import {createSlice} from "@reduxjs/toolkit";

const commentToggle = createSlice({
    name: "commentToggle",
    initialState: {
        show: false,
        id: null,
    },
    reducers: {
        setShowComment: (state, action) => {
            state.show = action.payload.show;
            state.id = action.payload.id;
        }
    }
});

const {reducer, actions} = commentToggle;
export const {setShowComment} = actions;
export default reducer;

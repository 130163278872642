import Axios from "../../../Lib/Axios";
import {getSkipIds, pushCommentToList} from "../../Actions/CommentList";
import fa from "react-timeago/lib/language-strings/fa";


export const createCommentPost = (id, content, userIds, part, parent) => {
    return Axios.post(`comment/post/${id}`, {
        comment: content, userIds, part, parent
    })
}

export const createCommentVideo = (id, content, userIds, part, parent) => {
    return Axios.post(`comment/video/${id}`, {
        comment: content, userIds, part, parent
    })
}
export const likeRequet = (id) => {
    return Axios.get(`comments/like/${id}`).then(result => {
        return result;
    }).catch(function (error) {
        return error.response;
    });
}
export const loadCommentRequet = (postID, lastID) => {

    return Axios.get(`comments/list-comment/${postID}/${lastID}`);
}
export const mentionsearch = (name) => {
    return Axios.get(`mention/search?name=${name}`)
}
let loadingCommnet = false;
export const commentPostList = async (postId, parent = 0, lastId = 0) => {
    let skipId = await getSkipIds();
    if (loadingCommnet) {
        return false;
    }
    loadingCommnet = true;
    return Axios.post(`comment/post/list/${postId}/${parent}/${lastId}`, {skipId}).then(result => {
        let list = [];
        for (let i = 0; i < result.data.list.length; i++) {
            let item = result.data.list[i];
            item.child = [];
            list.push(item)
        }

        pushCommentToList(list, parent);
        if (result.data.list.length < result.data.limit) {
            return 2;
        }
        return 1;
    }).catch(error => {
        return 3;
    }).finally(() => {
        loadingCommnet = false;
    })
}

export const commentVideotList = async (postId, parent = 0, lastId = 0) => {
    let skipId = await getSkipIds();
    if (loadingCommnet) {
        return false;
    }
    loadingCommnet = true;
    return Axios.post(`comment/video/list/${postId}/${parent}/${lastId}`, {skipId}).then(result => {
        let list = [];
        for (let i = 0; i < result.data.list.length; i++) {
            let item = result.data.list[i];
            item.child = [];
            list.push(item)
        }
        if (!list.length) {
            return false;
        }
        pushCommentToList(list, parent);
        if (result.data.list.length < result.data.limit) {
            return 2;
        }
        return 1;
    }).catch(error => {
        return 3;
    }).finally(() => {
        loadingCommnet = false;
    })
}


import React, {useCallback, useEffect, useMemo, useRef, useState} from 'react';
import {
    ContentState,
    convertFromHTML,
    convertFromRaw,
    convertToRaw,
    EditorState,
    Modifier,
    genKey
} from 'draft-js';
import {stateToHTML} from 'draft-js-export-html';
import 'draft-js/dist/Draft.css';
import Editor from '@draft-js-plugins/editor';
import createMentionPlugin, {
    defaultSuggestionsFilter,
} from '@draft-js-plugins/mention';
import '@draft-js-plugins/mention/lib/plugin.css';
import {mentionsearch} from "./CommentRequet";
import UploadMediaComment from "./UploadMediaComment";
import MediaComment from "./MediaComment";
import {deleteChunk} from "../FormPostComponent/ChunkFile";
import {getUserAuth, setShowAuth} from "../../Actions/authAction";
import IconClose from "../Icon/IconClose";
import {Link} from "react-router-dom";
import APP_CONSTANTS from "../../../Constant";
import htmlToDraft from 'html-to-draftjs';

let timeoutFocus = null;
let idCommentLog = 0;
export default function FormCommentComponent({postId, item = null, callbackCommentData = null, closeReply}) {
    const domEditor = useRef(null);
    const [editorState, setEditorState] = useState(() => EditorState.createEmpty());
    const [disabledButton, setDisabledButton] = useState(true);
    const [openMention, setOpenMention] = useState(false);
    const [fileMedia, setFileMedia] = useState(null);
    const [uploadPending, setUploadPending] = useState(false);
    const [mentionSuggestions, setMentionSuggestions] = useState([]);

    useEffect(() => {
        let content = editorState.getCurrentContent().getPlainText('\u0001');
        if ((content || fileMedia) && !uploadPending) {
            setDisabledButton(false)
        } else {
            setDisabledButton(true)
        }

    }, [fileMedia, editorState, uploadPending]);

    useEffect(() => {
        if (item) {
            timeoutFocus = setTimeout(function () {
                domEditor.current.focus();
            }, 500);
            if (idCommentLog !== item.id) {
                idCommentLog = item.id;
                setEditorState(EditorState.createEmpty());
            }
        } else if (idCommentLog) {
            setEditorState(EditorState.createEmpty());
        }
        return () => {
            clearTimeout(timeoutFocus);
            setEditorState(EditorState.createEmpty());
        }
    }, [item]);

    const {MentionSuggestions, plugins} = useMemo(() => {
        const mentionPlugin = createMentionPlugin({
            mentionComponent(mentionProps) {
                return <span className={`user-mention`}>{mentionProps.children}</span>;
            },
            positionSuggestions: (settings) => {
                return {
                    // left: settings.decoratorRect.left + 'px',
                    // top: settings.decoratorRect.top - 10 + 'px', // change this value (40) for manage the distance between cursor and bottom edge of popover
                    // display: 'block',
                    // transform: 'scale(1) translateY(-10%)', // transition popover on the value of its height
                    // // transformOrigin: '1em 0% 0px',
                    // transition: 'all 0.25s cubic-bezier(0.3, 1.2, 0.2, 1)',
                    // inset: "auto auto 40px 0px",
                    // position:"absolute",
                    // bottom:"30px"
                };
            }
        });
        const {MentionSuggestions} = mentionPlugin;
        const plugins = [mentionPlugin];
        return {plugins, MentionSuggestions};
    }, []);
    const onOpenChangeMention = useCallback((_open) => {
        setOpenMention(_open);
    }, []);
    const removeFile = () => {
        if (!fileMedia) {
            return false;
        }

        deleteChunk([fileMedia.uuuid]);
        setFileMedia(null)
    }
    const mediaComponent = useMemo(() => <>
        <MediaComment
            fileMedia={fileMedia}
            removeFile={removeFile}
            uploadPending={uploadPending}
        />
    </>, [fileMedia, uploadPending]);
    let timeoutMentionSearchChange = null;
    const onMentionSearchChange = useCallback(({value}) => {
        clearTimeout(timeoutMentionSearchChange);
        if (!value) {
            return false
        }
        setMentionSuggestions([])
        timeoutMentionSearchChange = setTimeout(() => {
            mentionsearch(value).then((result) => {
                // let items = [];
                // for (let i = 0; i < result.data.length; i++) {
                //     let userMetion = result.data[i];
                //     userMetion.avatar = APP_CONSTANTS.MEDIA_ENDPOINT + userMetion.avatar;
                //     items.push(userMetion);
                // }
                // setMentionSuggestions(items)
                setMentionSuggestions(result.data)
            }).catch(error => {

            })
        }, 300)
    }, []);
    const handleKeyBindingFn = (e) => {
        if (!e) {
            return false
        }

        let key = e.which || e.keyCode;
        if (key === 13 && !openMention) {
            handleSubmit();
            e.preventDefault();
            return false
        }
    }

    const handleSubmit = async () => {

        let userStore = await getUserAuth();
        if (!userStore.user) {
            setShowAuth(1);
            return false;
        }
        if (disabledButton) {
            return true;
        }

        let contentState = stateToHTML(editorState.getCurrentContent(), {
            entityStyleFn: (entity) => {
                const entityType = entity.get("type").toLowerCase();
                if (entityType === "mention") {
                    const {mention} = entity.get("data");
                    return {
                        element: "a",
                        attributes: {
                            href: `/profile/${mention.alias}`,
                            className: 'mention'
                        },
                    };
                }
            },
        });//.replace("<p><br></p>", "");
        // const contentState = stateToHTML(editorState.getCurrentContent());
        // const rawContentState = editorState.getCurrentContent().getPlainText('\u0001');
        const rawContentMetionState = convertToRaw(editorState.getCurrentContent()).entityMap;
        // let userMentions = [];
        let userIds = [];
        for (var key in rawContentMetionState) {
            // userMentions.push(rawContentMetionState[key].data.mention);
            userIds.push(rawContentMetionState[key].data.mention.id);
        }

        let path = fileMedia ? [fileMedia.uuuid] : [];
        let parent = 0;
        if (item) {
            parent = item.parent_id ? item.parent_id : item.id
        }
        setDisabledButton(true);

        callbackCommentData(postId, contentState, userIds, path, parent).then(result => {
            return true;
        }).catch(error => {
        }).finally(() => {
            closeReply(null);
            setEditorState(EditorState.createEmpty());
            setFileMedia(null)
        });
        setDisabledButton(true);
    }
    const hanldEditerOnChange = (e) => {
        setEditorState(e)
    }
    const addMention = (user) => {
        if (!user) {
            return false
        }
        const stateWithEntity = editorState.getCurrentContent().createEntity(
            'mention',
            'IMMUTABLE',
            {
                mention: {id: user.id, name: user.name, alias: user.alias},
            },
        )
        const entityKey = stateWithEntity.getLastCreatedEntityKey()
        const stateWithText = Modifier.insertText(stateWithEntity, editorState.getSelection(), user.name, {color: "#f00"}, entityKey)
        setEditorState(EditorState.push(editorState, stateWithText))

        setTimeout(function () {
            domEditor.current.focus();
        }, 200);
    }
    const onUploadPending = (status) => {
        setUploadPending(status);
    }

    return <div className={`box_comment`}>
        {mediaComponent}
        {
            (item) ? <div className={`reply-comment`}>
                <p>
                    Reply comment <Link to={item.user.alias} target={"_blank"}>{item.user.name}</Link>
                </p>
                <span onClick={() => closeReply(null)}>
                    <IconClose/>
                </span>
            </div> : ''
        }
        <div className="wrapper-post-detail_comment_action">

            <div
                className={`wrapper-commment-input`}
                onClick={() => {
                    domEditor.current.focus();
                }}
            >
                <Editor
                    editorKey={'editor'}
                    editorState={editorState}
                    onChange={hanldEditerOnChange}
                    plugins={plugins}
                    ref={domEditor}
                    keyBindingFn={handleKeyBindingFn}
                    //  handleKeyCommand={handleKeyCommand}
                    placeholder={"Comment"}
                />
                <MentionSuggestions
                    open={openMention}
                    onOpenChange={onOpenChangeMention}
                    suggestions={mentionSuggestions}
                    onSearchChange={onMentionSearchChange}
                    //onAddMention={() => setDisabledButton(false)}
                />
                <UploadMediaComment
                    fileMedia={setFileMedia}
                    onUpload={onUploadPending}
                />
            </div>

            <button
                disabled={disabledButton}
                id='button-comment'
                onClick={() => handleSubmit()}
            >
                Post
            </button>

        </div>
    </div>

}

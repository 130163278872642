import {Link, useNavigate, NavLink} from "react-router-dom";
import React, {useState} from "react";
import {setShowAuth} from "../../../Funlove/Actions/authAction";
import {useSelector} from "react-redux";
import {getFormCreatePostAction} from "../../../Funlove/Actions/formCreateAction";
import IconUserProfile from "../../../Funlove/Components/Icon/IconUserProfile";
import IconPlusMenuBottom from "../../../Funlove/Components/Icon/IconPlusMenuBottom";
import IconHome from "../../../Funlove/Components/Icon/IconHome";
import IconVideo from "../../../Funlove/Components/Icon/IconVideo";
import IconUserFollowing from "../../../Funlove/Components/Icon/IconUserFollowing";

export default function MenuBottom () {
    const navigate = useNavigate();
    const auth = useSelector(state => state.auth);
    const createPost = () => {
        if (!auth.user) {
            return setShowAuth(1);
        }
        getFormCreatePostAction()
       // setShowFormCreatePost(true);
        return false
    }

    const checkUser = () => {
        if (!auth.user) {
            return setShowAuth(1);
        }
        navigate(`/profile/${auth.user.alias}`);
    }
   // return <div className="menu-bottom"><input/></div>
    return <div className="menu-bottom">
        <ul>
            <li className="menu-site menu-home active">
                <Link
                    className="styled-link styled-tmp-link"
                    to="/"
                >
                    <IconHome/>
                    <span className="span-main-nav-text">Home</span>
                </Link>
            </li>
            <li className="menu-site menu-videos">
                <Link className="styled-link styled-tmp-link menu-bottom-video" to="/videos">
                    <IconVideo/>
                    <span className="span-main-nav-text">Video</span>
                </Link>
            </li>
            <li className="icon-create">
                <a className="styled-link styled-tmp-link" onClick={() => createPost()}>
                    <IconPlusMenuBottom/>
                </a>
            </li>
            <li className="menu-site menu-following">
                <a className="styled-link styled-tmp-link" href="/">
                    <IconUserFollowing/>
                    <span className="span-main-nav-text">Following</span>
                </a>
            </li>
            <li  className="menu-site menu-user">
                <button
                    onClick={() => checkUser()}
                    className="styled-link styled-tmp-link"
                >
                    <IconUserProfile/>
                    <span className="span-main-nav-text">Profile</span>
                </button>
            </li>
        </ul>
    </div>
}
import {createSlice} from "@reduxjs/toolkit";
import APP_CONSTANTS from '../../Constant'

const authSlice = createSlice({
    name: "authSlice",
    initialState: {
        user: JSON.parse(sessionStorage.getItem(APP_CONSTANTS.USER)),
        token: localStorage.getItem(APP_CONSTANTS.USER_TOKEN),
        showForm: false
    },
    reducers: {
        setUserData: (state, action) => {
          //  sessionStorage.setItem(APP_CONSTANTS.USER, JSON.stringify(action.payload));
            state.user = action.payload;
        },
        setToken: (state, action) => {
            state.token = action.payload;
            localStorage.setItem(APP_CONSTANTS.USER_TOKEN, action.payload);
        },
        showForm: (state, action) => {
            state.showForm = action.payload;
        },
        removeUserData: (state, action) => {
            state.user = null;
            state.token = null;
           // state.showForm = 1
            sessionStorage.removeItem(APP_CONSTANTS.USER, null);
            localStorage.removeItem(APP_CONSTANTS.USER_TOKEN);
        }
    }
});

const {reducer, actions} = authSlice;
export const {setUserData, showForm, removeUserData, setToken} = actions;
export default reducer;

import React, {useEffect, useRef, useState, useMemo} from "react";

import ActionComponent from "./ActionComponent";

import {handleVideoPlay} from "../../../Lib/Video";
import APP_CONSTANTS from "../../../Constant";
import DetailContent from "../../Post/Component/DetailContent";
import {likeVideoRequest, bookmarkVideoRequest} from "./VideoRequest";

function VideoItem({index, item, currentId, handleShowComment, coppyLink}) {
    const [rotioImageClass, setRatioImageClass] = useState(null)

    useEffect(() => {
        const imgElement = document.createElement("img");
        imgElement.src = item.thumb;
        imgElement.onload = function () {
            if (imgElement.width >= imgElement.height || imgElement.width / imgElement.height >= 0.7) {
                setRatioImageClass("full-width");
            } else {
                setRatioImageClass("full-height");
            }
        };
    }, [])

    return <div
        id={`lazy-img-container-video-${item.id}`}
        className={`video-slide-item`}
        data-id-item={item.id}
        data-index-item={index}
    >
        <div
            className={`video-content-item`}
            id={`video-content-item-${item.id}`}
        >
            <img
                src={item.thumb}
                key={item.id}
                id={`thumb-video-container-${item.id}`}
                className={`video-item-thumbnail ${rotioImageClass}`}
                onClick={() => handleVideoPlay(item.id)}
            />
            <i
                className="fa fa-play"
                id={`fa-play${item.id}`}
                onClick={() => handleVideoPlay(item.id)}
            />

            {/*<i className="fa fa-circle-o-notch fa-spin"></i>*/}
            <ActionComponent
                id={item.id}
                item={item}
                isComment={false}
                showComment={handleShowComment}
                handleLike={likeVideoRequest}
                handleBookmark={bookmarkVideoRequest}
                handleCopyLink={coppyLink}
            />
            {
                (currentId === item.id) ? <>
                    <DetailContent
                        user={item.user}
                        content={item.post.content}
                        maxLenght={70}
                        outsideShowMore={true}
                    />

                </> : ''
            }
        </div>
    </div>
}

export default VideoItem;
import React, {useState, useEffect} from "react";
import {useFormik} from "formik";
import * as Yup from "yup";
import {changePasswrordRequest, checkEmailExists, checkUsernameExists, registerRequest} from "./AuthRequest";
import {authenUser, logout, saveToken, saveUser} from "../../Actions/authAction";
import {toastError, toastSuccess} from "../../Actions/ToastAction";
import IconCheckSuccess from "../Icon/IconCheckSuccess";

let validatePassDafult = {
    passLenghtClass: '',
    passLenghtStatus: 0,
    passMatchessStatus: 0, // 0 dafult, 1 pass, 2 error
    passMatchesClass: '',
    showError: false,
    showPassRegExp: false,
    isForcus: false
}
const passRegExp = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/;
let timeoutHandler = null;
let timeoutCheckEmail = null;
let timeoutCheckUsername = null;
let regexEmail = new RegExp('[a-z0-9]+@[a-z]+\.[a-z]{2,3}');

function ChangePassword(props) {
    const [showErrorLodPassword, setShowErrorLodPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);
    const [validateForm, setValidateForm] = useState(false);
    const [serverError, setServerError] = useState(false);
    const [showPass, setShowPass] = useState(true);
    const [showConfirmPass, setShowConfirmPass] = useState(true);
    const [formSubmit, setFormSubmit] = useState(false);
    const [validatePass, setValidatePass] = useState(validatePassDafult);
    const [serverMessage, setServerMessage] = useState(null);

    const formik = useFormik({
        validateOnChange: true,
        validateOnBlur: true,
        initialValues: {
            logPassword: 'Funlove@2002',
            password: 'Funlove@2002',
            confirmPassword: 'Funlove@2002'
        },
        validationSchema: Yup.object({
            logPassword: Yup.string().required("log password is required"),
            password: Yup.string().required(1)
                .min(8, '8 to 20 characters')
                .max(20, '8 to 20 characters')
                .matches(passRegExp, 'Letters, numbers, and special characters'),
            confirmPassword: Yup.string().oneOf([Yup.ref('password'), null], 'Passwords must match')
        }),

        onSubmit: values => {
            handleSubmit(values);
        },
    });

    useEffect(() => {
        clearTimeout(timeoutCheckUsername)
        if (formik.values.logPassword) {
            timeoutCheckUsername = setTimeout(() => {
                checkUsernameExists(formik.values.logPassword).catch(error => {
                    formik.setFieldError('logPassword', error.error);
                    setShowErrorLodPassword(true);
                })
            }, 500);
        }
    }, [formik.values.logPassword]);

    useEffect(() => {
        checkPass(formik.values.password);
    }, [formik.values.password]);
    useEffect(() => {
        if (!Object.keys(formik.errors).length
            && formik.values.password !== ''
            && formik.values.confirmPassword !== ''
        ) {
            setValidateForm(true);
        } else {
            setValidateForm(false)
        }
    }, [formik.errors]);

    const checkPass = (pass) => {
        let lengthPass = pass.length;
        if (!lengthPass) {
            let validatePassDafultCurrnt = {...validatePassDafult};
            if (validatePass.isForcus) {
                validatePassDafultCurrnt.showPassRegExp = true
            }

            setValidatePass(validatePassDafultCurrnt)
            return false
        }

        let validatePassCurrnt = {...validatePass};
        validatePassCurrnt.passLenghtStatus = 2;
        validatePassCurrnt.passLenghtClass = 'error';
        validatePassCurrnt.passMatchessStatus = 2;
        validatePassCurrnt.passMatchesClass = 'error';

        if (lengthPass >= 8 && lengthPass <= 20) {
            validatePassCurrnt.passLenghtStatus = 1;
            validatePassCurrnt.passLenghtClass = 'pass';
        }

        if (passRegExp.test(pass)) {
            validatePassCurrnt.passMatchessStatus = 1;
            validatePassCurrnt.passMatchesClass = 'pass';
        }

        setValidatePass(validatePassCurrnt);
    }

    const handleSubmit = async (values) => {
        if (formSubmit) {
            return false
        }
        setServerError(false);
        setFormSubmit(true);
        setServerMessage(null);
        changePasswrordRequest(values).then(result => {
            // saveUser(result.data);
            // saveToken(result.data.token);
            // props.closeAuth(false)
            toastSuccess("Change password success")
            logout();

        }).catch(error => {
            setServerError(true);
            let messages = error.data.message;
            if (error.status === 500 || typeof messages === 'string') {
                toastError("Serrver Error");
                return false;
            }

            if (messages.logPassword) {
                setShowErrorLodPassword(true);
                formik.setFieldError('logPassword', messages['logPassword'][0]);
            }
        }).finally(() => {
            setFormSubmit(false);
        })
    }
    const onFocusConfirmPassword = () => {
        setServerError(false);
        setShowConfirmPassword(false);
    }

    const onBlurConfirmPassword = () => {
        if (formik.values.confirmPassword === '') {
            setShowConfirmPassword(false);
            return false;
        }
        if (formik.errors.confirmPassword) {
            setShowConfirmPassword(true);
            return false;
        }
        setShowConfirmPassword(false);
    }

    const onFocusPass = () => {
        setServerError(false);
        let validatePassCurrnt = {...validatePass};
        validatePassCurrnt.showError = false;
        validatePassCurrnt.showPassRegExp = true;
        validatePassCurrnt.isForcus = true;
        setValidatePass(validatePassCurrnt);
    }

    const onBlurPass = () => {
        let validatePassCurrnt = {...validatePass};
        validatePassCurrnt.isForcus = false;
        if (validatePassCurrnt.passLenghtStatus === 2 || validatePassCurrnt.passMatchessStatus === 2) {
            validatePassCurrnt.showError = true;
            validatePassCurrnt.showPassRegExp = true;
        } else {
            validatePassCurrnt.showPassRegExp = false;
        }
        setValidatePass(validatePassCurrnt);
    }

    const onFocuslogPassword = () => {
        setServerError(false);
        setShowErrorLodPassword(false);
    }

    const onBlurlogPassword = () => {
        if (formik.values.logPassword === '') {
            setShowErrorLodPassword(false);
            return false;
        }
        if (formik.errors.logPassword) {
            setShowErrorLodPassword(true);
            return false;
        }
        setShowErrorLodPassword(false);
    }

    return <>
        <h1 className='auth-title'>Change Password</h1>
        <form
            onSubmit={formik.handleSubmit}
            className='auth-form changpass'
            autoComplete="off"
        >
            <div className={'form-group'}>
                <input
                    type="text"
                    name="logPassword"
                    placeholder="Log Password"
                    value={formik.values.logPassword}
                    onChange={formik.handleChange}
                    autoComplete="off"
                    onFocus={onFocuslogPassword}
                    onBlur={onBlurlogPassword}
                />
                <i className="fa fa-warning"></i>
                {showErrorLodPassword ? <span className="error">{formik.errors.logPassword}</span> : ''}
            </div>
            <div className={(validatePass.showError) ? 'form-group field-error' : 'form-group'}>
                <input
                    type={(!showPass) ? 'password' : 'text'}
                    id="password"
                    className="form-control"
                    placeholder="New Password"
                    autoComplete="off"
                    name="password"
                    value={formik.values.password}
                    onChange={formik.handleChange}
                    onFocus={onFocusPass}
                    onBlur={onBlurPass}
                />
                <i className="fa fa-warning fa-warning-pass"></i>
                <i
                    className={(!showPass) ? 'show-pass fa fa-eye' : 'show-pass fa fa-eye-slash'}
                    onClick={() => setShowPass(!showPass)}
                />

                {(validatePass.showPassRegExp) ? <>
                    <p className="div-description" style={{marginTop: 10}}>Your password must have:</p>
                    <div className={`div-text-container ${validatePass.passLenghtClass}`}>
                        <IconCheckSuccess/>
                        <span role="status">8 to 20 characters</span>
                    </div>
                    <div className={`div-text-container ${validatePass.passMatchesClass}`}>
                        <IconCheckSuccess/>
                        <span role="status">Letters, numbers, and special characters</span>
                    </div>
                </> : ''
                }

            </div>

            <div className={'form-group'}>
                <input
                    type={(!showConfirmPass) ? 'password' : 'text'}
                    id="confirmPassword"
                    className="form-control"
                    placeholder="Confirm New Password"
                    autoComplete="off"
                    name="confirmPassword"
                    value={formik.values.confirmPassword}
                    onChange={formik.handleChange}
                    onFocus={onFocusConfirmPassword}
                    onBlur={onBlurConfirmPassword}
                />
                <i className="fa fa-warning fa-warning-pass"></i>
                <i
                    className={(!showConfirmPass) ? 'show-pass fa fa-eye' : 'show-pass fa fa-eye-slash'}
                    onClick={() => setShowConfirmPass(!showConfirmPass)}
                />
                {showConfirmPassword ? <span className="error">{formik.errors.confirmPassword}</span> : ''}
            </div>
            <div className="form-group">
                <button
                    className="login-btn btn auth-btn"
                    style={{marginBottom: 10}}
                    type="submit"
                    disabled={(!validateForm)}
                >
                    {formSubmit ? <i className="fa fa-circle-o-notch fa-spin"></i> : 'Change Password'}
                </button>
                {serverError && serverMessage !== null ?
                    <span className="error" id="errorLogin">{serverMessage}</span> : ''}
            </div>
        </form>
    </>
}

export default ChangePassword;


import React, {useState, useEffect} from "react";
import {useParams} from "react-router-dom";
import {useSelector} from "react-redux";
import {getHeightUserHead, handedScrollProfile} from "../EventListener/Profile/MenuTap";
import EditProfile from "./Component/EditProfile";
import {getFeedByUser, getUserInfo} from "./Component/ProfileRequet";
import PageErrorComponent from "../Components/PageErrorComponent";
import Followers from "./Component/Followers";
import {activeMenu} from "../../Lib/Menu";
import UserHeader from "./Component/UserHeader";
import TabComponent from "./Component/TabComponent";
function Profile(props) {
    let {alias} = useParams();
    const userAuth = useSelector(state => state.auth.user);
    const [userInfo, setUserInfo] = useState(null);
    const [isAuthor, setIsAuthor] = useState(false);
    const [userError, setUserError] = useState(false);
    const [showFolower, setShowFolower] = useState(true);
    const [editProfile, setEditProfile] = useState(false)
    const [lastId, setLastId] = useState(0)
    const [loadding, setLoadding] = useState(false)
    const rootElement = document.getElementById("root");
    useEffect(() => {
        getHeightUserHead();
        activeMenu('menu-user');
        rootElement.classList.add("profile-page");
        return () => {
            rootElement.classList.remove("profile-page");
        }
    }, []);

    useEffect(() => {
        handleTabApi();
    }, [userInfo]);

    useEffect(() => {
        setUserInfo(null);
        setEditProfile(false);
        setShowFolower(false);
        getUserInfo(alias).then((result) => {
            if (result.data.author) {
                setIsAuthor(true);
            }
            setUserInfo(result.data);
            setUserError(false);
        }).catch(error => {
            setUserError(true);
        });
        return () => {
            setUserInfo(null)
            setUserError(false);
            setIsAuthor(false);
        }
    }, [alias, userAuth]);

    const closePopupPofile = () => {
        setEditProfile(!editProfile);
    }
    const updateUserInfo = (data, status) => {
        let user = {...userInfo};
        user.following = data.userFollowing;
        user.followers = data.userFollowers;
        user.isFollow = status;
        setUserInfo(user)
    }
    const handedScroll = (event) => {
    //    handedScrollProfile(event);
        if (!userInfo) {
            return false;
        }

        let element = event.target;
        let positionScroll = (element.scrollHeight - element.scrollTop) - 20
        let elementHeight = element.clientHeight
        if (positionScroll > elementHeight || loadding) {
            return false
        }
        handleTabApi();
    }

    const handleTabApi = () => {
        if (!userInfo || loadding) {
            return false;
        }

        setLoadding(true)
        getFeedByUser(userInfo.id, lastId).finally(() => {
            setLoadding(false)
        })
    }
    if (userError) {
        return <PageErrorComponent>
            <h1>User don't not exist</h1>
        </PageErrorComponent>
    }

    return <>
        <div className="wrapper-profile main-scroll" onScroll={handedScroll}>
            <UserHeader
                userInfo={userInfo}
                setEditProfile={setEditProfile}
                editProfile={editProfile}
                setShowFolower={setShowFolower}
                isAuthor={isAuthor}
                userAuth={userAuth}
                updateUserInfo={updateUserInfo}
            />
           <TabComponent
               setLastId={setLastId}
               loading={loadding}
           />
        </div>
        {(isAuthor && editProfile) ? <EditProfile
            show={editProfile}
            close={closePopupPofile}
            title={'Edit profile'}
            backButton={false}
            userInfo={userInfo}
        /> : <></>
        }
        <Followers
            show={showFolower}
            close={setShowFolower}
            userInfo={userInfo}
            activeTab={showFolower}
            setActiveTab={setShowFolower}
            updateUserInfo={setUserInfo}
            isAuthor={isAuthor}
        />
    </>
}

export default Profile
import React, {useEffect, useState} from "react";
import {useSelector} from "react-redux";
import {deleteCommentRequest, likeCommentRequet} from "./VideoRequest";
import ListComment from "../../Components/Comment/ListComment";
import FormCommentComponent from "../../Components/Comment/FormCommentComponent";
import {setShowAuth} from "../../Actions/authAction";
import IconClose from "../../Components/Icon/IconClose";
import {setVideoPostion} from "../../../Lib/Video";
import {commentVideotList, createCommentVideo} from "../../Components/Comment/CommentRequet";
import {updateCommentList, updateSkipId} from "../../Actions/CommentList";
import {subscribeCommentVideo, unSubscribeCommentVideo} from "../../Components/SocketEmit/Comment";

let lastCommentId = 0;

function CommentComponent({item, showComment, handleShowComment}) {
    if (!item) {
        return false
    }
    const user = useSelector(state => state.auth.user);
    const [loadComment, setLoadComment] = useState(false);
    const [loadCommentMore, setLoadCommentMore] = useState(true);
    //const [lastCommentId, setLastCommentId] = useState(0);
    const [reply, setReply] = useState(null);
    const replyComment = (item) => {
        setReply(item)
    }

    useEffect(() => {
        if (showComment && item) {
            setVideoPostion(`video-content-item-${item.id}`)
            updateCommentList([])
            lastCommentId = 0;
            getComment(item.id)
            subscribeCommentVideo(item.id)
        }
        return () => {
            setVideoPostion(`video-content-item-${item.id}`)
            unSubscribeCommentVideo(item.id)
            updateCommentList([])
            updateSkipId([])
        }
    }, [showComment, item.id])

    const handleScroll = (event) => {
        let element = event.target;
        let positionScroll = (element.scrollHeight - element.scrollTop) - 20
        let elementHeight = element.clientHeight
        if (positionScroll > elementHeight || loadComment) {
            return false
        }
        getComment(item.id, 0, lastCommentId);
    }
    const setLastCommentId = (id) => {
        lastCommentId = id
    }

    const getComment = async (videoId, parentId = 0, lastCommentId = 0) => {
        if (loadComment) {
            document.getElementById('comment-list').removeEventListener('scroll', handleScroll)
            return true;
        }
        if (lastCommentId) {
            document.getElementById('comment-list').removeEventListener('scroll', handleScroll)
        }
        setLoadComment(true)
        let result = await commentVideotList(videoId, parentId, lastCommentId);
        setLoadComment(false)
        if (!result || result === 2) {
            document.getElementById('comment-list').removeEventListener('scroll', handleScroll)
            setLoadCommentMore(false)
        } else {
            document.getElementById('comment-list').addEventListener('scroll', handleScroll)
        }
    }

    return <div className={`wrapper-video-comment`}>
        <div className={`close-comment`}
             onClick={() => handleShowComment()}
        >
            <button
                className={`button-close-commnet`}
                onClick={() => handleShowComment()}
            >
                <IconClose/>
            </button>
        </div>
        <div className={`video-comment`}>
            <ListComment
                setLastId={setLastCommentId}
                loading={loadComment}
                replyComment={replyComment}
                hadleKeyPress={false}
                likeComment={likeCommentRequet}
                deleteComment={deleteCommentRequest}
                commentChildrenList={commentVideotList}
            />
            {
                (user) ? <FormCommentComponent
                    postId={item.id}
                    parent={0}
                    item={reply}
                    closeReply={setReply}
                    callbackCommentData={createCommentVideo}
                /> : <div className={`box_comment`}>
                    <p className={'login-now'} onClick={() => setShowAuth(1)}>Login Now</p>
                </div>
            }
        </div>

    </div>

}

export default CommentComponent;
import Axios from "../../../Lib/Axios";
import {addUnshiftListFolow, removeItemListFolow} from "../../Actions/FollowAction";
import {updateFollowUserVideo} from "../../Actions/VideoAction";
import {updateFollowUserNewFeed} from "../../Actions/FeedsAction";

export const followUser = (userId) => {
    return Axios.get(`user/follow-user/${userId}`).then(result => {
        addUnshiftListFolow(result.data.user)
        updateFollowUserVideo(result.data.user.id, 1)
        updateFollowUserNewFeed(result.data.user.id, 1)
        return result;
    }).finally(() => {
        return true
    }).catch((error) => {
        return error
    });
}

export const unFollowUser = (userId) => {
    return Axios.get(`user/un-follow-user/${userId}`).then(result => {
        removeItemListFolow(result.data.followId)
        updateFollowUserVideo(result.data.followId, 0)
        updateFollowUserNewFeed(result.data.followId, 0)
        return result;
    }).finally(() => {
        return true
    }).catch((error) => {
        return error
    });
}
import React from 'react';
import ReadMoreComponent from "../ReadMoreComponent";
import iconLike from '../../../LibMedias/icon-like.svg'
import GrildFeed from "./GrildFeed";
import FeedHead from "./FeedHead";
import FeedButton from "./FeedButton";
import {useNavigate} from "react-router-dom";
import {getUserAuth, setShowAuth} from "../../Actions/authAction";
import BoxshareComponent from "../../Post/Component/BoxshareComponent";
import {likeRequest, bookmarkRequest} from "../../Post/Component/PostRequest";

export default function FeedItem({item, selectItem, user, deleteItem}) {


    const actionButtonComment = (itemSelected = null, indexMediaClick = 0) => {
        selectItem(item, indexMediaClick)

    }

    return <div className={`feed-item`} id={`feed-item-${item.id}`}>
        <FeedHead item={item} user={user} deleteItem={deleteItem}/>
        {
            (item.content) ? <div className={`feed-item-content`}>
                <ReadMoreComponent
                    maxLenght={300}
                >
                    {item.content}
                </ReadMoreComponent>
            </div> : <></>
        }

        {
            item.media ?
                <div className={`feed-item-media-grid`}>
                    <GrildFeed
                        files={item.media}
                        grildTemplate={item.template}
                        postItem={item}
                        callBack={actionButtonComment}
                    />
                </div> : <></>
        }

        <BoxshareComponent
            item={item}
            handleComment={actionButtonComment}
            handleLike={likeRequest}
            handleBookmark={bookmarkRequest}
        />

    </div>
};

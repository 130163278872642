import React from "react";
import Dropdown from 'react-bootstrap/Dropdown';
import {getPostEdit} from "../FormPostComponent/FormPostRequest";
export default function DropdownContainer({children}) {
    return <>
        <Dropdown className={`feed-item-head-option`}>
            <Dropdown.Toggle variant="success" id="dropdown-basic">
                <i className="fa fa-ellipsis-h"></i>
            </Dropdown.Toggle>
            <Dropdown.Menu>
                {children}
            </Dropdown.Menu>
        </Dropdown>
    </>
}
import {createSlice} from "@reduxjs/toolkit";
import APP_CONSTANTS from '../../Constant'

const muteSlice = createSlice({
    name: "muteSlice",
    initialState: {
        mute: APP_CONSTANTS.MUTE,
    },
    reducers: {
        setMute: (state, action) => {
            state.mute = action.payload;
        }
    }
});

const {reducer, actions} = muteSlice;
export const {setMute} = actions;
export default reducer;

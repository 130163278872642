import Axios from "../../../Lib/Axios";
import {addListFolow} from "../../Actions/FollowAction";
import {pluckPostID} from "../../NewFeeds/Component/newFeedsRequest";
import store from "../../../app/store";
import {setListItemProfile, callApiErrorProfile} from "../../Slice/PostProfileSlice";
import {toastError} from "../../Actions/ToastAction";
import {setListItem} from "../../Slice/NewFeedSlice";

export const updateProile = (data, avatar = null) => {
    let formData = new FormData();
    formData.append('name', data.name);
    formData.append('username', data.username);
    formData.append('email', data.email);
    formData.append('intro', data.intro);
    if (avatar) {
        formData.append('avatar', avatar);
    }

    return Axios.post('user/update', formData);
}

export const getUserInfo = (alias) => {
    return Axios.get(`user/info/${alias}`);
}

export const checkUpdateUserNameExists = (username) => {
    return Axios.post('user/check-username', {username});
}

export const checkEmailExit = (email) => {
    return Axios.post('user/check-email', {email});
}

export const getListFollowers = (userId, page, search) => {
    return Axios.get(`follower/${userId}/${page}`,  { params: search });
}
export const getListFollowings = (userId, page, search) => {
    return Axios.get(`following/${userId}/${page}`,  { params: search });
}

export const getListFollorNavLeft = (lastId) => {

    return Axios.get(`follow/${lastId}`).then(result => {
        addListFolow(result.data)
    }).catch(() => {

    })
}

export const getFeedByUser = (userId, lassId) => {

    return Axios.get(`new-feeds/list/${userId}/${lassId}`).then(result => {
        let converData = pluckPostID(result.data);
         store.dispatch(setListItemProfile(result.data))
       // store.dispatch(setListItem({stop:converData.stop,ids:converData.ids, list: result.data}))
    }).catch(() => {
        // store.dispatch(callApiErrorProfile())
        toastError('Load new feeds error profile');
    }).finally(() => {
        return true
    })
}
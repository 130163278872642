let hashtags = false;
let idInput = 'contentEditableField';
let keyEnter = true;
export const keypressInput = (e) => {
    let inputField = document.getElementById(idInput);
    let x = e.which || e.keyCode;

    if (inputField.innerHTML === "<br/>") {
        inputField.innerHTML = '';
    }

    if ((x === 32 || x === 13) && hashtags){ // space key
        e.preventDefault();
        inputField.innerHTML = inputField.innerHTML + "</span>&nbsp;";
        placeCaretAtEnd(this);
        hashtags = false;
        let elementsTagNew = document.getElementsByClassName('new');
        if (elementsTagNew[0]) {
            elementsTagNew[0].setAttribute("contenteditable", false);
        }

        if (x === 13 ){
            keyEnter = true;
        }
      //  document.getElementsByClassName("new")[0].setAttribute("contenteditable", false);
        return false;
    }
    if (x === 35){ // hash key (#)
        e.preventDefault();
        // $(".tag").removeClass("new");
        let elementsTag = document.getElementsByClassName('hashtag');
        for (var i = 0; i < elementsTag.length; i++) {
            elementsTag[i].classList.remove('new');
        }

        inputField.innerHTML = inputField.innerHTML + "<span class='tag label label-info new hashtag'>#";
        placeCaretAtEnd(this);
        hashtags = true;
        keyEnter = true;
        return false;
    }
    // various punctuation characters
    if (x === 8 || x === 9 || x >=16 && x <= 18 || x === 27 || x === 33 || x === 34 || x >= 36 && x <= 47 || x >= 58 && x <= 64 || x >= 91 && x <= 94 || x === 96 || x >= 123 && x <= 126) {
        if(hashtags) {
            e.preventDefault();
            inputField.innerHTML = inputField.innerHTML + "</span>" + String.fromCharCode(x);
            placeCaretAtEnd(this);
            hashtags = false;
            let elementsTagNew = document.getElementsByClassName('new');
            if (elementsTagNew[0]) {
                elementsTagNew[0].setAttribute("contenteditable", false);
            }
            //document.getElementsByClassName("new")[0].setAttribute("contenteditable", false);
        }
        return  false;
    }

    if (x === 13 && !hashtags) { // return key
        console.log("enter")
        e.preventDefault();
        pasteHtmlAtCaret(`<br>`);

        if (keyEnter !== true) {
            pasteHtmlAtCaret(`<br>`);
        }
        keyEnter = true
        return  false;
        //document.execCommand('defaultParagraphSeparator', false, 'p');
    } else {
        keyEnter = false;
    }
}

export const placeCaretAtEnd = () => {
    let el = document.getElementById(idInput);
    el.focus();
    if (typeof window.getSelection != "undefined" && typeof document.createRange != "undefined") {
        var range = document.createRange();
        range.selectNodeContents(el);
        range.collapse(false);
        var sel = window.getSelection();
        sel.removeAllRanges();
        sel.addRange(range);
    } else if (typeof document.body.createTextRange != "undefined") {
        var textRange = document.body.createTextRange();
        textRange.moveToElementText(el);
        textRange.collapse(false);
        textRange.select();
    }
}

export const pasteHtmlAtCaret = (html) =>{
    let sel, range;
    if (window.getSelection) {
        // IE9 and non-IE
        sel = window.getSelection();
        if (sel.getRangeAt && sel.rangeCount) {
            range = sel.getRangeAt(0);
            range.deleteContents();

            // Range.createContextualFragment() would be useful here but is
            // non-standard and not supported in all browsers (IE9, for one)
            const el = document.createElement("div");
            el.innerHTML = html;
            let frag = document.createDocumentFragment(),
                node,
                lastNode;
            while ((node = el.firstChild)) {
                lastNode = frag.appendChild(node);
            }
            range.insertNode(frag);

            // Preserve the selection
            if (lastNode) {
                range = range.cloneRange();
                range.setStartAfter(lastNode);
                range.collapse(true);
                sel.removeAllRanges();
                sel.addRange(range);
            }
        }
    } else if (document.selection && document.selection.type !== "Control") {
        // IE < 9
        document.selection.createRange().pasteHTML(html);
    }
}

export const handlePaste = (e) => {
    e.preventDefault()
    let text = e.clipboardData.getData('text/plain')
    document.execCommand('insertText', false, text)
}
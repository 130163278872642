import React, {useState, useEffect, useMemo} from "react";
import AuthComponent from "../../Funlove/Components/Auth/AuthComponent";
import {ToastContainer} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {getProfile} from "../../Funlove/Components/Auth/AuthRequest";
import FromCreatePost from "../../Funlove/Components/FormPostComponent";
import MenuBottom from "./Mobile/MenuBottom";
import {updateMute} from "../../Funlove/Actions/MuteAction";
import VideoElementComponent from "../../Funlove/Components/VideoElementComponent";
import Header from "./Desktop/Header";
import NavLeft from "./Desktop/NavLeft";
import Socket from '../../Funlove/Components/SocketEmit/index'
import {activeMenu} from "../../Lib/Menu";

function Layouts(props) {
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    useEffect(() => {
        getProfile();
        const handleWindowResize = () => {
            activeMenu()
            setWindowWidth(window.innerWidth);
        };
        const handleClike = (e) => {
            updateMute(false)
            window.removeEventListener('click', handleClike);
        }
        window.addEventListener('resize', handleWindowResize);
        window.addEventListener('click', handleClike);

        return () => {
            window.removeEventListener('resize', handleWindowResize);
            window.removeEventListener('click', handleClike);
        };
    }, []);

    useEffect(() => {
    }, [windowWidth]);

    const videoElement = useMemo(() => <VideoElementComponent/>,[])
    const socketConnet = useMemo(() => <Socket/>,[])
    return <>

        {windowWidth > 932 ? <Header/> : <></>}
        <div className={`main ${windowWidth > 932 ? 'main-desktop' : 'main-mobile'}`}>
            {windowWidth > 932 ? <NavLeft/> : ''}
            <div className={`${windowWidth > 915 ? 'main-right-content ' : ''}main-content`}>
                {props.children}
            </div>


        </div>

        {windowWidth <= 932 ? <MenuBottom/> : ''}
        <AuthComponent/>
        <ToastContainer style={{fontSize: "14px", zIndex: 999999}}/>
        <FromCreatePost/>
        {videoElement}
        {socketConnet}
    </>
}

export default Layouts;
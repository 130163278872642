import React from "react";
import {useSelector} from "react-redux";

import ModalComponent from "../ModalComponent";
import LoginComponent from "./LoginComponent";
import RegisterComponent from "./RegisterComponent";
import ForgotComponent from "./ForgotComponent";
import {setShowAuth} from "../../Actions/authAction";
import ChangePassword from "./ChangePassword";

const loginForm = 1;
const registerForm = 2;
const forgotForm = 3;
const changePassword = 4;

function AuthComponent(props) {
    const auth = useSelector(state => state.auth);
    if (auth.user && !auth.showForm) {
        return false;
    }
    const closeAuth = () => {
        setShowAuth(false)
    }

    return <ModalComponent
        show={auth.showForm}
        close={closeAuth}
        title=''
        backButton={false}
        style={{maxWidth: 483}}
    >
        <div className="wrapper-auth">
            {(auth.showForm === loginForm) ? <LoginComponent closeAuth={closeAuth} setShowAuth={setShowAuth}/> : ''}
            {(auth.showForm === registerForm) ? <RegisterComponent closeAuth={closeAuth}/> : ''}
            {(auth.showForm === forgotForm) ? <ForgotComponent closeAuth={closeAuth} setShowAuth={setShowAuth}/> : ''}
            {(auth.showForm === changePassword) ?
                <ChangePassword closeAuth={closeAuth} setShowAuth={setShowAuth}/> : ''}
            {
                auth.showForm === 4 ? "" : <div className='auth-footer'>
                    {auth.showForm === 2 ?
                        <p>Already have an account? <span onClick={() => setShowAuth(1)}>Log in</span></p> :
                        <p>Don’t have an account? <span onClick={() => setShowAuth(2)}>Sign up</span></p>}
                </div>
            }

        </div>
    </ModalComponent>


}

export default AuthComponent;
import { io } from 'socket.io-client';
import {changeCommentItem} from "../Funlove/Actions/CommentList";
import {updateCountCommentPost} from "../Funlove/Actions/FeedsAction";
import {updateCountCommentVideo} from "../Funlove/Actions/VideoAction";
import {addNewPostToFeed} from "../Funlove/NewFeeds/Component/newFeedsRequest";

 window.socketIo = null;
export const connectSocket = async () => {
    //return false;
    socketIo = await io(process.env.REACT_APP_SOCKET_ENDPOINT, {
        transports: ['polling']
    });
    socketOnEvetn(socketIo)
}

const socketOnEvetn = (socket) => {
    socket.on('commentPost', (data) => {
        console.log('commentPost')
        console.log(data)
        changeCommentItem(data);

    })

    socket.on('totalCommentPost', (data) => {
        updateCountCommentPost(data.id, data.total)

    })
    socket.on('commentVideo', (data) => {
        console.log('commentVideo')
        console.log(data)
        changeCommentItem(data);
    })
    socket.on('totalCommentVideo', (data) => {
        updateCountCommentVideo(data.id, data.total)
    })

    socket.on('newPost', (data) => {
        addNewPostToFeed(data)
    })
    socket.on('sendemo', (data) => {
        console.log('sendemo')
        console.log(data)
    })
}

export const eventEmit = (event, data) => {
    if (!window.socketIo) {
        return false;
    }
    window.socketIo.emit(event, data);
}


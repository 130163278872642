import React, {useState} from 'react';
import {toastSuccess} from "../../Actions/ToastAction";
import {copyToClipboard, intToString} from "../../../Lib/Helpers";
import IconHeart from "../../Components/Icon/IconHeart";
import IconComment from "../../Components/Icon/IconComment";
import IconBookmark from "../../Components/Icon/IconBookmark";
import IconLink from "../../Components/Icon/IconLink";

function BoxshareComponent({item, handleComment, handleBookmark, handleLike}) {
    const coppyLink = () => {
        navigator.clipboard.writeText(`${window.location.origin}/post/${item.id}`);
        toastSuccess('Copied');
    }

    return <div className="wrapper-post-detail_action">
        <div className='wrapper-post-detail_social'>
            <div className="wrapper-post-detail_action-user">
                <div
                    className={"wrapper-post-detail_action-user-like" + (item.isLike ? ' is-like-feed' : '')}
                    onClick={() => handleLike(item.id)}
                >
                    <span><IconHeart/></span>
                    {(item.like_count) ?
                        <strong className="browse-like-count">{intToString(item.like_count)}</strong> : ''}
                </div>
                <div className="wrapper-post-detail_action-user-comment" onClick={() => handleComment(item.id)}>
                    <span><IconComment/></span>
                    {(item.comment_count ?
                        <strong className="browse-comment-count">{intToString(item.comment_count)}</strong> : '')}
                </div>
                <div
                    className={"wrapper-post-detail_action-user-bookmark" + (item.bookmark ? ' is-bookmark-feed' : '')}
                    onClick={() => handleBookmark(item.id)}
                >
                    <span><IconBookmark/></span>
                    {(item.bookmark_count ?
                        <strong className="browse-comment-count">{intToString(item.bookmark_count)}</strong> : '')}
                </div>

                <div className="wrapper-post-detail_action-user-comment" onClick={() => coppyLink()}>
                    <span><IconLink/></span>
                </div>
            </div>
        </div>
    </div>
}

export default BoxshareComponent;
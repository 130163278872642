import store from "../../../app/store";
import Axios from "../../../Lib/Axios";
import {toastError} from "../../Actions/ToastAction";
import {setVideoListItem, callVideoApiError, setLoadingVideo} from "../../Slice/VideoSlice";
import {pluckPostID} from "../../NewFeeds/Component/newFeedsRequest";
import {updateBookmark, updateLike, updateCountCommentVideo} from "../../Actions/VideoAction";
import {removeComment, updateLikeComment} from "../../Actions/CommentList";

let timeoutCallApi;
let loadding = false;
let requestLike = false;
let requestBookmark = false;
let requestLikeComment = false;
export const fetchVideoList = (ids) => {
    if (loadding) return false
    loadding = true;
    store.dispatch(setLoadingVideo(true));
    return Axios.post('videos', {ids:ids}).then(result => {
        let converData = pluckPostID(result.data);
        store.dispatch(setVideoListItem({stop:converData.stop,ids:converData.ids, list: result.data}))
        return true
    }).catch(error => {
        store.dispatch(callVideoApiError())
        toastError('Load videos feeds error');
        return error
    }).finally(() => {
        clearTimeout(timeoutCallApi);
        timeoutCallApi = setTimeout(function () {
            loadding = false;
            store.dispatch(setLoadingVideo(false));
        }, 500)
        return true
    })
}

export const fetchVideoById = async (id) => {
    return Axios.get(`videos/${id}`).then(result => {
        store.dispatch(setVideoListItem({stop:false,ids:[id], list: result.data}))
        fetchVideoList([id])
        return result.data;
    }).catch((error) =>{
        fetchVideoList([]);
        toastError("Feed isn't available right now");
        return null;
    })
}

export const likeVideoRequest = (id) => {
    if (requestLike) return false;
    requestLike = true
    Axios.get(`video/like/${id}`).then(result => {
        updateLike(id, result.data.isLiked, result.data.like)
    }).finally(() => {
        requestLike = false
    }).catch(error => {

    })
}

export const bookmarkVideoRequest = (id) => {
    if (requestBookmark) return false;
    requestBookmark = true
    Axios.get(`video/bookmark/${id}`).then(result => {
        updateBookmark(id, result.data.isBookmark, result.data.bookmark)
    }).finally(() => {
        requestBookmark = false
    }).catch(error => {

    })
}

export const likeCommentRequet = (id, parent) => {
    if (requestLikeComment) return false;
    requestLikeComment = true
    Axios.get(`/comment/video/like/${id}`).then(result => {
        updateLikeComment(id, parent, result.data.isLiked, result.data.like)
    }).finally(() => {
        requestLikeComment = false
    }).catch(error => {

    })
}

export const deleteCommentRequest = (iteam) => {

    Axios.delete(`/comment/video/${iteam.id}`).then(result => {
        removeComment(iteam.id, iteam.parent_id, result.data.replyCount);
        updateCountCommentVideo(iteam.post_id, result.data.commentCount);
    }).catch(error => {
        console.log(error)
    })
}


import {getUserAuth} from "../../Actions/authAction";
import {eventEmit} from "../../../Lib/Socket";

export const subscribeCommentPost = async (postId) => {
    eventEmit('subscribeCommentPost', {postId});
}
export const unSubscribeCommentPost = (postId) => {
    eventEmit('unSubscribeCommentPost', {postId});
}
export const subscribeCommentVideo = async (videoId) => {
    eventEmit('subscribeCommentVideo', {videoId});
}
export const unSubscribeCommentVideo = (videoId) => {
    if (!videoId) {
        return false;
    }
    eventEmit('unSubscribeCommentVideo', {videoId});
}
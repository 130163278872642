import React from "react";
import {useNavigate, Link, NavLink} from 'react-router-dom';
import IconHome from "../../../../Funlove/Components/Icon/IconHome";
import IconUserFollowing from "../../../../Funlove/Components/Icon/IconUserFollowing";
import IconVideo from "../../../../Funlove/Components/Icon/IconVideo";
function MenuLeft(props) {
    return <div className="menu-left">
        <ul className="ul-main-nav">
            <li className="menu-site menu-home">
                <NavLink className="styled-link styled-tmp-link" to="/">
                    <IconHome/>
                    <span className="span-main-nav-text">For You</span>
                </NavLink>
            </li>
            <li className="menu-site menu-following">
                <NavLink className="styled-link styled-tmp-link" to="/">
                    <IconUserFollowing/>
                    <span className="span-main-nav-text">Following</span>
                </NavLink>
            </li>
            <li className="menu-site menu-videos">
                <NavLink className="styled-link styled-tmp-link" to="/videos">
                    <IconVideo/>
                    <span className="span-main-nav-text">Video</span>
                </NavLink>
            </li>
            {/*<li>*/}
            {/*    <a className="styled-link styled-tmp-link" href="/">*/}
            {/*        <svg width="32" data-e2e="" height="32" viewBox="0 0 32 32" fill="rgba(22, 24, 35, 1)"*/}
            {/*             xmlns="http://www.w3.org/2000/svg">*/}
            {/*            <path fillRule="evenodd" clipRule="evenodd"*/}
            {/*                  d="M7.78511 10.3334C6.95518 10.3334 6.33301 10.9792 6.33301 11.7143V20.2858C6.33301 21.0209 6.95518 21.6667 7.78511 21.6667H18.5744C19.4043 21.6667 20.0265 21.0209 20.0265 20.2858V17.5602C20.0265 17.1826 20.2392 16.8372 20.5763 16.6672C20.9135 16.4973 21.3177 16.5317 21.6212 16.7563L25.6663 19.7488V12.2513L21.6212 15.2439C21.3177 15.4684 20.9135 15.5029 20.5763 15.3329C20.2392 15.1629 20.0265 14.8175 20.0265 14.4399V11.7143C20.0265 10.9792 19.4043 10.3334 18.5744 10.3334H7.78511ZM25.6855 12.2371C25.6831 12.2388 25.6839 12.2383 25.6839 12.2383L25.6855 12.2371ZM25.6716 12.2177C25.673 12.2212 25.6746 12.2243 25.6763 12.2269C25.6798 12.2324 25.6834 12.2355 25.6855 12.2371L25.6874 12.2383C25.6874 12.2383 25.6865 12.238 25.6839 12.2383M4.33301 11.7143C4.33301 9.81952 5.90653 8.33337 7.78511 8.33337H18.5744C20.453 8.33337 22.0265 9.81953 22.0265 11.7143V12.4562L24.4963 10.629C25.0929 10.1877 25.8879 10.1155 26.5542 10.4359C27.224 10.758 27.6663 11.4325 27.6663 12.1905V19.8096C27.6663 20.5676 27.224 21.2421 26.5542 21.5642C25.888 21.8846 25.0929 21.8124 24.4963 21.371L22.0265 19.5439V20.2858C22.0265 22.1806 20.453 23.6667 18.5744 23.6667H7.78511C5.90653 23.6667 4.33301 22.1806 4.33301 20.2858V11.7143Z"></path>*/}
            {/*            <path*/}
            {/*                d="M15 15.134C15.6667 15.5189 15.6667 16.4811 15 16.866L12 18.5981C11.3333 18.983 10.5 18.5019 10.5 17.7321L10.5 14.2679C10.5 13.4981 11.3333 13.017 12 13.4019L15 15.134Z"></path>*/}
            {/*        </svg>*/}
            {/*        <span className="span-main-nav-text">LIVE</span>*/}
            {/*    </a>*/}
            {/*</li>*/}
        </ul>
    </div>
}
export default MenuLeft;
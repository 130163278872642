import {createSlice, current} from "@reduxjs/toolkit";

const ThumbnailSlice = createSlice({
    name: "ThumbnailSlice",
    initialState: {
        list: []
    },
    reducers: {
        setListThumb: (state, action) => {

            state.list = state.list.concat(action.payload);
           // console.log(action.payload)
           // state.list = state.list.push(action.payload);
        },
        updateListThumb:(state, action) => {
            state.list = [...action.payload];
        },
    }
});

const {reducer, actions} = ThumbnailSlice;
export const {
    setListThumb,
    updateListThumb
} = actions;
export default reducer;
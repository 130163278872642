export default {
    USER_TOKEN: 'X-Auth-Token',
    USER: 'User',
    THEME_COLOR:'Theme-Color',
    LIMIT: 20,
    debounceTimeout: 1000,
    imageExtension: ['image/bmp', 'image/gif', 'image/jpeg', 'image/jpg', 'image/png'],
    videoExtension:  ['video/mp4'],
    BASE_URL:process.env.REACT_APP_BASE_URL,
    API_ENDPOINT:process.env.REACT_APP_API_ENDPOINT,
    MEDIA_ENDPOINT:process.env.REACT_APP_MEDIA_ENDPOINT,
    MUTE: true,
    AVATAR_DEFAULT: 'avatar/default-1.jpg',
}

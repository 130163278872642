import React, {useEffect} from "react";
import avatar from '../../../../LibMedias/avtar.jpeg';
import {useSelector} from "react-redux";
import {setShowAuth} from "../../../../Funlove/Actions/authAction";
import {getListFollorNavLeft} from "../../../../Funlove/Profile/Component/ProfileRequet";
import FollowItem from "./FollowItem";
function Follows(props) {
    const user = useSelector(state => state.auth.user);
    const follow = useSelector(state => state.follow.list);
    const loadMore = useSelector(state => state.follow.stop);

    useEffect(() => {
        if (user) {
            getListFollow(0)
        }
    }, [user]);

    const getListFollow = (lastId = 0) => {
        getListFollorNavLeft(lastId);
    }

    if (!user) {
        return <div className="menu-follow no-user">
            <p className="nav-login-text">Log in to follow creators, like videos, and view comments.</p>
            <button type="button" className="nav-login-button" onClick={() => setShowAuth(1)}>Log in</button>
        </div>
    }



    return <div className="menu-follow">
        <h2 className="following-accounts">Following accounts</h2>
        {/*<FollowItem items={follow}/>*/}
    </div>
}

export default Follows;
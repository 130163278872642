import React, {useState} from 'react';
import Funlove from "./Funlove";


function App() {

    // const aspectRatio = calculateAspectRatio(550, 825);
    // console.log(aspectRatio)

    return  <Funlove/>;
}

export default App;

import {createSlice, current} from "@reduxjs/toolkit";

const VideoSlice = createSlice({
    name: "VideoSlice",
    initialState: {
        list: [],
        ids: [],
        stop: false,
        error: false,
        loading:false,
    },
    reducers: {
        setVideoListItem: (state, action) => {
             state.list = state.list.concat(action.payload.list);
             state.ids = state.ids.concat(action.payload.ids);
            // state.stop = action.payload.stop;
             state.error = false;
        },
        clearVideoListItem:(state, action) => {
            state.show = [];
            state.ids = [];
            state.stop = false;
            state.error = false;
        },
        callVideoApiError:(state) => {
            state.error = true;
        },
        updateVideoListItem:(state, action) => {
            state.list = [...action.payload];
        },
        setLoadingVideo:(state, action) => {
            state.loading = action.payload;
        },
    }
});

const {reducer, actions} = VideoSlice;
export const {
    setVideoListItem,
    clearVideoListItem,
    callVideoApiError,
    updateVideoListItem,
    setLoadingVideo
} = actions;
export default reducer;
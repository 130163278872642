import React, {useState, useEffect, useMemo} from "react";
import {useSelector} from "react-redux";
import {useNavigate, useParams} from "react-router-dom";

import {fetchVideoById, fetchVideoList} from "./Component/VideoRequest";
import VideoItem from "./Component/VideoItem";
import {initVideosObserver, resetVideo} from "../../Lib/Video";
import VideoSkeleton from "./Component/VideoSkeleton";
import VideoItemActive from "./Component/VideoItemActive";
import CommentComponent from "./Component/CommentComponent";
import {toastSuccess} from "../Actions/ToastAction";
import {activeMenu} from "../../Lib/Menu";

let timeOutObserver = null;
let timeOutLoading = null;
let isScroll = false;
let logIdItemActive = null;
let seekBarElement = null;
function Videos(props) {
    // const navigate = useNavigate();
    // const user = useSelector(state => state.auth.user);
    let {id} = useParams();
    const videos = useSelector(state => state.videos.list);
    const loading = useSelector(state => state.videos.loading);
    const ids = useSelector(state => state.videos.ids);
    const isStop = useSelector(state => state.videos.stop);
    const [currentItem, setCurrentItem] = useState(null);
    const [currentId, setCurrentId] = useState(null);
    const rootElement = document.getElementById("root");
    const [showComment, setShowComment] = useState(false);
    const [deletetem, setDeleteItem] = useState(null);
    useEffect(() => {
        seekBarElement = document.getElementById("seek-bar")
        if (logIdItemActive) {
            getVideoItem(logIdItemActive)
        }
        activeMenu('menu-videos')
        rootElement.classList.add("video-page");
        return () => {
            removeClassRoot()
            resetVideo();
            rootElement.classList.remove("video-page", 'hide-thumbnail', 'video-loading', 'videos-slide-scroll');
        }
    }, []);

    useEffect(() => {
        clearTimeout(timeOutObserver);
        timeOutObserver = setTimeout(() => {
            initVideosObserver(".video-slide-item", setTargetItem);
        }, 500)

    }, [videos.length]);

    useEffect(() => {
        if (videos.length === 0 && !id) {
            getVideoList([])
        } else {
            if (logIdItemActive) {
                getVideoItem(logIdItemActive)
            } else {
                getVideoItem(id)
            }
        }
    }, [id]);

    useEffect(() => {
        seekBarElement.style.backgroundSize = 0;
        removeClassRoot();
        return () => {
            let imagesItem = document.getElementById(`thumb-video-container-${currentId}`);
            if (imagesItem) {
                imagesItem.style = null;
            }
        }
    }, [currentId]);

    const videoItemActive = useMemo(() => <VideoItemActive item={currentItem} idItem={currentId}/>, [currentId]);
    const getVideoItem = (id) => {
        if (!id || id === currentId) {
            return false;
        }

        let itemSelect = videos.find(x => x.id === Number(id));
        if (itemSelect) {
            setCurrentId(itemSelect.id)
            setCurrentItem(itemSelect);
            document.getElementById(`lazy-img-container-video-${itemSelect.id}`).scrollIntoView()
            return false;
        }
        fetchVideoById(id).then(result => {
            setCurrentId(id)
            setCurrentItem(result);
        })
    }
    const getVideoList = async (ids) => {
        if (isStop) {
            return false;
        }
        fetchVideoList(ids);
    }
    const setTargetItem = (idActive, index) => {
        isScroll = false;
        if (index >= videos.length - 2) {
            clearTimeout(timeOutLoading);
            timeOutLoading = setTimeout(() => {
                getVideoList(ids);
            }, 200)
        }
        if (!idActive) {
            return false
        }
        setCurrentId(idActive)
        setCurrentItem(videos[index])
        if (idActive !== logIdItemActive) {
            rootElement.classList.remove( 'hide-thumbnail');
            logIdItemActive = idActive;
        }
    }

    const coppyLink = () => {
        navigator.clipboard.writeText(`${window.location.origin}/videos/${currentId}`);
        toastSuccess('Copied');
    }
    const addClassRoot = () => {
        rootElement.classList.add("showComment");
    }
    const removeClassRoot = () => {
        rootElement.classList.remove("showComment");
    }

    const handleShowComment = () => {
        setShowComment(!showComment);
        if (!showComment) {
            addClassRoot()
        } else {
            removeClassRoot()
        }
    }

    return <>
        {(currentItem && videos.length) ? videoItemActive : ''}
        <div
            className={`videos-slide`}
            id={"videos-slide"}
            //onScroll={(e) => addVideoopacity(e)}
        >
            {(loading && !videos.length) ? <VideoSkeleton number={1}/> : ''}
            {
                videos.map((item, index) => {
                    return <VideoItem
                        item={item}
                        index={index}
                        currentId={currentId}
                        key={`video-item-${index}`}
                        handleShowComment={handleShowComment}
                        coppyLink={coppyLink}
                    />
                })
            }
        </div>
        {showComment ? <CommentComponent
            item={currentItem}
            showComment={showComment}
            handleShowComment={handleShowComment}
        /> : ''
        }
    </>;
}

export default Videos;
import React, {useState, useEffect, useRef} from "react";
import AvatarEditor from 'react-avatar-editor'
import Slider from '@mui/material/Slider';
import {useDispatch} from "react-redux";

// import avatar from '../../../Lib/avatar-default.jpeg';
// import {updateAvatar} from "../../../Funlove/User/Components/UserRequests";
// import {user} from "../../../Funlove/User/Components/UserSlice";
import ModalComponent from "../../Components/ModalComponent";


function EditAavatar(props) {
    const [zoom, setZoom] = useState(1);
    const editor = useRef(null);
    const dispatch = useDispatch();
    const handleChangeZoom = (event) => {
        setZoom(event.target.value)
    }

    const cancel = () => {
        props.onHide();
    }

    useEffect(() => {
        setZoom(1)
    }, [props.show]);

    const save = async () => {
       //let canvas = editor.current.getImage();
        let canvas = editor.current.getImageScaledToCanvas()
        canvas.toBlob(async (blob) => {
            props.callbackHandleSaveAvatar(blob);
        }, 'image/jpeg');
    }
    return <ModalComponent
        show={props.show}
        close={props.hideEdittorAvatar}
        title={'Edit Avatar'}
        backButton={true}
        style={{maxWidth: 450}}
    >
        <div className="edit-avatar">
            <div className="edit-avatar-content popup-in-content">
                <div className={'crop-container crop-avatar'}>
                    <AvatarEditor
                        ref={editor}
                        image={props.fileUrl}
                        width={200}
                        height={200}
                        border={50}
                        color={[0, 0, 0, 0.6]} // RGBA
                        scale={zoom}
                        rotate={0}
                        borderRadius={999}
                        disableHiDPIScaling={false}
                        disableBoundaryChecks={false}
                        backgroundColor={'#f00'}
                        style={{width: '100%', height: 'auto', maxWidth: 450}}
                    />
                </div>
                <div className="controls-sider">
                    <Slider
                        size="small"
                        defaultValue={1}
                        min={1}
                        max={3}
                        step={0.01}
                        onChange={handleChangeZoom}
                        type="range"
                        aria-label="Temperature"
                    />
                </div>
            </div>
            <div className="edit-avatar-action footer-popup btn-popup">
                <button className='btn btn-cancel' onClick={() => props.hideEdittorAvatar()}>Cancel</button>
                <button className='btn btn-save' onClick={() => save()}>Save</button>
            </div>
        </div>
    </ModalComponent>
}

export default EditAavatar;
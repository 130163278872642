import React, {useState, useEffect, useRef} from "react";
import imageBanner from "../../../LibMedias/girl.png";
import APP_CONSTANTS from "../../../Constant";
import Skeleton from "react-loading-skeleton";
import Dropdown from "react-bootstrap/Dropdown";
import IconConfigBlack from "../../Components/Icon/IconConfigBlack";
import {logout, setShowAuth} from "../../Actions/authAction";
import FolowButtonComponent from "../../Components/FlolowComponent/FolowButtonComponent";
import {numberCash} from "../../../Lib/Helpers";
import TabConstant from "../../../Constant/TabConstant";
import IconBookmark from "../../Components/Icon/IconBookmark";
import IconHeart from "../../Components/Icon/IconHeart";

export default function UserHeader({
                                       userInfo,
                                       setEditProfile,
                                       editProfile,
                                       setShowFolower,
                                       isAuthor,
                                       userAuth,
                                       updateUserInfo
                                   }) {

    // let userInfo = props.userInfo;
    // let setEditProfile = props.setEditProfile;
    // let editProfile = props.editProfile;
    // let setShowFolower = props.setShowFolower;

    const handleNavigate = (native) => {
        console.log(native)
    }


    return <div className="user-header">
        <div className='user-info'>
            {/*<div*/}
            {/*    className="user-info_banner"*/}
            {/*    id="user-info_banner"*/}
            {/*    style={{backgroundImage: `url(${imageBanner})`}}*/}
            {/*>*/}
            {/*    /!*<img src={imageBanner}/>*!/*/}
            {/*</div>*/}
            <div className='user-info_content'>
                <div className='user-info_avatar'
                     style={{backgroundImage: `url(${(userInfo) ? userInfo.avatarUrl : APP_CONSTANTS.AVATAR_DEFAULT})`}}></div>
                <div className='user-info_container'>
                    <h1 className='user-info_container_title'>{(userInfo) ? userInfo.name : <Skeleton/>}</h1>
                    <div className='btn-info-action'>
                        {(userInfo) ? (isAuthor) ? <>
                                <button className='btn-edit-profile' onClick={() => setEditProfile(!editProfile)}>
                                    <i className="fa fa-pencil"></i>
                                    <span>Edit profile</span>
                                </button>
                                <Dropdown className={`config-option feed-item-head-option`}>
                                    <Dropdown.Toggle variant="success" id="dropdown-basic">
                                        {/*<i className="fa fa-cog"></i>*/}
                                        <IconConfigBlack/>
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu className={'tab-native'}>
                                        <Dropdown.Item
                                            className={'tab-native-bookmark'}
                                            onClick={() => handleNavigate(TabConstant.postFavorites)}
                                        >
                                            <IconBookmark/>
                                            <span> Post</span>
                                        </Dropdown.Item>
                                        <Dropdown.Item
                                            className={'tab-native-like'}
                                            onClick={() => handleNavigate(TabConstant.postLiked)}
                                        >
                                            <IconHeart/>
                                            <span> Post</span>
                                        </Dropdown.Item>
                                        <Dropdown.Item
                                            className={'tab-native-bookmark'}
                                            onClick={() => handleNavigate(TabConstant.videoFavorites)}
                                        >
                                            <IconBookmark/>
                                            <span>Video</span>
                                        </Dropdown.Item>
                                        <Dropdown.Item
                                            className={'tab-native-like'}
                                            onClick={() => handleNavigate(TabConstant.videoLiked)}
                                        >
                                            <IconHeart/>
                                            <span>Video</span>
                                        </Dropdown.Item>

                                        <Dropdown.Item onClick={() => setShowAuth(4)}>
                                            <i className="fa fa-lock"></i>
                                            Change Password
                                        </Dropdown.Item>
                                        <Dropdown.Item onClick={() => logout()}>
                                            <i className="fa fa-sign-out"></i>
                                            Logout
                                        </Dropdown.Item>
                                    </Dropdown.Menu>
                                </Dropdown>
                            </> : <FolowButtonComponent
                                isFollow={userInfo.isFollow}
                                user={userAuth}
                                userInfo={userInfo}
                                callBack={updateUserInfo}
                            />

                            : <Skeleton height={36}/>
                        }
                    </div>
                </div>
            </div>
        </div>
        <div className="user-count-infos">
            <h2 className='user-count-infos_following'>
                {
                    userInfo ? <p onClick={() => setShowFolower(1)}>
                        <strong>{numberCash(userInfo.following)}</strong>
                        Following
                    </p> : <Skeleton/>
                }
            </h2>
            <h2 className='user-count-infos_followers'>
                {
                    userInfo ? <p onClick={() => setShowFolower(2)}>
                        <strong>{numberCash(userInfo.followers)}</strong>
                        Followers
                    </p> : <Skeleton/>
                }
            </h2>
            <h2 className='user-count-infos_flike'>
                {
                    userInfo ? <p onClick={() => setShowFolower(3)}>
                        <strong>{numberCash(userInfo.likes)}</strong>
                        Likes
                    </p> : <Skeleton/>
                }
            </h2>
        </div>
        <div className='user-bio'>{(userInfo) ? (userInfo.intro) ? userInfo.intro : <>No bio yet.</> :
            <Skeleton/>}</div>
    </div>

}
import React, {useState, useEffect, useRef} from "react";
import EmojiPickerComponent from "../EmojiPickerComponent";
import {keypressInput, pasteHtmlAtCaret, handlePaste} from "./contentEditableLib";
function FormContentComponent(props) {
    const contentEditableRef = useRef(null);
    const [showFormEmoji, setShowFormEmoji] = useState(false)
    const [boundingBtnEmoji, setboundingBtnEmoji] = useState(0)
    useEffect(() => {
        contentEditableRef.current.addEventListener("keypress",  keypressInput)
        contentEditableRef.current.addEventListener("keyup",  checkEditable)
        contentEditableRef.current.addEventListener("paste",  handlePaste)

        contentEditableRef.current.innerHTML= props.content;
        const timeOutHashtag = setTimeout(function (){
            let elementsTag = document.getElementsByClassName('hashtag');
            for (var i = 0; i < elementsTag.length; i++) {
                elementsTag[i].setAttribute("contenteditable", false);
            }
        }, 500);
        return () => {
            clearTimeout(timeOutHashtag);
           if (contentEditableRef.current !== null) {
               contentEditableRef.current.removeEventListener('keypress', keypressInput);
               contentEditableRef.current.removeEventListener('paste', handlePaste);
               contentEditableRef.current.removeEventListener('keyup', checkEditable);
           }
        };
    }, []);

    const checkEditable = (e) => {
        let x = e.which || e.keyCode;
        if (x !== 8) {
            return false
        }
        if (contentEditableRef.current.innerHTML !== "<br>") {
            return false;
        }
        contentEditableRef.current.innerHTML = '';
    }
    const handleEmoji = (emojiPicker) => {
        contentEditableRef.current.focus();
        pasteHtmlAtCaret(`<span><img  class="img-emoji" src="${emojiPicker.imageUrl}"/></span>`);
        //pasteHtmlAtCaret(emojiPicker.emoji);
        handleChangeContent();
    }

    const showEmoji = () => {
        contentEditableRef.current.focus();
        setShowFormEmoji(true)
        var rec = document.getElementById(`btn-opent-emoji`).getBoundingClientRect();
        let boundingTop = rec.top - 350;
        if (boundingTop < 0) {
            boundingTop = 0
        }
        setboundingBtnEmoji(boundingTop)
    }

    const handleChangeContent = () => {
        if (props.onChange) {
            //props.onChange(contentEditableRef.current.innerHTML.replace("<br>", ""));
            props.onChange(contentEditableRef.current.innerHTML);
        }
    }

    return <>
        <div className={`wrapper-caption`}>
            <div
                id={`contentEditableField`}
                contentEditable={true}
                placeholder="What are you thinking?"
                ref={contentEditableRef}
                onInput={handleChangeContent}
                //onBlur={handleChangeContent}
               // dangerouslySetInnerHTML={{__html: props.content}}
            >
            </div>
            <button className={`btn-opent-emoji`} id={`btn-opent-emoji`} onClick={() => showEmoji()}></button>
        </div>
        <EmojiPickerComponent
            show={showFormEmoji}
            configShow={setShowFormEmoji}
            bounding={boundingBtnEmoji}
            onChange={handleEmoji}
        />
    </>
}

export default FormContentComponent;
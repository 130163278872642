import store from "../../app/store";
import {setListFollow, setLoadMoreStop} from "../Slice/FollowSlice";

export const getListFollow = () => {
    return [...store.getState().follow.list];
}

export const addListFolow = async (data) => {
    let listFollow = await getListFollow();
    store.dispatch(setListFollow(listFollow.concat(data.list)));
    if (data.list.length < data.limit) {
        store.dispatch(setLoadMoreStop(true));
    }
}

export const addUnshiftListFolow = async (item) => {
    let listFollow = await getListFollow();
    listFollow.unshift(item);
    store.dispatch(setListFollow(listFollow));
}

export const removeItemListFolow = async (followId) => {
    let listFollow = await getListFollow();
    const index = listFollow.findIndex(x => x.id === followId);
    if (index < 0 ) {
        return false
    }
    listFollow.splice(index, 1);
    store.dispatch(setListFollow(listFollow));
}
import React, {useRef, useEffect, useState} from 'react';
import Skeleton from 'react-loading-skeleton'
import LoadingComponent from "../LoadingComponent";

export function LoadingComment({show, num = 2}) {
    if (!show) {
        return '';
    }
    return <div className={`comment-loading`}>
        {
            [...Array(num)].map((x, i) => {
                return <div className={`comment-item`} key={`comment-item-loading-${i}`}>
                    <div className={`comment-item-avatar`}>
                        <Skeleton height={36}/>
                    </div>
                    <div className={`comment-item-contaiter`}>
                        <div className={`comment-username`}>
                            <Skeleton height={25} width={150}/>
                        </div>
                        <div className={`comment-text`}>
                            <Skeleton height={36}/>
                        </div>
                    </div>
                </div>
            })
        }
        <LoadingComponent show={show}/>
    </div>
}
import React, {useState, useEffect, useRef} from "react";
import {useFormik, Formik} from "formik";
import * as Yup from "yup";
import {clear} from "@testing-library/user-event/dist/clear";

function ForgotComponent(props) {

    const [validateForm, setValidateForm] = useState(false);
    const [serverError, setServerError] = useState(false);
    const [formSubmit, setFormSubmit] = useState(false);
    let timeoutHandler = null;
    const formik = useFormik({
        validateOnChange: true,
        validateOnBlur: true,
        initialValues: {
            email: '',
        },
        validationSchema: Yup.object({
            email: Yup.string().email("Invalid Mail format").required("Mail is required")
        }),

        onSubmit: values => {
            handleSubmit(values);
        },
    });

    const handleSubmit = async (values) => {
        if (formSubmit) {
            return false
        }

        setFormSubmit(true);
        setTimeout(() => {
            setFormSubmit(false);
            setServerError(true);
        }, 4000);
    }

    const onFocus = () => {
        setServerError(false);
    }

    if (timeoutHandler) {
        clearTimeout(timeoutHandler)
    }

    if (!Object.keys(formik.errors).length && formik.values.email !== '') {
        timeoutHandler = setTimeout(() => {
            setValidateForm(true);
        }, 500);
    } else {
        timeoutHandler = setTimeout(() => {
            setValidateForm(false)
        }, 500);
    }

    return <>
        <div className='back-button' onClick={() => props.setShowAuth(1)}>
            <svg width="24" height="24" viewBox="0 0 48 48" fill="currentColor"
                 xmlns="http://www.w3.org/2000/svg">
                <path fillRule="evenodd" clipRule="evenodd"
                      d="M4.58579 22.5858L20.8787 6.29289C21.2692 5.90237 21.9024 5.90237 22.2929 6.29289L23.7071 7.70711C24.0976 8.09763 24.0976 8.7308 23.7071 9.12132L8.82843 24L23.7071 38.8787C24.0976 39.2692 24.0976 39.9024 23.7071 40.2929L22.2929 41.7071C21.9024 42.0976 21.2692 42.0976 20.8787 41.7071L4.58579 25.4142C3.80474 24.6332 3.80474 23.3668 4.58579 22.5858Z"></path>
            </svg>
        </div>
        <h1 className='auth-title'>Forgot password</h1>
        <form
            onSubmit={formik.handleSubmit}
            className='auth-form'
            autoComplete="off"
        >
            <div className="div-description">Email</div>
            <div className={(serverError)? 'form-group field-error': 'form-group'}>
                <input
                    type="text"
                    name="email"
                    placeholder="E-mail Address"
                    value={formik.values.email}
                    onChange={formik.handleChange}
                    autoComplete="off"
                    onFocus={onFocus}
                />
                <i className="fa fa-warning"></i>
            </div>

            <div className="form-group">
                <button
                    className="login-btn auth-btn"
                    style={{marginBottom:10}}
                    type="submit"
                    disabled={(!validateForm)}
                >
                    {formSubmit? <i className="fa fa-circle-o-notch fa-spin"></i>: 'Send recovery email'}
                </button>
                {serverError? <span className="error" id="errorLogin">Username or password doesn't match our records. Try again.</span>: ''}
            </div>
        </form>
    </>

}

export default ForgotComponent;


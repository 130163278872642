import React from "react";

export default function IconVideo() {

    return <svg version="1.0"
                xmlns="http://www.w3.org/2000/svg"
                width="32"
                height="25"
                viewBox="0 0 512.000000 512.000000"
                preserveAspectRatio="xMidYMid meet"
    >
        <g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
           fill="#fff" stroke="none">
            <path d="M521 4624 c-169 -45 -301 -180 -346 -351 -23 -86 -23 -3340 0 -3426
                45 -173 178 -307 350 -352 52 -13 293 -15 2035 -15 2155 0 2025 -3 2142 56 71
                36 159 123 197 193 65 122 61 8 61 1831 0 1455 -2 1663 -15 1715 -45 172 -179
                305 -352 350 -85 22 -3989 21 -4072 -1z m4045 -330 c15 -11 37 -33 48 -48 l21
                -27 0 -1659 0 -1659 -21 -27 c-11 -15 -33 -37 -48 -48 l-27 -21 -1979 0 -1979
                0 -27 21 c-15 11 -37 33 -48 48 -21 27 -21 29 -24 1659 -1 898 0 1646 3 1664
                7 40 49 91 90 109 25 11 377 13 1997 11 l1967 -2 27 -21z"
            />
            <path d="M2015 3506 c-41 -18 -83 -69 -90 -109 -3 -18 -5 -406 -3 -864 3 -822
                3 -832 24 -859 38 -52 71 -69 132 -69 l57 0 655 409 c360 224 669 423 687 441
                56 59 56 151 0 210 -18 18 -326 216 -686 440 -577 360 -659 409 -700 412 -26
                2 -60 -3 -76 -11z m644 -696 c215 -135 391 -247 391 -250 0 -4 -704 -448 -796
                -503 -12 -7 -14 76 -14 503 0 427 2 510 14 503 7 -4 189 -118 405 -253z"
            />
        </g>
    </svg>

}
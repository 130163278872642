import Axios from "./Axios";
import {FFmpeg} from '@ffmpeg/ffmpeg';
import {fetchFile, toBlobURL} from '@ffmpeg/util';
import {addThumbnail} from "../Funlove/Actions/ThumbnailActive";

const ffmpeg = new FFmpeg();
await ffmpeg.load();

export const ffmpegCreateThumbnail = async (file, uuuid, callBackSetThumbnail) => {
    let nameFileInput = `fileWriteFile${uuuid}`;
    let nameFileOutput = `fileNameOutput${uuuid}.png`;
    await ffmpeg.writeFile(nameFileInput, await fetchFile(file));
    await ffmpeg.exec(['-i', nameFileInput, "-ss", '00:00:01', '-frames:v', '1', nameFileOutput])
    const readFileOutput = await ffmpeg.readFile(nameFileOutput);
    let thumbnail = new File([readFileOutput.buffer], 'thumbnail' + uuuid, {type: 'image/jpeg'});
    let formData = new FormData();
    formData.append('file', thumbnail);
    formData.append('name', uuuid);
    return Axios.post('upload-thumbnail', formData, {
        timeout: 7200000,
        headers: {"Content-Type": "multipart/form-data"}
    }).then(() => {
        let baseUrl = process.env.REACT_APP_API_ENDPOINT.replace("api/", "")+"storage/thumbnail/"+uuuid+"/thumbnail.webp"
        callBackSetThumbnail({uuuid: uuuid, url:baseUrl})
    }).catch(error => {
    })
}


export const createMuntipleThumbnail = async (file, index = 0) => {
    let nameFile = `fileWriteFile${index}`;
    await ffmpeg.writeFile(nameFile, await fetchFile(file));
    await ffmpeg.exec([
        "-i",
        nameFile,
        "-vf",
        'fps=1/10', 'img%03d.jpg'
    ]);

    for (let i = 1; i <= 3; i++) {
        let acccs = await ffmpeg.readFile(`img00${i}.jpg`);
        console.log(acccs.buffer)
        // let img1 = document.createElement("img");
        // img1.src = URL.createObjectURL(new Blob([acccs.buffer], {type: 'image/jpeg'}));
        // img1.style.width = 200 + "px"
        // videoRef.current.append(img1);
    }
    const dace = await ffmpeg.readFile('img001.jpg');

    return URL.createObjectURL(new Blob([dace.buffer], {type: 'image/jpeg'}));
}
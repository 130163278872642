import {toast} from 'react-toastify';

let toastOption = {
    position: "top-right",
    autoClose: 3000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "light",
   // onClose: () => window.alert('Called when I close')
}
export const toastSuccess = (messages = '') => {
    toast.success(messages, toastOption)
}

export const toastError = (messages = '') => {
    toast.error(messages, toastOption)
}

export const toastWarning = (messages = '') => {
    toast.warning(messages, toastOption)
}

export const toastInfo = (messages = '') => {
    toast.info(messages, toastOption)
}

export const toastDefault = (messages = '') => {
    toast(messages, toastOption)
}


import store from "../../app/store";
import {updateListItem, rollbackListItem} from "../Slice/NewFeedSlice";

export const listNewFeed = () => {
    return store.getState().newFeeds
}
export const updateLike = (id, status, countLike) => {
    const newFeeds = store.getState().newFeeds;
    const itemList = [...newFeeds.list];
    const index = itemList.findIndex(item => item.id === id);
    let item = {...itemList[index]}
    item.isLike = status;
    item.like_count = countLike;
    itemList[index] = item;
    store.dispatch(updateListItem(itemList));
}

export const rollbackListNewFeed = (data) => {
    store.dispatch(rollbackListItem(data));
}

export const updateBookmark = (id, status, countLike) => {
    const newFeeds = store.getState().newFeeds;
    const itemList = [...newFeeds.list];
    const index = itemList.findIndex(item => item.id === id);
    let item = {...itemList[index]}
    item.bookmark = status;
    item.bookmark_count = countLike;
    itemList[index] = item;
    store.dispatch(updateListItem(itemList));
}

export const updateCountCommentPost = (id, countComment) => {
    const newFeeds = store.getState().newFeeds;
    const itemList = [...newFeeds.list];
    const index = itemList.findIndex(item => item.id === id);
    let item = {...itemList[index]}
    item.comment_count = countComment;
    itemList[index] = item;
    store.dispatch(updateListItem(itemList));
}

export const updateFollowUserNewFeed = (userID, status) => {
    const newFeeds = store.getState().newFeeds;
    const listItems = [...newFeeds.list];
    for (let i = 0; i < listItems.length; i++) {
        if (userID !== listItems[i].user.id) {
            continue;
        }

        let item = {...listItems[i]}

        item.following = status;

        listItems[i] = item;
    }
    store.dispatch(updateListItem(listItems));
}


export const removeItemInList = (id) => {
    const newFeeds = store.getState().newFeeds;
    const listItems = [...newFeeds.list];
    const index = listItems.findIndex(item => item.id === id);
    listItems.splice(index, 1);
    store.dispatch(updateListItem(listItems));
}
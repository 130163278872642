import store from "../../app/store";
import {updateListItemProfile} from "../Slice/PostProfileSlice";

export const updateLikeProfile = (id, status, countLike) => {
    const itemList = [...store.getState().postProfile.list];
    const index = itemList.findIndex(item => item.id === id);
    let item = {... itemList[index]}
    item.isLike = status;
    item.like_count = countLike;
    itemList[index] = item;
    store.dispatch(updateListItemProfile(itemList));
}

export const updateBookmarkProfile = (id, status, countLike) => {
    const itemList = [...store.getState().postProfile.list];
    const index = itemList.findIndex(item => item.id === id);
    let item = {... itemList[index]}
    item.bookmark = status;
    item.bookmark_count = countLike;
    itemList[index] = item;
    store.dispatch(updateListItemProfile(itemList));
}

export const updateCountCommentPostProfile = (id, countComment) => {
    const itemList = [...store.getState().postProfile.list];
    const index = itemList.findIndex(item => item.id === id);
    let item = {... itemList[index]}
    item.comment_count = countComment;
    itemList[index] = item;
    store.dispatch(updateListItem(itemList));
}

export const updateFollowUserNewFeedProfile = (userID, status) => {
    const itemList = [...store.getState().postProfile.list];
    for (let i = 0; i < listItems.length; i++) {
        if (userID !== listItems[i].user.id) {
            continue;
        }
        let item = {... listItems[i]}

        item.following = status;

        listItems[i] = item;
    }
    store.dispatch(updateListItem(listItems));
}


export const removeItemInListProfile = (id) => {
    const itemList = [...store.getState().postProfile.list];
    const index = listItems.findIndex(item => item.id === id);
    listItems.splice(index, 1);
    store.dispatch(updateListItem(listItems));
}
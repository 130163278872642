import {createSlice} from "@reduxjs/toolkit";

const windowSizeSlice = createSlice({
    name: "windowSizeSlice",
    initialState: {
        width: 0,
        height: 0,
    },
    reducers: {
        setSizeWindow: (state, action) => {
            state.width = action.payload.width;
            state.height = action.payload.height;
        }
    }
});

const {reducer, actions} = windowSizeSlice;
export const {setSizeWindow} = actions;
export default reducer;

import React, {useEffect, useMemo, useState} from "react";
import {useNavigate} from "react-router-dom";

import CarouselComponent from "./Component/CarouselComponent";
import IconClose from "../Components/Icon/IconClose";
import IconBack from "../Components/Icon/IconBack";
import ReadMoreComponent from "../Components/ReadMoreComponent";
import UserInfoContainer from "../Components/Feeds/UserInfoContainer";
import DropdownContainer from "../Components/Feeds/DropdownContainer";
import Dropdown from "react-bootstrap/Dropdown";
import {getPostEdit} from "../Components/FormPostComponent/FormPostRequest";
import BoxshareComponent from "./Component/BoxshareComponent";
import FormCommentComponent from "../Components/Comment/FormCommentComponent";
import ActionComponent from "../Videos/Component/ActionComponent";
import DetailContent from "./Component/DetailContent";
import {setShowComment} from "../Slice/CommentToggle";
import ListComment from "../Components/Comment/ListComment";
import {commentPostList, createCommentPost} from "../Components/Comment/CommentRequet";
import {updateSkipId, updateCommentList} from "../Actions/CommentList";
import {unSubscribeCommentPost, subscribeCommentPost} from "../Components/SocketEmit/Comment";
import {bookmarkRequest, likeRequest, likeCommentRequet, deleteCommentRequest, deleteItemRequest} from "./Component/PostRequest";
import {useSelector} from "react-redux";
import {setShowAuth} from "../Actions/authAction";
import {toastSuccess} from "../Actions/ToastAction";
import ModalConfirm from "../Components/ModalConfirm";
import FeedHead from "../Components/Feeds/FeedHead";

export default function Post({item, mediaIndex = 0, backEvent = null}) {
    if (!item) {
        document.getElementById("root").classList.remove(
            "disableScroll",
            "hideMenuBottom",
            "postDetail",
            "hide-media-control"
        );
        return false
    }
    const navigate = useNavigate();
    const user = useSelector(state => state.auth.user);
    const [windowWidth, setWindowWidth] = useState([window.innerWidth]);
    const [loadComment, setLoadComment] = useState(false);
    const [loadCommentMore, setLoadCommentMore] = useState(true);
    const [lastId, setLastId] = useState(0);
    const [hadleKeyPressShowMedia, sethadleKeyPressShowMedia] = useState(false);
    const [reply, setReply] = useState(null);
    const [showComment, setShowComment] = useState(false);
    const [deleteItem, setDeleteItem] = useState(null);
    useEffect(() => {
        document.getElementById("root").classList.add(
            "disableScroll",
            "hideMenuBottom",
            "postDetail",
        );

        return () => {
            document.getElementById("root").classList.remove(
                "disableScroll",
                "hideMenuBottom",
                "postDetail",
                "hide-media-control"
            );
        }
    }, []);
    useEffect(() => {

        getComment(item.id, 0, lastId);
        subscribeCommentPost(item.id)

        return () => {
            updateCommentList([])
            updateSkipId([])
            unSubscribeCommentPost(item.id)
        }
    }, [item.id]);

    useEffect(() => {
        if (!hadleKeyPressShowMedia) {
            document.addEventListener("keydown", hadleKeyPress)
        } else {
            document.removeEventListener("keydown", hadleKeyPress)
        }
        return () => {
            document.removeEventListener("keydown", hadleKeyPress)
        }
    }, [hadleKeyPressShowMedia]);

    useEffect(() => {
        const handleWindowResize = () => {
            setWindowWidth([window.innerWidth]);
        };
        const handleClick = (e) => {
            window.removeEventListener('click', handleClick);
        }
        window.addEventListener('resize', handleWindowResize);
        return () => {
            window.removeEventListener('resize', handleWindowResize);
            window.removeEventListener('click', handleClick);
        };

    }, []);
    const hadleKeyPress = (e) => {
        if (e.keyCode !== 27) {
            return false;
        }
        goBack();
    }
    const getComment = async (postId, parentId = 0, lastId = 0) => {
        if (loadComment || !loadCommentMore) {
            return true;
        }
        setLoadComment(true)
        let result = await commentPostList(postId, parentId, lastId);
        setLoadComment(false)
        if (!result || result === 2) {
            setLoadCommentMore(false)
        }
    }
    const goBack = () => {
        if (backEvent) {
            backEvent();
            return true;
        }
        navigate('/', {replace: true});
        // if (window.history.state && window.history.state.idx > 0) {
        //     navigate(-1);
        // } else {
        //     navigate('/', { replace: true }); // the current entry in the history stack will be replaced with the new one with { replace: true }
        // }
    }
    const replyComment = (item) => {
        setReply(item)
    }
    const handleScroll = (event) => {
        let element = event.target;
        let positionScroll = (element.scrollHeight - element.scrollTop) - 20
        let elementHeight = element.clientHeight
        if (positionScroll > elementHeight || loadComment) {
            return false
        }
        getComment(item.id, 0, lastId);
    }
    const handleShowComment = () => {
        setShowComment(!showComment);
        if (!showComment) {
            document.getElementById("root").classList.add("hide-media-control");
        } else {
            document.getElementById("root").classList.remove("hide-media-control");
        }
    }

    const coppyLink = () => {
        navigator.clipboard.writeText(`${window.location.origin}/post/${item.id}`);
        toastSuccess('Copied');
    }

    const carouselComponent = useMemo(() => <>
        <CarouselComponent item={item} selectedItem={mediaIndex}/>
    </>, [item.media]);

    const confirmDelete = (item) => {
        deleteItemRequest(item)
       setDeleteItem(null);
       navigate('/', { replace: true });
    }

    return <>
        <ModalConfirm
            title={'Are you sure you want to delete this Post?'}
            confirmDelete={confirmDelete}
            iteam={deleteItem}
            cancel={setDeleteItem}
        />
        {/*<div className={`bg-post`}></div>*/}
        <div
            className={`post-detail-wrapper ${item.media.length ? 'media' : 'unmedia'} ${showComment ? `show-comment` : ``}`}>
            {carouselComponent}
            {
                (item.media.length && windowWidth <= 932) ? <div className={`post-detail-content-mobile`}>
                    <DetailContent
                        user={item.user}
                        content={item.content}
                        maxLenght={70}
                        outsideShowMore={true}
                    />
                    <ActionComponent
                        id={item.id}
                        item={item}
                        isComment={false}
                        showComment={handleShowComment}
                        handleLike={likeRequest}
                        handleBookmark={bookmarkRequest}
                        handleCopyLink={coppyLink}
                    />
                </div> : ''
            }
            {
                (showComment && windowWidth <= 932) ? <>
                    <div
                        className={`wrapper-close-comment-mobile`}
                        onClick={() => handleShowComment()}
                    >
                        <button
                            className={`button-close-commnet`}
                            onClick={() => handleShowComment()}
                        >
                            <IconClose/>
                        </button>
                    </div>
                </> : ''
            }

            <div className={`post-detail-content`}>
                <div
                    className={`wrapper-content-post`}
                    onScroll={(event) => handleScroll(event)}
                >
                    {
                        (!item.media.length || windowWidth > 932) ? <>
                            <FeedHead item={item} user={user} deleteItem={setDeleteItem}/>

                            {
                                (item.content) ? <div className={`feed-item-content`}>
                                    <ReadMoreComponent
                                        maxLenght={300}
                                    >
                                        {item.content}
                                    </ReadMoreComponent>
                                </div> : <></>
                            }
                            <BoxshareComponent
                                item={item}
                                handleLike={likeRequest}
                                handleBookmark={bookmarkRequest}
                            />
                        </> : ''
                    }
                    <ListComment
                        setLastId={setLastId}
                        setLoadComment={setLoadComment}
                        loading={loadComment}
                        replyComment={replyComment}
                        hadleKeyPress={sethadleKeyPressShowMedia}
                        likeComment={likeCommentRequet}
                        deleteComment={deleteCommentRequest}
                        commentChildrenList={commentPostList}
                    />
                </div>
                {
                    (user) ? <FormCommentComponent
                        postId={item.id}
                        parent={0}
                        item={reply}
                        closeReply={setReply}
                        callbackCommentData={createCommentPost}
                    /> : <div className={`box_comment`}>
                        <p className={'login-now'} onClick={() => setShowAuth(1)}>Login Now</p>
                    </div>
                }
            </div>
            {
                !showComment ?
                    <button id={`button-post-back`} onClick={() => goBack()}>
                        {
                            (window.innerWidth > 932) ? <IconClose/> : <IconBack/>
                        }
                    </button> : ""
            }
        </div>
    </>
}

import React, {useState} from "react";
import {followUser, unFollowUser} from "./FollowRequet";

export default function FolowButtonComponent({isFollow, user, userInfo, callBack = null}) {
    const [isCallFollow, setIsCallFollow] = useState(false);
    const unFollow = (id) => {
        if (isCallFollow) return true;
        setIsCallFollow(true);
        unFollowUser(id).then(result => {
            if (callBack) {
                callBack(result.data, 0)
            }
        }).finally(() => {
            setIsCallFollow(false);
        })
    }

    const follow = (id) => {
        if (isCallFollow) return true;
        setIsCallFollow(true);
        followUser(id).then(result => {
            callBack(result.data, 1)
        }).finally(() => {
            setIsCallFollow(false);
        }).catch(() => {

        })
    }

    if (user && user.id === userInfo.id) {
        return false
    }

    if (isFollow && user) {
        return <button
            type="button"
            aria-label={userInfo.name}
            className="btn-unfollow-button"
            onClick={() => unFollow(userInfo.id)}
        >
            {(isCallFollow) ? <i className="fa fa-circle-o-notch fa-spin"></i> : 'Following'}
        </button>
    }

    return <button
        type="button"
        aria-label={userInfo.name}
        className="btn-follow-button"
        onClick={() => follow(userInfo.id)}
    >
        {(isCallFollow) ? <i className="fa fa-circle-o-notch fa-spin"></i> : 'Follow'}
    </button>

}
import React, {useState} from "react";
import APP_CONSTANTS from '../../../Constant/index'
import {intToString} from "../../../Lib/Helpers";
import IconHeart from "../../Components/Icon/IconHeart";
import IconComment from "../../Components/Icon/IconComment";
import IconBookmark from "../../Components/Icon/IconBookmark";
import IconLink from "../../Components/Icon/IconLink";
import {followUser, unFollowUser} from "../../Components/FlolowComponent/FollowRequet";
import {useSelector} from "react-redux";
import {Link} from "react-router-dom";
import IconCheck from "../../Components/Icon/IconCheck";

function ActionComponent(props) {
    const user = useSelector(state => state.auth.user);
    const [isCallFollow, setIsCallFollow] = useState(false);
    const item = props.item;
    const showComment = () => {
        props.showComment(item)
    }

    const follow = (id) => {
        if (isCallFollow) return true;
        setIsCallFollow(true);
        followUser(id).finally(() => {
            setIsCallFollow(false);
        })
    }
    const unFollow = (id) => {
        if (isCallFollow) return true;
        setIsCallFollow(true);
        unFollowUser(id).finally(() => {
            setIsCallFollow(false);
        })
    }

    return <div className="menu-actions">
        <div
            className="div-author menu-action-item 1111"
            //style={{backgroundImage: `url(${avatar})`}}
        >
            <Link to={`/profile/${props.item.user.alias}`}>
                <img src={props.item.user.avatarUrl}/>
            </Link>
            {
                (user && user.id === item.user.id) ? "" : <>
                    {
                        (isCallFollow) ? <i className="fa fa-circle-o-notch fa-spin"></i> : <>
                            {
                                (!item.following || !user) ? <i
                                    className="fa fa-plus-circle"
                                    onClick={() => follow(item.user.id)}
                                />:<span
                                    className={`following`}
                                    onClick={() => unFollow(item.user.id)}
                                >
                                    <IconCheck/>
                                </span>
                            }
                        </>
                    }
                </>
            }

        </div>
        <div
            className={"div-like menu-action-item" + (item.isLike ? ' is-like-feed' : '')}
            onClick={() => props.handleLike(item.id)}
        >
            <IconHeart/>
            <span className="span-text count-text">{intToString(item.like_count)}</span>
        </div>
        <div className='comment-icon menu-action-item' onClick={() => showComment()}>
            <IconComment/>
            <span className="span-text count-text">{intToString(item.comment_count)}</span>
        </div>
        <div
            className={"bookmark-icon menu-action-item" + (item.bookmark ? ' is-bookmark-feed' : '')}
            onClick={() => props.handleBookmark(item.id)}
        >
            <IconBookmark/>
            <span className="span-text count-text">{intToString(item.bookmark_count)}</span>
        </div>
        <div className='share-icon menu-action-item'  onClick={() => props.handleCopyLink(item.id)}>
            <IconLink/>
        </div>
    </div>;
}

export default ActionComponent;
import React from 'react';
import Skeleton from 'react-loading-skeleton'
import LoadingComponent from "../../Components/LoadingComponent";
export default function VideoSkeleton({number = 4}) {
    return <div className={`video-slide-item`}>
        {
            [...Array(number)].map((x, i) => {
                    return <div className={`video-content-item`} key={`feed-item-skeleton-${i}`}>
                        <Skeleton/>
                        <LoadingComponent show={true}/>
                    </div>
                }
            )}
    </div>
}
import React, {useState, useEffect} from "react";
import IconClose from "./Icon/IconClose";
import IconBack from "./Icon/IconBack";

function ModalComponent(props) {

    if (!props.show) return false;

    const closePopupPofile = () => {
        props.close();
    }

    return <div className="wrapper-modal">
        <div className={`modal-container ${props.classContainer}`} style={props.style}>
            {(props.showHeader === false) ? <></> :
                <div className='modal-header'>
                    {
                        (props.backButton) ? <div className='back-button' onClick={() => closePopupPofile()}>
                            <IconBack/>
                        </div> : ''
                    }
                    <label className="modal-title">{props.title}</label>
                    {
                        (!props.backButton) ? <div className='close_button' onClick={() => closePopupPofile()}>
                            <IconClose/>
                        </div> : ''
                    }

                </div>
            }

            <div className="modal-content" style={props.styleContent}>
                {props.children}
            </div>
        </div>
    </div>
}

export default ModalComponent
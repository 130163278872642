import React, {useState, useRef, useEffect} from "react";
import EditAavatar from "./EditAavatar";
import ModalComponent from "../../Components/ModalComponent";
import {useFormik} from "formik";
import * as Yup from "yup";
import {getUserAuth, saveUser} from "../../Actions/authAction";
import {checkUpdateUserNameExists, updateProile, checkEmailExit} from "./ProfileRequet";
import {toastError} from "../../Actions/ToastAction";
import APP_CONSTANTS from '../../../Constant'
let timeoutCheckUsername = null;
let timeoutCheckEmail = null;

function EditProfile(props) {
    let userInfo = getUserAuth().user;
    const inputFile = useRef();
    const [valueInputFile, setValueInputFile] = useState('');
    const [showEdittorAvatar, setShowEdittorAvatar] = useState(false);
    const [fileUrl, setFileUrl] = useState(null);
    const [avatar, setAvatar] = useState(null);
    const [fileAvatar, setFileAvatar] = useState(null);
    const [showErrorEmail, setShowErrorEmail] = useState(false);
    const [showErrorUsername, setShowErrorUsername] = useState(false);
    const [showErrorName, setShowErrorName] = useState(false);
    const [showErrorIntro, setShowErrorIntro] = useState(false);
    const [lenghtIntro, setLenghtIntro] = useState(0);
    const [validateForm, setValidateForm] = useState(false);
    const [ichange, setIchange] = useState(false);
    const [formSubmit, setFormSubmit] = useState(false);
    let classForm = 'form-group edit-profile-column edit-profile-intro';
    useEffect(() => {
        setAvatar(userInfo.avatarUrl);
        return () => {
            setFileAvatar(null);
        }
    }, [props.show]);

    const formik = useFormik({
        validateOnChange: true,
        validateOnBlur: true,
        initialValues: {
            email: userInfo.email,
            username: userInfo.username,
            name: userInfo.name,
            intro: userInfo.intro ? userInfo.intro : '',
        },
        validationSchema: Yup.object({
            email: Yup.string().email("Invalid Mail format")
                .required("Mail is required")
                .max(30, 'Maximum 30 characters'),
            username: Yup.string().required("Username is required")
                .min(2, 'Include at least 2 characters in your username')
                .max(24, 'Maximum 24 characters')
                .matches(
                    /^([0-9A-Za-z_]+$)/,
                    "Usernames can only contain letters, numbers, and underscores"
                ),
            name: Yup.string().required("name is required")
                .min(2, 'Include at least 2 characters in your name')
                .max(24, 'Maximum 24 characters'),
            intro: Yup.string().max(80, 'Maximum 80 characters')
        }),

        onSubmit: values => {
            handleSubmit(values);
        },
    });

    const handleSubmit = (values) => {

        if (formSubmit) {
            return false
        }
        //  setServerError(false);
        setFormSubmit(true);
        updateProile(values, fileAvatar).then(result => {
            saveUser(result.data);
            props.close();
        }).catch(error => {

            //setServerError(true);
            let messages = error.data.message;
            if (error.status === 500 || typeof messages === 'string') {
                toastError("Serrver Error");
                return false;
            }

            if (messages.username) {
                setShowErrorUsername(true);
                formik.setFieldError('username', messages['username'][0]);
            }

            if (messages.email) {
                setShowErrorEmail(true);
                formik.setFieldError('email', messages['email'][0]);
            }

            if (messages.name) {
                setShowErrorName(true);
                formik.setFieldError('name', messages['name'][0]);
            }
            if (messages.name) {
                setShowErrorName(true);
                formik.setFieldError('name', messages['name'][0]);
            }
        }).finally(() => {
            setFormSubmit(false);
        })
    }

    const handleChangeFile = async (e) => {
        if (!e.target.files.length) return false;
        //setFileAvatar(e.target.files[0])
        let url = await URL.createObjectURL(e.target.files[0]);
        setFileUrl(url);
        setShowEdittorAvatar(true);
    }

    const triggerInputFile = () => {
        inputFile.current.click()
    }

    const hideEdittorAvatar = () => {
        setShowEdittorAvatar(false);
        setFileUrl(null)
    }

    const callbackHandleSaveAvatar = async (blob) => {
        let file = await new File([blob], "fileAvatar.jpg", {type: "image/jpeg"})
        setFileAvatar(file)
        let url = URL.createObjectURL(blob);
        setAvatar(url);
        hideEdittorAvatar();
    }

    const onFocusEmail = () => {
        //setServerError(false);
        setShowErrorEmail(false);
    }

    const onBlurEmail = () => {
        if (formik.values.email === '') {
            setShowErrorEmail(false);
            return false;
        }

        if (formik.errors.email) {
            setShowErrorEmail(true);
            return false;
        }
        setShowErrorEmail(false);
    }

    const onFocusUsername = () => {
        //setServerError(false);
        setShowErrorUsername(false);
    }

    const onBlurUsername = () => {
        if (formik.values.username === '') {
            setShowErrorUsername(false);
            return false;
        }

        if (formik.errors.username) {
            setShowErrorUsername(true);
            return false;
        }

        setShowErrorUsername(false);
    }

    const onFocusName = () => {
        // setServerError(false);
        setShowErrorName(false);
    }

    const onBlurName = () => {
        if (formik.values.name === '') {
            setShowErrorName(false);
            return false;
        }

        if (formik.errors.name) {
            setShowErrorName(true);
            return false;
        }

        setShowErrorName(false);
    }

    useEffect(() => {
        let introLenght = formik.values.intro.length;
        setLenghtIntro(introLenght)
        if (introLenght > 80) {
            setShowErrorIntro(true);
        } else {
            setShowErrorIntro(false);
        }
    }, [formik.values.intro]);

    useEffect(() => {
        setIchange(false);
        let valueForm = formik.values;
        if (valueForm.intro !== userInfo.intro
            || valueForm.email !== userInfo.email
            || valueForm.username !== userInfo.username
            || valueForm.name !== userInfo.name
            || fileAvatar !== null
        ) {
            setIchange(true);
        }
    }, [formik.values, fileAvatar]);

    useEffect(() => {
        let valueForm = formik.values;
        if (!formik.errors.email && valueForm.email && valueForm.email !== userInfo.email) {
            if (timeoutCheckEmail) {
                clearTimeout(timeoutCheckEmail);
            }

            timeoutCheckEmail = setTimeout(() => {
                checkEmailExit(valueForm.email).catch(error => {
                    //setServerError(true);
                    let messages = error.data.message;
                    if (error.status === 500 || typeof messages === 'string') {
                        toastError("Serrver Error");
                        return false;
                    }
                    if (messages.email) {
                        setShowErrorEmail(true);
                        formik.setFieldError('email', messages['email'][0]);
                    }
                })
            }, 300);
        }

    }, [formik.values.email]);

    useEffect(() => {
        let valueForm = formik.values;
        if (!formik.errors.username && valueForm.username && valueForm.username !== userInfo.username) {
            if (timeoutCheckUsername) {
                clearTimeout(timeoutCheckUsername);
            }
            timeoutCheckUsername = setTimeout(() => {
                checkUpdateUserNameExists(valueForm.username).catch(error => {
                    // setServerError(true);
                    let messages = error.data.message;
                    if (error.status === 500 || typeof messages === 'string') {
                        toastError("Serrver Error");
                        return false;
                    }
                    if (messages.username) {
                        setShowErrorUsername(true);
                        formik.setFieldError('username', messages['username'][0]);
                    }
                })
            }, 300);
        }
    }, [formik.values.username]);

    useEffect(() => {
        if (Object.keys(formik.errors).length) {
            setValidateForm(false);
        } else {
            setValidateForm(true);
        }
    }, [formik.errors]);

    let isDisabled = true;
    if (validateForm && ichange) {
        isDisabled = false
    }


    return <>
        <ModalComponent
            show={props.show}
            close={props.close}
            title={'Edit profile'}
            backButton={false}
            style={{maxWidth: 450}}
        >
            <div className="edit-profile">
                <form
                    onSubmit={formik.handleSubmit}
                    className='auth-form '
                    autoComplete="off"
                >
                    <div className="edit-profile-content popup-in-content">

                        <div className="edit-profile-column edit-profile-avatar">
                            <div
                                className="edit-profile-avatar-img"
                                style={{backgroundImage: `url(${avatar})`}}
                                onClick={() => triggerInputFile()}
                            >
                                <div className="edit-profile-avatar-img-svg">
                                    <svg width="16" data-e2e="" height="16" viewBox="0 0 48 48" fill="currentColor"
                                         xmlns="http://www.w3.org/2000/svg">
                                        <path fillRule="evenodd" clipRule="evenodd"
                                              d="M26.5858 5.08579C27.3479 4.32371 28.5767 4.30253 29.3646 5.03789L36.8646 12.0379C37.2612 12.408 37.4904 12.9232 37.4997 13.4655C37.5091 14.0078 37.2977 14.5307 36.9142 14.9142L16.9142 34.9142C16.5391 35.2893 16.0304 35.5 15.5 35.5H8.5C7.39543 35.5 6.5 34.6046 6.5 33.5V26C6.5 25.4696 6.71071 24.9609 7.08579 24.5858L26.5858 5.08579ZM28.0479 9.2805L10.5 26.8284V31.5H14.6716L32.622 13.5496L28.0479 9.2805Z"></path>
                                        <path
                                            d="M7 41C7 40.4477 7.44772 40 8 40H41C41.5523 40 42 40.4477 42 41V43C42 43.5523 41.5523 44 41 44H8C7.44772 44 7 43.5523 7 43V41Z"></path>
                                    </svg>
                                </div>
                            </div>
                        </div>
                        <div className="form-group edit-profile-column edit-profile-name">
                            <label>Username</label>
                            <input
                                type="text"
                                name="username"
                                placeholder="Username"
                                className={(showErrorUsername) ? "input input-name error" : "input input-name"}
                                value={formik.values.username}
                                onChange={formik.handleChange}
                                autoComplete="off"
                                onFocus={onFocusUsername}
                                onBlur={onBlurUsername}
                            />
                            {showErrorUsername ? <span className="error">{formik.errors.username}</span> : ''}

                        </div>
                        <div className="form-group edit-profile-column edit-profile-name">
                            <label>Name</label>
                            <input
                                value={formik.values.name}
                                className={(showErrorName) ? "input input-name error" : "input input-name"}
                                placeholder="Name"
                                name='name'
                                onChange={formik.handleChange}
                                onFocus={onFocusName}
                                onBlur={onBlurName}
                            />
                            {showErrorName ? <span className="error">{formik.errors.name}</span> : ''}
                        </div>
                        <div className="form-group edit-profile-column edit-profile-name">
                            <label>Email</label>
                            <input
                                value={formik.values.email}
                                className={(showErrorEmail) ? "input input-name error" : "input input-name"}
                                name='email'
                                placeholder="Email"
                                onChange={formik.handleChange}
                                onFocus={onFocusEmail}
                                onBlur={onBlurEmail}
                            />
                            {showErrorEmail ? <span className="error">{formik.errors.email}</span> : ''}
                        </div>

                        <div className="form-group edit-profile-column edit-profile-intro">
                            <label>Intro</label>
                            <textarea
                                value={formik.values.intro}
                                className={(showErrorIntro) ? "input input-intro error" : "input input-intro"}
                                placeholder="Intro"
                                name='intro'

                                onChange={formik.handleChange}
                            />
                            <span
                                className={(showErrorIntro) ? "intro-length error" : "intro-length"}>{lenghtIntro}/80</span>
                            {showErrorIntro ? <span className="error">{formik.errors.intro}</span> : ''}
                        </div>
                    </div>
                    <div className="edit-profile-action footer-popup btn-popup">
                        <button className='btn btn-cancel' type="button" onClick={() => props.close()}>Cancel</button>
                        <button
                            className='btn btn-save'
                            type='submit'
                            disabled={isDisabled}
                        >
                            {formSubmit ? <i className="fa fa-circle-o-notch fa-spin"></i> : 'Save'}
                        </button>
                    </div>
                </form>
                <input
                    ref={inputFile}
                    value={valueInputFile}
                    type="file"
                    className="form-control input-file"
                    placeholder="Search for Friends , Videos and more.."
                    autoComplete="off"
                    onChange={handleChangeFile}
                />
            </div>
        </ModalComponent>

        <EditAavatar
            show={showEdittorAvatar}
            hideEdittorAvatar={hideEdittorAvatar}
            fileUrl={fileUrl}
            callbackHandleSaveAvatar={callbackHandleSaveAvatar}
        />
    </>
}

export default EditProfile;
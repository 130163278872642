import store from "../../app/store";
import {updateVideoListItem} from "../Slice/VideoSlice";

export const updateLike = (id, status, countLike) => {
    const videos  = [...store.getState().videos.list];
    const index = videos.findIndex(item => item.id === id);
    let item = {... videos[index]}
    item.isLike = status;
    item.like_count = countLike;
    videos[index] = item;
    store.dispatch(updateVideoListItem(videos));
}

export const updateBookmark = (id, status, countLike) => {
    const videos  = [...store.getState().videos.list];
    const index = videos.findIndex(item => item.id === id);
    let item = {... videos[index]}
    item.bookmark = status;
    item.bookmark_count = countLike;
    videos[index] = item;
    store.dispatch(updateVideoListItem(videos));
}
export const updateCountCommentVideo = (id, countComment) => {
    const videos  = [...store.getState().videos.list];
    const index = videos.findIndex(item => item.id === id);
    let item = {... videos[index]}
    item.comment_count = countComment;
    videos[index] = item;
    store.dispatch(updateVideoListItem(videos));
}

export const updateFollowUserVideo = (userID, status) => {
    const videos  = [...store.getState().videos.list];
    for (let i = 0; i < videos.length; i++) {
        if (userID !== videos[i].user.id) {
            continue;
        }

        let item = {... videos[i]}

        item.following = status;

        videos[i] = item;
    }
    store.dispatch(updateVideoListItem(videos));
}


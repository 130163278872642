import store from "../../../app/store";
import Axios from "../../../Lib/Axios";
import {toastError} from "../../Actions/ToastAction";
import {showForm, setPostData} from "../../Slice/FormPostSlice";

export const createPost = (values) => {
    return Axios.post('post/create', values);
}

export const getPostEdit = (id) => {
     Axios.get(`post/edit/${id}`).then(result => {
            store.dispatch(showForm(true))
            store.dispatch(setPostData(result.data));

     }).catch(error => {
         if (error.status === 500) {
             toastError("Serrver Error");
             return error;
         }

         if (error.status === 404) {
             toastError(`${error.status} ${error.statusText}`);
             return error;
         }

         toastError(`${error.status} ${error.statusText}`);
     })
}

export const updatePost = (id,values) => {
    return Axios.put(`post/update/${id}`, values);
}

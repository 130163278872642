import axiosInstance from 'axios';

import APP_CONSTANTS from '../Constant/index'
import {clearUserData, setShowAuth} from "../Funlove/Actions/authAction";

const apiCallStack = []

const Axios = axiosInstance.create({
    baseURL: process.env.REACT_APP_API_ENDPOINT,
    timeout: 30000
})

Axios.interceptors.request.use(async function (config) {

    const userToken = await localStorage.getItem(APP_CONSTANTS.USER_TOKEN)
    const backgroundCall = config.data && config.data.background === true
    if (!backgroundCall) {
        apiCallStack.push(config.url)
    }

    let headers = {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        //'Accept': '*',
    }

    if (config.headers) {
        headers = config.headers
    }

    if (userToken) {
        headers['Authorization'] = "Bearer " + userToken
    }

    //config.headers.common = headers
    config.headers = headers
    return config;
}, function (error) {
    // Do something with request error
    return Promise.reject(error);
});

// Add a response interceptor
Axios.interceptors.response.use(function (response) {

    const apiCallIndex = apiCallStack.indexOf(response.config.url)
    if (apiCallIndex !== -1) {
        apiCallStack.splice(apiCallIndex, 1)
    }

    return response;

}, async function (error) {

    const apiCallIndex = apiCallStack.indexOf(error.config.url)
    if (error.response.status === 401) {
        clearUserData()
      //  setShowAuth(1)
        // sessionStorage.setItem(APP_CONSTANTS.USER, null);
        // localStorage.setItem(APP_CONSTANTS.USER_TOKEN, null);
      //  localStorage.removeItem(APP_CONSTANTS.USER_TOKEN);
        //localStorage.removeItem(APP_CONSTANTS.USER);

        // console.log(window.location.origin);
        //window.location.href = window.location.origin + "/login";

    }

    if (apiCallIndex !== -1) {
        apiCallStack.splice(apiCallIndex, 1)
    }

    return await Promise.reject(error.response);
});

export default Axios

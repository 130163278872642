import {createSlice, current} from "@reduxjs/toolkit";
const CommentSlice = createSlice({
    name: "CommentSlice",
    initialState: {
        list: [],
        skipId: []
    },
    reducers: {
        setListComment: (state, action) => {
            state.list = action.payload;
        },
        setSkipCommentId: (state, action) => {
            // state.skipId = state.skipId.concat(action.payload);
             state.skipId = action.payload;
        }
    }
});

const {reducer, actions} = CommentSlice;
export const {setListComment, setSkipCommentId} = actions;
export default reducer;
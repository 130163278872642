import React, {useEffect, useState} from 'react';
import APP_CONSTANTS from "../../../Constant";
import {setVideoPostion} from "../../../Lib/Video";

let timeOutResize;
export default function VideoItemActive({item, idItem}) {

    useEffect(() => {
        if (idItem) {
            window.addEventListener('resize', resizeSetVideoPostion);
        }

        return () => {
            window.removeEventListener('resize', resizeSetVideoPostion);
        };
    }, [idItem]);

    const resizeSetVideoPostion = () => {
        if (!idItem) {
            return false
        }
        setVideoPostion(`video-content-item-${idItem}`)
    }

    if (!item) {
        return false;
    }

    useEffect(() => {
        let videoElement = document.getElementById(`video-active`);
        videoElement.className = 'video-active';
        videoElement.poster = item.thumb
        videoElement.src = item.url;
        videoElement.onloadeddata = () => {
            setVideoPostion(`video-content-item-${idItem}`);
        }

    }, [item.id]);

    return <></>
}
import React, {useState, useEffect} from "react";
import {useFormik} from "formik";
import * as Yup from "yup";
import {checkEmailExists, checkUsernameExists, registerRequest} from "./AuthRequest";
import {authenUser, saveToken, saveUser} from "../../Actions/authAction";
import {toastError} from "../../Actions/ToastAction";
import IconCheckSuccess from "../Icon/IconCheckSuccess";

let validatePassDafult = {
    passLenghtClass: '',
    passLenghtStatus: 0,
    passMatchessStatus: 0, // 0 dafult, 1 pass, 2 error
    passMatchesClass: '',
    showError: false,
    showPassRegExp: false,
    isForcus: false
}
const passRegExp = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/;
let timeoutHandler = null;
let timeoutCheckEmail = null;
let timeoutCheckUsername = null;
let regexEmail = new RegExp('[a-z0-9]+@[a-z]+\.[a-z]{2,3}');

function RegisterComponent(props) {

    const [showErrorEmail, setShowErrorEmail] = useState(false);
    const [showErrorUsername, setShowErrorUsername] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);
    const [validateForm, setValidateForm] = useState(false);
    const [serverError, setServerError] = useState(false);
    const [showPass, setShowPass] = useState(true);
    const [showConfirmPass, setShowConfirmPass] = useState(true);
    const [formSubmit, setFormSubmit] = useState(false);
    const [validatePass, setValidatePass] = useState(validatePassDafult);
    const [serverMessage, setServerMessage] = useState(null);

    const formik = useFormik({
        validateOnChange: true,
        validateOnBlur: true,
        initialValues: {
            email: 'langtuhoabinh2010@gmail.com',
            username: 'langtuhoabinh',
            password: 'Funlove@2002',
            confirmPassword: 'Funlove@2002'
        },
        validationSchema: Yup.object({
            email: Yup.string().email("Invalid Mail format")
                .required("Mail is required")
                .max(30, 'Maximum 30 characters'),
            username: Yup.string().required("Username is required")
                .min(2, 'Include at least 2 characters in your username')
                .max(24, 'Maximum 24 characters')
                .matches(
                    /^([0-9A-Za-z_]+$)/,
                    "Usernames can only contain letters, numbers, and underscores"
                ),
            password: Yup.string().required(1)
                .min(8, '8 to 20 characters')
                .max(20, '8 to 20 characters')
                .matches(passRegExp, 'Letters, numbers, and special characters'),
            confirmPassword: Yup.string().oneOf([Yup.ref('password'), null], 'Passwords must match')
        }),

        onSubmit: values => {
            handleSubmit(values);
        },
    });

    useEffect(() => {
        clearTimeout(timeoutCheckUsername)
        if (formik.values.username) {
            timeoutCheckUsername = setTimeout(() => {
                checkUsernameExists(formik.values.username).catch(error => {
                    formik.setFieldError('username', error.error);
                    setShowErrorUsername(true);
                })
            }, 500);
        }
    }, [formik.values.username]);

    useEffect(() => {
        clearTimeout(timeoutCheckEmail)
        if (formik.values.email && regexEmail.test(formik.values.email)) {
            timeoutCheckEmail = setTimeout(() => {
                checkEmailExists(formik.values.email).catch(error => {
                    formik.setFieldError('email', error.error);
                    setShowErrorEmail(true);
                })
            }, 500);
        }
    }, [formik.values.email]);

    useEffect(() => {
        checkPass(formik.values.password);
    }, [formik.values.password]);
    useEffect(() => {
        if (!Object.keys(formik.errors).length
            && formik.values.email !== ''
            && formik.values.username !== ''
            && formik.values.password !== ''
            && formik.values.confirmPassword !== ''
        ) {
            setValidateForm(true);
        } else {
            setValidateForm(false)
        }
    }, [formik.errors]);

    const checkPass = (pass) => {
        let lengthPass = pass.length;
        if (!lengthPass) {
            let validatePassDafultCurrnt = {...validatePassDafult};
            if (validatePass.isForcus) {
                validatePassDafultCurrnt.showPassRegExp = true
            }

            setValidatePass(validatePassDafultCurrnt)
            return false
        }

        let validatePassCurrnt = {...validatePass};
        validatePassCurrnt.passLenghtStatus = 2;
        validatePassCurrnt.passLenghtClass = 'error';
        validatePassCurrnt.passMatchessStatus = 2;
        validatePassCurrnt.passMatchesClass = 'error';

        if (lengthPass >= 8 && lengthPass <= 20) {
            validatePassCurrnt.passLenghtStatus = 1;
            validatePassCurrnt.passLenghtClass = 'pass';
        }

        if (passRegExp.test(pass)) {
            validatePassCurrnt.passMatchessStatus = 1;
            validatePassCurrnt.passMatchesClass = 'pass';
        }

        setValidatePass(validatePassCurrnt);
    }

    const handleSubmit = async (values) => {

        if (formSubmit) {return false}
        setServerError(false);
        setFormSubmit(true);
        setServerMessage(null);
        registerRequest(values).then(result => {
            saveUser(result.data);
            saveToken(result.data.token);
            props.closeAuth(false)
        }).catch(error => {

            setServerError(true);
            let messages = error.data.message;
            if (error.status === 500 || typeof messages === 'string') {
                toastError("Serrver Error");
                return false;
            }

            if (messages.email) {
                setShowErrorEmail(true);
                formik.setFieldError('email', messages['email'][0]);
            }
            if (messages.username) {
                setShowErrorUsername(true);
                formik.setFieldError('username', messages['username'][0]);
            }

        }).finally(() => {
            setFormSubmit(false);
            ///setServerError(true);
        })
    }

    const onFocusEmail = () => {
        setServerError(false);
        setShowErrorEmail(false);
    }

    const onBlurEmail = () => {
        if (formik.values.email === '') {
            setShowErrorEmail(false);
            return false;
        }
        if (formik.errors.email) {
            setShowErrorEmail(true);
            return false;
        }
        setShowErrorEmail(false);
    }

    const onFocusUsername = () => {
        setServerError(false);
        setShowErrorUsername(false);
    }

    const onBlurUsername = () => {
        if (formik.values.username === '') {
            setShowErrorUsername(false);
            return false;
        }
        if (formik.errors.username) {
            setShowErrorUsername(true);
            return false;
        }
        setShowErrorUsername(false);
    }

    const onFocusConfirmPassword = () => {
        setServerError(false);
        setShowConfirmPassword(false);
    }

    const onBlurConfirmPassword = () => {
        if (formik.values.confirmPassword === '') {setShowConfirmPassword(false);
            return false;
        }
        if (formik.errors.confirmPassword) {
            setShowConfirmPassword(true);
            return false;
        }
        setShowConfirmPassword(false);
    }

    const onFocusPass = () => {
        setServerError(false);
        let validatePassCurrnt = {...validatePass};
        validatePassCurrnt.showError = false;
        validatePassCurrnt.showPassRegExp = true;
        validatePassCurrnt.isForcus = true;
        setValidatePass(validatePassCurrnt);
    }

    const onBlurPass = () => {
        let validatePassCurrnt = {...validatePass};
        validatePassCurrnt.isForcus = false;
        if (validatePassCurrnt.passLenghtStatus === 2 || validatePassCurrnt.passMatchessStatus === 2) {
            validatePassCurrnt.showError = true;
            validatePassCurrnt.showPassRegExp = true;
        } else {
            validatePassCurrnt.showPassRegExp = false;
        }
        setValidatePass(validatePassCurrnt);
    }

    return <>
        <h1 className='auth-title'>Sign up</h1>
        <form
            onSubmit={formik.handleSubmit}
            className='auth-form'
            autoComplete="off"
        >
            <div className="div-description">E-mail Address</div>
            <div className={'form-group'}>
                <input
                    type="text"
                    name="email"
                    placeholder="E-mail Address"
                    value={formik.values.email}
                    onChange={formik.handleChange}
                    autoComplete="off"
                    onFocus={onFocusEmail}
                    onBlur={onBlurEmail}
                />
                <i className="fa fa-warning"></i>
                {showErrorEmail ? <span className="error">{formik.errors.email}</span> : ''}
            </div>
            <div className={'form-group'}>
                <input
                    type="text"
                    name="username"
                    placeholder="Username"
                    value={formik.values.username}
                    onChange={formik.handleChange}
                    autoComplete="off"
                    onFocus={onFocusUsername}
                    onBlur={onBlurUsername}
                />
                <i className="fa fa-warning"></i>
                {showErrorUsername ? <span className="error">{formik.errors.username}</span> : ''}
            </div>
            <div className={(validatePass.showError) ? 'form-group field-error' : 'form-group'}>
                <input
                    type={(!showPass) ? 'password' : 'text'}
                    id="password"
                    className="form-control"
                    placeholder="Password"
                    autoComplete="off"
                    name="password"
                    value={formik.values.password}
                    onChange={formik.handleChange}
                    onFocus={onFocusPass}
                    onBlur={onBlurPass}
                />
                <i className="fa fa-warning fa-warning-pass"></i>
                <i
                    className={(!showPass) ? 'show-pass fa fa-eye' : 'show-pass fa fa-eye-slash'}
                    onClick={() => setShowPass(!showPass)}
                />

                {(validatePass.showPassRegExp)? <>
                    <p className="div-description" style={{marginTop: 10}}>Your password must have:</p>
                    <div className={`div-text-container ${validatePass.passLenghtClass}`}>
                        <IconCheckSuccess/>
                        <span role="status">8 to 20 characters</span>
                    </div>
                    <div className={`div-text-container ${validatePass.passMatchesClass}`}>
                        <IconCheckSuccess/>
                        <span role="status">Letters, numbers, and special characters</span>
                    </div>
                </>: ''
                }

             </div>

            <div className={'form-group'}>
                <input
                    type={(!showConfirmPass) ? 'password' : 'text'}
                    id="confirmPassword"
                    className="form-control"
                    placeholder="Confirm Password"
                    autoComplete="off"
                    name="confirmPassword"
                    value={formik.values.confirmPassword}
                    onChange={formik.handleChange}
                     onFocus={onFocusConfirmPassword}
                     onBlur={onBlurConfirmPassword}
                />
                <i className="fa fa-warning fa-warning-pass"></i>
                <i
                    className={(!showConfirmPass) ? 'show-pass fa fa-eye' : 'show-pass fa fa-eye-slash'}
                    onClick={() => setShowConfirmPass(!showConfirmPass)}
                />
                {showConfirmPassword ? <span className="error">{formik.errors.confirmPassword}</span> : ''}
            </div>
            <div className="form-group">
                <button
                    className="login-btn btn auth-btn"
                    style={{marginBottom: 10}}
                    type="submit"
                    disabled={(!validateForm)}
                >
                    {formSubmit ? <i className="fa fa-circle-o-notch fa-spin"></i> : 'Register'}
                </button>
                {serverError && serverMessage !== null ? <span className="error" id="errorLogin">{serverMessage}</span> : ''}
            </div>

        </form>
        <div className="agreement">
            <p className="p-text">By continuing, you agree to TikTok’s
                <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href="https://www.tiktok.com/legal/terms-of-use?lang=en"
                    className="a-link"
                >
                    Terms of Service
                </a>
                and confirm that you have read TikTok’s
                <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href="https://www.tiktok.com/legal/privacy-policy?lang=en"
                    className="a-link"
                >
                    Privacy Policy
                </a>.
            </p>
        </div>
    </>
}

export default RegisterComponent;


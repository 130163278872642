import React, {useState, useEffect, useRef} from "react";
import EmojiPicker from "emoji-picker-react";

function EmojiPickerComponent(props) {

    const emojiPicker = useRef(null)
    useEffect(() => {
        function handleClickOutside(event) {
            if (emojiPicker.current && !emojiPicker.current.contains(event.target)) {
                props.configShow(false)
            }
        }

        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [props.show, emojiPicker]);


    const closeEmojiPicker = () => {
        props.close();
    }

    const onChange = (emojiPicker) => {
      props.onChange(emojiPicker)
    }

    if (!props.show) {
        return  <></>
    }

    return <div
        className={`emojiPicker`}
        ref={emojiPicker}
        style={{top: props.bounding}}

    >
        <EmojiPicker
            onEmojiClick={onChange}
            open={props.show}
            searchDisabled={true}
            Theme={`auto`}
            width={250}
            height={350}
            skinTonesDisabled={true}
            autoFocusSearch={false}
        />
    </div>
}

export default EmojiPickerComponent;


import React from "react";
import Menus from "./Menus";
import Follows from "./Follows";

function NavLeft(props) {

    return <div className="menu-left-content">
        <Menus/>
        <Follows/>
    </div>
}
export default NavLeft;
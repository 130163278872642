import React, {useState, useEffect, useRef} from "react";
import {useNavigate, Link} from 'react-router-dom';
import {useSelector} from "react-redux";
import {logout, setShowAuth} from "../../../Funlove/Actions/authAction";
import {getFormCreatePostAction} from "../../../Funlove/Actions/formCreateAction";
import APP_CONSTANTS from '../../../Constant/index'
import IconLogout from "../../../Funlove/Components/Icon/IconLogout";
import IconHeart from "../../../Funlove/Components/Icon/IconHeart";
import IconUserProfile from "../../../Funlove/Components/Icon/IconUserProfile";
function Header(props) {
    const auth = useSelector(state => state.auth);
    const [isActiveDropdownMenu, setIsActiveDropdownMenu] = useState(false);
    const dropdownRef = useRef(null);

    useEffect(() => {
        const pageClickEvent = (e) => {
            if (dropdownRef.current !== null && !dropdownRef.current.contains(e.target)) {
                setIsActiveDropdownMenu(!isActiveDropdownMenu);
            }
        };

        if (isActiveDropdownMenu) {
            window.addEventListener('click', pageClickEvent);
        }

        return () => {
            window.removeEventListener('click', pageClickEvent);
        }
    }, [isActiveDropdownMenu]);
    const checkAuth = () => {

    }

    return <div className="header-container">
        <div className="header-left-container">
            <h1 className="logo">
                <Link to="/" className="styled-link">Funlove</Link>
            </h1>
        </div>
        <div className="header-center-container">
            <div className="div-search-form-container">
                <form className="search-form form-element" action="/search">
                    <input
                        type="search"
                        placeholder="Search"
                        name="q"
                        autoComplete="off"
                        role="combobox"
                        aria-controls=""
                        aria-label="Search"
                        aria-expanded="false"
                        aria-autocomplete="list"
                        className="input-element search-input"
                        defaultValue=""
                    />
                    <span className="span-spliter"></span>
                    <button
                        type="submit"
                        aria-label="Search"
                        className="button-search"
                    >
                        <div className="search-icon-container">
                            <svg width="24" data-e2e="" height="24" viewBox="0 0 48 48" fill="rgba(22, 24, 35, .34)"
                                 xmlns="http://www.w3.org/2000/svg">
                                <path fillRule="evenodd" clipRule="evenodd"
                                      d="M22 10C15.3726 10 10 15.3726 10 22C10 28.6274 15.3726 34 22 34C28.6274 34 34 28.6274 34 22C34 15.3726 28.6274 10 22 10ZM6 22C6 13.1634 13.1634 6 22 6C30.8366 6 38 13.1634 38 22C38 25.6974 36.7458 29.1019 34.6397 31.8113L43.3809 40.5565C43.7712 40.947 43.7712 41.5801 43.3807 41.9705L41.9665 43.3847C41.5759 43.7753 40.9426 43.7752 40.5521 43.3846L31.8113 34.6397C29.1019 36.7458 25.6974 38 22 38C13.1634 38 6 30.8366 6 22Z"></path>
                            </svg>
                        </div>
                    </button>
                </form>
            </div>
        </div>
        <div className="header-right-container">
            <div className="upload-container">
                <div
                    className="styled-link a-upload"
                    aria-label="Upload a video"
                    onClick={() => {
                        if (!auth.user) {
                            return setShowAuth(1)
                        }
                        getFormCreatePostAction()
                        //navigate('/upload')
                    }}
                >
                    <div className="div-upload">
                        <svg className="plus-icon" width="1em" data-e2e="" height="1em"
                             viewBox="0 0 16 16" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                            <path fillRule="evenodd" clipRule="evenodd"
                                  d="M8 2.5C7.58579 2.5 7.25 2.83579 7.25 3.25V7.25H3.25C2.83579 7.25 2.5 7.58579 2.5 8C2.5 8.41421 2.83579 8.75 3.25 8.75H7.25V12.75C7.25 13.1642 7.58579 13.5 8 13.5C8.41421 13.5 8.75 13.1642 8.75 12.75V8.75H12.75C13.1642 8.75 13.5 8.41421 13.5 8C13.5 7.58579 13.1642 7.25 12.75 7.25H8.75V3.25C8.75 2.83579 8.41421 2.5 8 2.5Z"></path>
                        </svg>
                        <span className="span-upload-text">Upload </span></div>
                </div>
            </div>
            {(auth.user) ? <>

                {/*-------------- Inbox ---------------*/}
                <div className="header-inbox-container">
                    <span>
                        <svg
                            className="styled-inbox-icon"
                            width="32"
                            data-e2e=""
                            height="32"
                            viewBox="0 0 32 32"
                            fill="currentColor"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path fillRule="evenodd" clipRule="evenodd"
                                  d="M24.0362 21.3333H18.5243L15.9983 24.4208L13.4721 21.3333H7.96047L7.99557 8H24.0009L24.0362 21.3333ZM24.3705 23.3333H19.4721L17.2883 26.0026C16.6215 26.8176 15.3753 26.8176 14.7084 26.0026L12.5243 23.3333H7.62626C6.70407 23.3333 5.95717 22.5845 5.9596 21.6623L5.99646 7.66228C5.99887 6.74352 6.74435 6 7.66312 6H24.3333C25.2521 6 25.9975 6.7435 26 7.66224L26.0371 21.6622C26.0396 22.5844 25.2927 23.3333 24.3705 23.3333ZM12.6647 14C12.2965 14 11.998 14.2985 11.998 14.6667V15.3333C11.998 15.7015 12.2965 16 12.6647 16H19.3313C19.6995 16 19.998 15.7015 19.998 15.3333V14.6667C19.998 14.2985 19.6995 14 19.3313 14H12.6647Z"></path>
                        </svg>
                    </span>

                    <div className="div-header-inbox-wrapper">
                        <div className="div-inbox-container"></div>
                    </div>
                </div>


                {/*-------------- Users ---------------*/}
                <div
                    title={auth.user.name}
                    ref={dropdownRef}
                    id="header-more-menu-icon"
                    tabIndex="0"
                    style={{backgroundImage: `url(${auth.user.avatarUrl})`}}
                    role="button"
                    aria-label="Open settings menu"
                    aria-expanded="false"
                    aria-haspopup="true"
                    aria-controls=""
                    className="div-profile-container dropdown-header-profile 123"
                    onClick={() => setIsActiveDropdownMenu(!isActiveDropdownMenu)}
                >
                    {(isActiveDropdownMenu) ? <>
                        <div className="dropdown-menu">
                            <Link className="dropdown-menu-item" to={`/profile/${auth.user.alias}`}>
                                <IconUserProfile/>
                                View Profile
                            </Link>
                            <button
                                className="dropdown-menu-item"
                                onClick={() => logout()}
                            >
                                <IconLogout/>
                                Logout
                            </button>
                        </div>
                    </> : ''}
                </div>

            </> : <div className="header-login-container">
                <button type="button" id="header-login-button" onClick={() => setShowAuth(1)}>Log in</button>
            </div>}
        </div>
    </div>
}

export default Header;
import React, {Suspense} from "react";
import {BrowserRouter as Router, Routes, Route} from 'react-router-dom';
import { SkeletonTheme } from 'react-loading-skeleton';

import Layouts from "../Layout/Funlove";
import Videos from './Videos/index';
import Profile from "./Profile";
import NewFeeds from "./NewFeeds";

function Index(props) {

    return <Router>
        <Suspense fallback={
            <div className={`loading-page`}>
            <div className="loader-line"></div>
        </div>
        }>
            <Layouts>
                <SkeletonTheme>
                    <Routes>
                    <Route path="/*" element={<NewFeeds/>}/>
                    <Route path="/Videos/:id" element={<Videos/>}/>
                    <Route path="/Videos" element={<Videos/>}/>
                    <Route path="/profile/:alias"  element={<Profile/>}/>
                    <Route path="/profile/:alias/post/:id"  element={<Profile/>}/>
                    <Route path="/post/:id" element={<NewFeeds/>}/>
                </Routes>
                </SkeletonTheme>
            </Layouts>
        </Suspense>
    </Router>
}


export default Index;
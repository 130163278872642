import store from "../../app/store";
import {setMute} from "../Slice/MuteSlice";

export const updateMute = (status) => {
    store.dispatch(setMute(status));
}

export const getMute = () => {
    return store.getState().mute.mute;
}

import {createSlice, current} from "@reduxjs/toolkit";

const FollowSlice = createSlice({
    name: "FollowSlice",
    initialState: {
        list: [],
        stop: false,
    },
    reducers: {
        setListFollow: (state, action) => {
            state.list = action.payload;
        },
        setLoadMoreStop: (state, action) => {
            state.stop = action.payload;
        },
    }
});

const {reducer, actions} = FollowSlice;
export const {setListFollow, setLoadMoreStop} = actions;
export default reducer;
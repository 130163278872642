import React from 'react';
import {LazyLoadImage} from "react-lazy-load-image-component";
import APP_CONSTANTS from "../../../Constant";

export default function GrildFeed({files, grildTemplate, postItem, callBack}) {

    if (!files.length) {
        return false;
    }

    const itemGrid = (item, count, index,  indexItem = 0, classIten = 'img') => {

        let uuuid = item.id;
        if (item.type === 'video') {
            return <div
                className={`grid-item ${classIten}_${count > 5 ? 5 : count}_${index + 1} video`}
                key={`grid-item-${uuuid}`}
                id={`grid-item-${uuuid}`}
            >
                {/*<LazyLoadImage*/}
                {/*    src={item.thumb}*/}
                {/*    id={`grid-item-${uuuid}`}*/}
                {/*    draggable={false}*/}
                {/*/>*/}
                <img
                    src={item.thumb}
                    id={`grid-item-${uuuid}`}
                    draggable={false}
                />
                <i className={`icon-play`} onClick={() => callBack(postItem, indexItem)}></i>
            </div>
        }

        return <div
            className={`grid-item ${classIten}_${count > 5 ? 5 : count}_${index + 1}`}
            key={`grid-item-${uuuid}`}
        >
            <LazyLoadImage
                src={item.url}
                id={`grid-item-${uuuid}`}
                draggable={false}
                onClick={() => callBack(postItem, indexItem)}
            />
        </div>
    }

    let lengthItem = files.length;
    let subIndex = 0
    if (lengthItem < 5) {
        return <div className={`grid-container ${grildTemplate}`}>
            <div className={`row-grid-container grid-lenght-${(lengthItem > 5) ? 5 : lengthItem}`}>
                {
                    files.map((item, index) => {
                        if (index > 4) {
                            return null
                        }
                        return itemGrid(item, lengthItem, index, index)
                    })}
            </div>
        </div>
    }

    return <div className="grid-container">
        <div className={`sub_grid_wrapper`}>
            <div className={`sub_grid_wrapper_first_row grid_wrapper_2_img`}>
                {
                    files.map((item, index) => {
                        if (index > 1) {
                            return null
                        }
                        return itemGrid(item, 2, index, index)
                    })
                }
            </div>
            <div className={`sub_grid_wrapper_second_row sub_grid_wrapper_3_img`}>
                {
                    files.map((item, index) => {
                        if (index < 2 || index > 4) {
                            return null
                        }

                        if (index === 4 && lengthItem > 5) {
                            return <div className="img sub_img_3_3 show_more_img" key={`sub-img-3-3-${item.id}`}>
                                {itemGrid(item, 3, index, index, 'sub_img')}
                                <div className="show_more_text"
                                     onClick={() => callBack(postItem, index)}> +{lengthItem - 5}</div>
                            </div>
                        }

                        let iten = itemGrid(item, 3, subIndex, index,'sub_img');
                        subIndex++;
                        return iten;
                    })
                }
            </div>
        </div>
    </div>
}
import React, {useRef, useEffect, useState} from 'react';
import {useSelector} from "react-redux";
import CommentItem from "./CommentItem";
import {LoadingComment} from "./LoadingComment";
import {getSkipIds} from "../../Actions/CommentList";
import APP_CONSTANTS from "../../../Constant";
import IconClose from "../Icon/IconClose";
import IconBack from "../Icon/IconBack";
import ModalComponent from "../ModalComponent";
import {pauseVideo, playVideo} from "../../../Lib/Video";
import {likeComment} from "../../Post/Component/PostRequest";
import ModalConfirm from "../ModalConfirm";

let mediaType = null;
function ListComment({setLastId = null, loading, replyComment, hadleKeyPress, likeComment, deleteComment, commentChildrenList}) {
    const commentState = useSelector(state => state.listComment);
    const userState = useSelector(state => state.auth);
    const [showMedia, setShowMedia] = useState(false)
    const [mediaContent, setMediaContent] = useState(null)
    const [deleteCommentItem, setDeleteCommentItem] = useState(null);
    const user = userState.user;
    useEffect(() => {
        let comentIds = commentState.list.map(({id}) => id);
        let skipId = getSkipIds();
        let ids = comentIds.filter((el) => !skipId.includes(el));
        let id = ids.at(-1);
        setLastId(id)
    }, [commentState.list])

    if (commentState.list.length <= 0 && !loading) {
        return <div className="comment-list no-comment">
            <p className={`no-comment-text`}>No comments yet</p>
        </div>
    }

    const hadleKeyPressMedia = (e) => {

        if (e.keyCode !== 27) {
            return false;
        }
        closeMedia();
    }

    const hadleDetailMedia = (media) => {
        mediaType = media.type;
        setMediaContent(media);
        setShowMedia(true);
        if (hadleKeyPress) {
            hadleKeyPress(true);
        }

        document.getElementById("root").classList.add("hide-media-control");
        document.addEventListener("keydown", hadleKeyPressMedia);
        if (media.type === 'video') {
            pauseVideo();
        }
    }
    const closeMedia = () => {

        if (mediaType === 'video') {
            playVideo();
        }
        setShowMedia(false);
        mediaType = null;
        setMediaContent(null);
        if (hadleKeyPress) {
            hadleKeyPress(false);
        }

        // playVideo();
        document.getElementById("root").classList.remove("hide-media-control");
        document.removeEventListener("keydown", hadleKeyPressMedia)
    }

    const confirmDelete = (iteam) => {
        deleteComment(iteam);
        setDeleteCommentItem(null);
    }

    return <>
        <ModalConfirm
            title={'Are you sure you want to delete this comment?'}
            confirmDelete={confirmDelete}
            iteam={deleteCommentItem}
            cancel={setDeleteCommentItem}
        />
        <div className="comment-list" id={`comment-list`} key={`comment-key`}>
            {
                commentState.list.map(item => {
                    return <CommentItem
                        item={item}
                        user={user}
                        key={`commnet-item-key-parent-${item.id}`}
                        replyComment={replyComment}
                        hadleDetailMedia={hadleDetailMedia}
                        likeComment={likeComment}
                        deleteComment={setDeleteCommentItem}
                        commentChildrenList={commentChildrenList}
                    >
                        {
                            (item.child.length) ? <div
                                className="comment-list comment-list-child"
                                key={`commnet-item-key-child-${item.id}`}
                            >
                                {
                                    item.child.map(childItem => {
                                        return <CommentItem
                                            item={childItem}
                                            user={user}
                                            loadCommentChildren={null}
                                            key={`commnet-item-key-child-${childItem.id}`}
                                            replyComment={replyComment}
                                            hadleDetailMedia={hadleDetailMedia}
                                            likeComment={likeComment}
                                            deleteComment={setDeleteCommentItem}
                                            commentChildrenList={commentChildrenList}
                                        />
                                    })
                                }

                            </div> : <></>
                        }
                    </CommentItem>

                })
            }
            <ModalComponent
                show={showMedia}
                close={closeMedia}
                showHeader={false}
            >
                {(mediaContent) ? <div className={`view-meida-item`}>
                        <div className={`view-meida-comtent`}>
                            {
                                (mediaContent.type === 'video') ? <video
                                        src={mediaContent.url}
                                        controls={true}
                                        controlsList="nodownload noplaybackrate noremoteplayback"
                                        disablePictureInPicture={true}
                                        playsInline={true}
                                        autoPlay={true}
                                        loop
                                    /> :
                                    <img
                                        src={mediaContent.url}
                                        draggable={false}
                                    />
                            }
                            <button id={`button-post-back`} onClick={() => closeMedia()}>
                                {
                                    (window.innerWidth > 820) ? <IconClose/> : <IconBack/>
                                }
                            </button>
                        </div>
                    </div>
                    : ""

                }
            </ModalComponent>
            <LoadingComment show={loading} num={3}/>

        </div>
    </>
}

export default ListComment;

//DivCommentListContainer
import React from "react";
import Dropdown from 'react-bootstrap/Dropdown';
import {getPostEdit} from "../FormPostComponent/FormPostRequest";
import UserInfoContainer from "./UserInfoContainer";
import DropdownContainer from "./DropdownContainer";

export default function FeedHead({item, user, deleteItem}) {
    return <div className={`feed-item-head`}>
        <UserInfoContainer user={user} item={item}/>
        {(user && item.user_id === user.id) ?
            <DropdownContainer>
                <Dropdown.Item onClick={() => getPostEdit(item.id)}>
                    <i className="fa fa-pencil"></i>
                    Edit
                </Dropdown.Item>
                <Dropdown.Item onClick={() => deleteItem(item)}>
                    <i className="fa fa-trash-o"></i>
                    Delete
                </Dropdown.Item>
            </DropdownContainer> : <></>
        }
    </div>
}
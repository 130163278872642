import React, {useState, useEffect, useRef} from "react";
import {Carousel} from 'react-responsive-carousel';
import {useSelector} from "react-redux";

let indexCurrunt = null;
let timeoutHandleStyleVideo = null;
export default function CarouselComponent(props) {
    if (!props.item || !props.item.media.length) {
        return <></>
    }

    let medias = props.item.media;
    let selectedItem = props.selectedItem ? props.selectedItem : 0
    const [itemIndexBefore, setItemIndexBefore] = useState(null)
    const [showArrows, setShowArrows] = useState(window.innerWidth > 932 ? true : false)
    const muteState = useSelector(state => state.mute);
    useEffect(() => {
        handleCarousel(selectedItem);
        window.addEventListener('resize', delayhandleStyleVideo);
        return () => {
            clearTimeout(timeoutHandleStyleVideo);
            resetVideo();
            window.removeEventListener('resize', delayhandleStyleVideo);
        }
    }, [props.item]);

    const delayhandleStyleVideo = () => {
        handleStyleVideo(itemIndexBefore)
        // timeoutHandleStyleVideo = setTimeout(function () {
        //     handleStyleVideo(itemIndexBefore)
        // }, 200)
    }

    const resetVideo = () => {
        let controlsElement = document.getElementById(`wrapper-controls-media`);
        if (controlsElement) {
            controlsElement.style = null;
        }

        let wrapervideoElement = document.getElementById(`video-slide`);
        if (wrapervideoElement) {
            wrapervideoElement.style = 0;
        }

        let videoElement = document.getElementById(`video-active`);
        if (videoElement) {
            videoElement.src = null;
            videoElement.pause();
        }
    }

    const handleStyleVideo = (indexItem) => {

        let index = indexItem ? indexItem : indexCurrunt;
        let seekBar = document.getElementById(`seek-bar`);
        seekBar.value = 0;
        seekBar.style.backgroundSize = 0;
        if (index === null || medias[index].type !== "video") {
            return false;
        }

        let lazyContainer = document.getElementById(`lazy-video-container-post${medias[index].id}`);
        if (!lazyContainer) {
            return false;
        }

        let wraperVideoElement = document.getElementById(`video-slide`);
        let controlsVideo = document.getElementById(`controls-video-content`);
        let controlsElement = document.getElementById(`wrapper-controls-media`);
        let videoElement = document.getElementById(`video-active`);

        let topItem = lazyContainer.getBoundingClientRect();
        wraperVideoElement.style.width = topItem.width + 'px';
        controlsElement.style.width = topItem.width + 'px';
        controlsElement.style.display = 'block';
        wraperVideoElement.style.height = topItem.height + 'px';
        wraperVideoElement.style.top = 0;
        wraperVideoElement.style.left = 0;
        wraperVideoElement.style.top = 0;
        wraperVideoElement.style.left = 0;
        controlsVideo.style.width = videoElement.offsetWidth+'px';
        if (window.innerWidth >= 915) {
            wraperVideoElement.style.width = topItem.width + 'px';
            wraperVideoElement.style.height = topItem.height + 'px';

        } else {
            wraperVideoElement.style.width = '100%';
            wraperVideoElement.style.height = '100dvh';
        }
    }
    const handleCarousel = (index) => {
        if (index === itemIndexBefore) {
            return false
        }

        setItemIndexBefore(index);
        indexCurrunt = index;
        if (itemIndexBefore !== null && medias[itemIndexBefore].type === "video" && medias[index].type !== "video") {
            resetVideo();
        }

        // if (itemIndexBefore !== null && medias[itemIndexBefore].type === "video") {
        //     // let beforeElement = document.getElementById(`lazy-video-container-post${medias[itemIndexBefore].id}`);
        //     // beforeElement.style.background = '#000';
        //     // beforeElement.innerHTML = `
        //     //     <img
        //     //         src="${APP_CONSTANTS.MEDIA_ENDPOINT + medias[itemIndexBefore].thumb}" class=""
        //     //         id="thumb-video-container-${medias[itemIndexBefore].id}"
        //     //     >`
        // }

        if (medias[index].type === "image") {
            return false;
        }

        let videoElement = document.getElementById(`video-active`);
        videoElement.className = 'video-active';
        videoElement.poster = medias[index].thumb
        videoElement.src = medias[index].url;
        videoElement.load();
        videoElement.onplay = () => {
            handleStyleVideo(index);
        } ;
    }

    const statusFormatter = (currentItem, total) => {
        if (total <= 1) {
            return <></>
        }
        return currentItem + "/" + total
    }

    const handleVideoPlay = (id) => {
        let videoElement = document.getElementById(`video-active`);
        let controlsPlay = document.querySelector('#fa-play' + id);
        let urlVideo = videoElement.getAttribute('src');
        if (urlVideo === 'null') {
            return false;
        }

        if(videoElement.readyState !== 4) {
            return false;
        }
        if (videoElement.paused) {
            videoElement.play();
            if (controlsPlay) {
                controlsPlay.style.display = 'none';
            }
            return false;
        }

        videoElement.pause();
        if (controlsPlay) {
            controlsPlay.style.display = 'block';
        }
    }

    return <div className={`post-detail-media`}>
        <Carousel
            showThumbs={false}
            renderThumbs={false}
            autoFocus={false}
            dynamicHeight={false}
            infiniteLoop={false}
            onChange={handleCarousel}
            selectedItem={selectedItem}
            showArrows={showArrows}
            statusFormatter={statusFormatter}
            // width={widthSlide}
            // autoPlay={true}
            // interval={1000}
        >
            {
                medias.map((item, index) => {
                    if (item.type === 'image') {
                        return <div
                            id={`lazy-img-container-post${item.id}`}
                            key={`img-key-${item.id}`}
                            className={'img-carousel-post'}
                        >
                            <img src={item.url} key={item.id}/>
                        </div>
                    }
                    return <div
                        id={`lazy-video-container-post${item.id}`}
                        key={`video-key-${item.id}`}
                        className={'video-carousel-post'}
                        onClick={() => handleVideoPlay(item.id)}
                    >
                        <img
                            src={item.thumb}
                            key={item.id}
                            id={`thumb-video-container-${item.id}`}

                        />
                        <i className="fa fa-play" id={`fa-play${item.id}`}></i>
                    </div>
                })
            }

        </Carousel>

    </div>
}
import React, {useEffect, useState} from "react";
import ReadMoreComponent from "../../Components/ReadMoreComponent";

function DetailContent({user, content, maxLenght = 300, outsideShowMore = false}) {
    const [showMore, setShowMore] = useState(true)
    useEffect(() => {
        if (!showMore && outsideShowMore) {
            document.addEventListener('mouseup', handleOutsideShowMore);
        } else {
            document.removeEventListener('mouseup', handleOutsideShowMore);
        }
        return () => {
            document.removeEventListener('mouseup', handleOutsideShowMore);
        }
    }, [showMore])

    const handleOutsideShowMore = (e) => {
        var container = document.getElementById('feed-detail-show-more');
        if (!container.contains(e.target)) {
            setShowMore(true)
        }
    }

    return <div className={`feed-detail ${!showMore ? 'show-more' : ''}`} id={`feed-detail-show-more`}>
            <div className={`content-feed-detail`}>
                <h2 className="feed-detail-name">{user.name}</h2>
                {
                    (content) ? <div className="feed-detail-content">
                        <ReadMoreComponent
                            maxLenght={maxLenght}
                            callback={setShowMore}
                            showMore={showMore}
                        >
                            {content}
                        </ReadMoreComponent>
                    </div> : ''
                }
            </div>
        </div>
}

export default DetailContent;

import React, {useState} from 'react';
import {Link} from "react-router-dom";

import ReadMoreComponent from "../ReadMoreComponent";
import TimeAgoComponent from "../TimeAgoComponent";
import APP_CONSTANTS from '../../../Constant/index'
import {commentPostList} from "./CommentRequet";
import {LoadingComment} from "./LoadingComment";
import {getSkipIds, removeCommentChild} from "../../Actions/CommentList";
import {setShowAuth} from "../../Actions/authAction";

function CommentItem({children, item, user, replyComment, hadleDetailMedia, likeComment, deleteComment, commentChildrenList}) {
    const [loading, setLoading] = useState(false)
    const loadCommentChildren = async (item) => {
        let comentIds = item.child.map(({id}) => id);
        let skipId = getSkipIds();
        let ids = comentIds.filter((el) => !skipId.includes(el));
        let id = ids.at(-1);
        let lastID = 0;
        if (id) {
            lastID = id
        }
        setLoading(true);
        await commentChildrenList(item.post_id, item.id, lastID);
        setLoading(false);
    }

    let childLength = item.child.length
    let countReply = item.reply_count - childLength;
    const opentFormReply = () => {
        if (!user) {
            setShowAuth(1);
            return false
        }
        replyComment(item)
    }

    return <div className={`comment-item`} key={`comment-item-${item.id}`}>
        <div className={`comment-item-avatar`}>
            <Link to={`/profile/${item.user.alias}`}>
                <img src={item.user.avatarUrl}/>
            </Link>
        </div>
        <div className={`comment-item-contaiter`}>
            <div className={`comment-username`}>
                <Link to={`/profile/${item.user.alias}`}>
                    <span>{item.user.name}</span>
                </Link>
            </div>
            <div className={`comment-text`}>
                <ReadMoreComponent maxLenght={100}>
                    {item.comment}
                </ReadMoreComponent>
                {
                    item.media ? <div
                        className={`comment-media ${item.media.type}`}
                        key={`comment-media-${item.id}`}
                        onClick={() => hadleDetailMedia(item.media)}
                    >
                        <i className="fa fa-play"></i>
                        {
                            (item.media.type === 'video') ? <img
                                src={item.media.thumb}
                            /> : <img
                                src={item.media.url}
                            />
                        }
                    </div> : ""
                }
                {
                    (item.is_media === 2 && !item.media) ? <div
                        className={`comment-media image loading-media`}
                        key={`loading-media-${item.id}`}
                    >
                        <i className="fa fa-spinner fa-spin"></i>
                    </div> : ""
                }
            </div>
            <div className={`comment-sub-content`}>
                <span className={`comment-time`}><TimeAgoComponent date={item.created_at}/></span>
                <div className={"comment-like-wrapper"}>

                    {
                        (user && user.id === item.isLike) ?
                            <svg
                                fill="currentColor"
                                color="rgba(255, 59, 92, 1)"
                                fontSize="24"
                                viewBox="0 0 48 48"
                                xmlns="http://www.w3.org/2000/svg"
                                width="1em"
                                height="1em"
                                onClick={() => likeComment(item.id, item.parent_id)}
                            >
                                <path
                                    d="M10.4 12.2c3.76-3.68 9.69-3.66 13.6.42 3.91-4.08 9.84-4.1 13.6-.42a9.48 9.48 0 0 1 0 13.63L25.06 38.07a1.5 1.5 0 0 1-2.1 0L10.4 25.83a9.48 9.48 0 0 1 0-13.63Z"></path>
                            </svg> : <svg
                                fill="currentColor"
                                color="#9d9393" fontSize="24"
                                viewBox="0 0 48 48"
                                xmlns="http://www.w3.org/2000/svg"
                                width="1em" height="1em"
                                onClick={() => likeComment(item.id, item.parent_id)}
                            >
                                <path
                                    d="M24 12.62c3.91-4.08 9.84-4.1 13.6-.42a9.48 9.48 0 0 1 0 13.63L25.06 38.07a1.5 1.5 0 0 1-2.1 0L10.4 25.83a9.48 9.48 0 0 1 0-13.63c3.77-3.68 9.7-3.66 13.61.42Zm-1.9 2.36c-2.8-3.2-6.99-3.2-9.61-.63a6.48 6.48 0 0 0 0 9.33L24 34.91l11.51-11.23a6.48 6.48 0 0 0 0-9.33c-2.62-2.56-6.8-2.56-9.6.63l-.05.05-.81.8a1.5 1.5 0 0 1-2.1 0l-.81-.8-.04-.05Z"></path>
                            </svg>
                    }
                    {
                        item.like ? <span className={`comment-like-count`}>{item.like}</span> : ''
                    }
                </div>
                {
                    (user && user.id === item.user_id) ? <>
                        <span className={`comment-delete`} onClick={() => deleteComment(item)}>
                            <i className="fa fa-trash-o"></i>
                        </span>
                    </> : ''
                }
                <span className={`comment-reply`} onClick={() => opentFormReply()}>Reply</span>
            </div>
            {children}
            {loading ? <LoadingComment show={loading}/> : ''}
            {
                (item.reply_count && !loading) ? <div className={`reply-action-container`}>
                    <div className={`wrapper-more`}>
                        <span className={`more-line`}></span>
                        {
                            countReply ? <p className={`view-more`} onClick={() => loadCommentChildren(item)}>
                                View {countReply} more
                                <i className="fa fa-angle-down"></i>
                            </p> : ""
                        }
                        {
                            childLength ? <p
                                className={`view-hide`}
                                onClick={() => removeCommentChild(item.id)}
                            >
                                Hide <i className="fa fa-angle-up"></i>
                            </p> : ""
                        }
                    </div>
                </div> : ''
            }
        </div>
    </div>
}

export default CommentItem;
import React, {useEffect, useMemo, useState} from 'react';
import {useNavigate, useParams} from "react-router-dom";
import ListFeeds from "../../../Components/Feeds";
import {useSelector} from "react-redux";
import Post from "../../../Post";
export default function TabPostComponent({loadding, setLastId}) {
    const user = useSelector(state => state.auth.user);
    const [item, setItem] = useState(null);
    const [mediaIndex, setMediaIndex] = useState(0);
    const listPost = useSelector(state => state.postProfile.list);

    useEffect(() => {
        if (listPost.length !== 0 && item) {
            const index = listPost.findIndex(x => x.id === item.id);
            if (index >= 0) {
                setItem(listPost[index]);
            }
        }
        if (listPost.length) {
            console.log(listPost.at(-1).id)
            setLastId(listPost.at(-1).id);
        }

    }, [listPost]);

    const selectItem = (item, selectedItem = 0) => {
        setItem(item)
        setMediaIndex(selectedItem);
    }

    const handleBack = () => {
        setItem(null);
        setMediaIndex(0);
    }

   const postComponent = useMemo(() =>
       <Post
           item={item}
           mediaIndex={mediaIndex}
           updateItem={setItem}
           backEvent={handleBack}
       />,
       [item]);

    return <>
        <ListFeeds
            selectItem={selectItem}
            items={listPost}
            loadding={loadding}
            handleScroll={() =>{}}
            user={user}
        />
        {
            (item) ? postComponent : ''
        }
    </>
}
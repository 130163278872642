import Axios from "../../../Lib/Axios";
import APP_CONSTANTS from "../../../Constant";
import {saveUser, setShowAuth} from "../../Actions/authAction";

export const loginRequest = async (values) => {
    return Axios.post('auth/login', {
        username: values.email,
        password: values.password
    });
}

export const checkEmailExists = async (email) => {
    return Axios.post('auth/check-email', {email});
}

export const checkUsernameExists = async (username) => {
    return Axios.post('auth/check-username', {username});
}

export const registerRequest = async (values) => {
    return Axios.post('auth/register', values);
}

export const changePasswrordRequest = async (values) => {
    return Axios.put('user/change-password', values);
}

export const getProfile = async () => {
    let userToken = await localStorage.getItem(APP_CONSTANTS.USER_TOKEN);
    // let userStorage = await sessionStorage.getItem(APP_CONSTANTS.USER);
    if (!userToken) {
        return false;
    }

    Axios.get('user/profile').then(result => {
        saveUser(result.data);
    }).catch(error => {
        setShowAuth(1)
    })
}

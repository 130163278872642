
export const gennerateHashTagsImage  = (file, uuid, callBack) => {
    const oImg = document.createElement("img");
    oImg.setAttribute('src', URL.createObjectURL(file));
    oImg.onload = function () {
        classifier.classify(oImg, result => {
            if (!result) {
                return false;
            }
            let hashTagArrya = [];
            for (let i = 0; i < result.length; i++ ){
                let tag = [...hashTagArrya];
                hashTagArrya = tag.concat(result[i].label.split(","));
            }
            callBack({key:uuid, hashTags:hashTagArrya})
        });
    }
}


import React from 'react';
import Skeleton from 'react-loading-skeleton'
import GridItem from "../FormPostComponent/GridItem";
import LoadingComponent from "../LoadingComponent";

export default function FeedSkeleton({number = 4}) {


    return <div className={`feeds`}>
        {
            [...Array(number)].map((x, i) => {
                    return <div className={`feed-item feed-loading`} key={`feed-item-skeleton-${i}`}>
                        <div className={`feed-item-head`}>
                            <div className={`feed-item-head-avatar`}>
                                <Skeleton height={36}/>
                            </div>
                            <div className={`feed-item-head-content`}>
                                <div className={`user-name`}>
                                    <Skeleton/>
                                </div>
                                <div className={`post-time`}>
                                    <Skeleton/>
                                </div>
                            </div>
                            <div className={`feed-item-head-option`}>

                            </div>

                        </div>
                        <div className={`feed-item-content`}>

                            <Skeleton height={250}/>
                        </div>
                        <div className={`feed-item-media-grid`}>
                            {/*<Skeleton height={200}/>*/}

                        </div>
                        <LoadingComponent show={true}/>
                    </div>
                }
            )}
    </div>
}
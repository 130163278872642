import React, {useState} from 'react';
import {useDispatch, useSelector} from "react-redux";

import FormCreateNewFeeds from "./FormCreateNewFeeds";
import {closeForm} from "../../Slice/FormPostSlice";

function FromCreatePost(props) {
    const formPost = useSelector(state => state.formPost);
    const dispatch = useDispatch();
    const close = () => {
        dispatch(closeForm())
    }

    if (!formPost.showForm) {
        return <></>
    }
    return <FormCreateNewFeeds show={formPost.showForm} formData={formPost.postData}  close={close} id={1} />
}

export default FromCreatePost
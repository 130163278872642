import React, {useRef, useState, useEffect, useMemo, memo, use} from 'react';
import FeedItem from "./FeedItem";
import {useSelector} from "react-redux";
import FeedSkeleton from "./FeedSkeleton";
import {getUserAuth} from "../../Actions/authAction";
import fa from "react-timeago/lib/language-strings/fa";
import LoadingComponent from "../LoadingComponent";
import ModalConfirm from "../ModalConfirm";
import {deleteItemRequest} from "../../Post/Component/PostRequest";


export default function ListFeeds({items, selectItem, loadding, handleScroll, user}) {
    const [deleteItem, setDeleteItem] = useState(null);

    const confirmDelete = (item) => {
        deleteItemRequest(item)
        setDeleteItem(null);
    }



    return <>
        <div
            id={`feeds`}
            className={`feeds ${!items.length?`no-item`: ``}`}
            onScroll={(event) => handleScroll(event)}
        >
            {(!items.length && !loadding) ? <p className={'no-post'}>No Post yet</p>: ''}
            {items.map((item, index) => {
                return <FeedItem
                    item={item}
                    key={`feed-key-${index}`}
                    selectItem={selectItem}
                    user={user}
                    deleteItem ={setDeleteItem}
                />
            })}
            {loadding ? <FeedSkeleton number={2}/> : '' }
        </div>
        <ModalConfirm
            title={'Are you sure you want to delete this Post?'}
            confirmDelete={confirmDelete}
            iteam={deleteItem}
            cancel={setDeleteItem}
        />
    </>

};
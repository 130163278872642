import Axios from "../../../Lib/Axios";
import React from "react";
import {gennerateHashTagsImage} from "./GennerateHashTags";

const CHUNK_SIZE = 50000000; // 50MB
export const chunkUpload = (file, sort, uuuid, callbackDone, uploadFileError = null) => {
    let start = 0;
    let end = CHUNK_SIZE;
    let arrayName = file.name.split(".");
    let fileExtension = arrayName.pop();
    let noNumner = 0;
    let uploadDone = 0;
    let uploadError = false;

    while (start < file.size) {
        if (uploadError) {
            break;
        }
        let chunk = file.slice(start, end);
        start = end;
        end = start + CHUNK_SIZE;
        let formData = new FormData();
        formData.append('file', chunk);
        formData.append('name', uuuid);
        formData.append('sort', sort);
        formData.append('fileExtension', fileExtension.toLowerCase());
        formData.append('index', noNumner);
        Axios.post('upload-chunk', formData,{
            timeout:7200000,
            headers: {"Content-Type": "multipart/form-data"}
        }).then(result => {
        }).catch(error => {
            if (uploadFileError) {
                uploadFileError(uuuid);
            }
            uploadError = true;
        }).finally(() => {
            uploadDone++;
            if (uploadDone >= noNumner) {
                callbackDone(uuuid)
            }
        });
        noNumner++;
    }
    return true;
}

export const gennerateHashTagsVideo = (file, uuuid, updateHashtagMedias) => {
    const canvas = document.createElement("canvas");
    const video = document.createElement("video");
    video.autoplay = true;
    video.muted = true;
    video.src = URL.createObjectURL(file);
    video.load();

    video.onloadeddata = () => {
        let duration = (video.duration / 5 > 10)? video.duration/5 : 10;
        let currentTime = 0.1
        video.currentTime = currentTime;
        video.addEventListener("timeupdate", (event) => {
            let ctx = canvas.getContext("2d");
            canvas.width = video.videoWidth;
            canvas.height = video.videoHeight;
            ctx.drawImage(video, 0, 0, video.videoWidth, video.videoHeight);
            video.pause();
            ctx.canvas.toBlob(async (blob) => {
                let file = await new File([blob], ".jpg", {type: "image/jpeg"});
                gennerateHashTagsImage(file, uuuid, updateHashtagMedias)
                currentTime = currentTime + duration;
                if (currentTime > video.duration) {
                    return false
                }
                video.currentTime = currentTime;
            }, "image/jpeg", 0.8 /* quality */);
        });

    };

}

export const deleteChunk = (data) => {
    Axios.post('delete-chunk', data)
}
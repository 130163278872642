import store from "../../../app/store";
import Axios from "../../../Lib/Axios";
import {toastError} from "../../Actions/ToastAction";
import {setListItem, callApiError} from "../../Slice/NewFeedSlice";

export const getListNewFeeds = (ids) => {
    return Axios.post('new-feeds', {ids:ids}).then(result => {
        let converData = pluckPostID(result.data);
        store.dispatch(setListItem({stop:converData.stop,ids:converData.ids, list: result.data}))
    }).catch(error => {
        store.dispatch(callApiError())
        console.log(error)
        toastError('Load new feeds error chay chay');
    }).finally(() => {
        return true
    })
}

export const pluckPostID = (listItem) => {
    let ids = [];
    if (listItem.length < 1) {
        return { stop: true,  ids}
    }

    for (let i = 0; i < listItem.length; i++) {
        ids.push(listItem[i].id);
    }
    return { stop: false, ids }
}

export const getPostDetail = async (id) => {
     const result = await fetchPostDetail(id);

     if (result.status !== 200) {
         getListNewFeeds([]);
         return null;
     }

    getListNewFeeds([id])
    store.dispatch(setListItem({stop:false,ids:[id], list: result.data}))
    return result.data;
}

export const addNewPostToFeed = (data) => {
    store.dispatch(setListItem({stop:false,ids:[data.id], list: data}))
}

export const fetchPostDetail = (id) => {
    return Axios.get(`new-feeds/${id}`).then(result => {
            return result;
    }).catch((error) =>{
        toastError("Feed isn't available right now");
        return error;
    })
}
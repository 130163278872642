import React from 'react';
import APP_CONSTANTS from "../../../Constant";
import imageLoading from "../../../LibMedias/loading.gif";
function GridItem(props) {

    let items = [];
    let loading = false;
    if (props.files) {
        items = props.files;
    }

    if (props.loading) {
        loading = props.loading;
    }

    if (!items.length) {
        return false;
    }

    const controlVideo = (e) => {
        const videoElement = e.currentTarget;
        if(videoElement.currentTime > 0.5) {
            videoElement.pause()
        }
    }

    const itemGrid = (item, count, index, classIten = 'img') => {
        let url = null;
        let uuuid = '';
        if (item.id) {
            url = item.url;
            uuuid = item.id;
            if(item.type === 'video') {
                url = item.thumb;
            }
        } else if(item.type !== 'video') {
            url = URL.createObjectURL(item.file);
            uuuid = item.uuuid;
        } else {
            uuuid = item.uuuid;
            url = item.thumb;
        }

        return <div
            className={`grid-item ${classIten}_${count > 5 ? 5 : count}_${index + 1} video`}
            key={`grid-item-${uuuid}`}
        >
            <img id={`grid-item-${uuuid}`} src={url} draggable={false}/>
            {
                (item.type === 'video') ? <i className={`icon-play`}></i>: ''
            }
        </div>
    }

    let lengthItem = items.length;
    let subIndex = 0
    if (lengthItem < 5) {
        return <div className={`grid-container ${props.grildTemplate}`}>
            <div className={`row-grid-container grid-lenght-${(lengthItem > 5) ? 5 : lengthItem}`}>
                {
                    items.map((item, index) => {
                        if (index > 4) {
                            return null
                        }
                        return itemGrid(item, lengthItem, index)
                    })}
            </div>
        </div>
    }


    return <div className="grid-container">
        <div className={`sub_grid_wrapper`}>
            <div className={`sub_grid_wrapper_first_row grid_wrapper_2_img`}>
                {
                    items.map((item, index) => {
                        if (index > 1) {
                            return null
                        }
                        return itemGrid(item, 2, index)
                    })
                }
            </div>

            <div className={`sub_grid_wrapper_second_row sub_grid_wrapper_3_img`}>
                {
                    items.map((item, index) => {
                        if (index < 2 || index > 4) {
                            return null
                        }

                        if (index === 4 && lengthItem > 5) {
                            return <div className="img sub_img_3_3 show_more_img" key={`sub-img-3-3-${index}`}>
                                {itemGrid(item, 3, index, 'sub_img')}
                                <div className="show_more_text"> +{lengthItem - 5}</div>
                            </div>
                        }

                        let iten = itemGrid(item, 3, subIndex, 'sub_img');
                        subIndex++;
                        return iten;
                    })
                }
            </div>
        </div>
    </div>
}


export default GridItem
import {createSlice} from "@reduxjs/toolkit";
import APP_CONSTANTS from '../../Constant'

const formPostSlice  = createSlice({
    name: "formPostSlice",
    initialState: {
        showForm: false,
        postData: null
    },
    reducers: {
        setPostData: (state, action) => {
            state.postData = action.payload;
        },

        showForm: (state) => {
            state.showForm = true;
            state.postData = null;
        },

        closeForm: (state) => {
            state.showForm = false;
            state.postData = null;
        }
    }
});

const {reducer, actions} = formPostSlice;
export const {setPostData, showForm, closeForm} = actions;
export default reducer;

import React from "react";
import ModalComponent from "./ModalComponent";

function ModalConfirm({iteam, confirmDelete, cancel, title}) {
    if (!iteam) {
        return false;
    }
    const formConfirmDeleteComment = () => {
        document.getElementById("root").classList.add("hide-media-control");
        confirmDelete(iteam);
    }

    const closeFormConfirmDeleteComment = () => {
        document.getElementById("root").classList.remove("hide-media-control");
        cancel(null);
    }

    return <ModalComponent
        show={true}
        classContainer={`confirm-delete-comment`}
        showHeader={false}
    >
        <p>{title}</p>
        <button
            className={`btn-confirm-delete`}
            onClick={() => {
                formConfirmDeleteComment()
            }}
        >
            Delete
        </button>
        <button
            className={`btn-cancel-delete`}
            onClick={() => closeFormConfirmDeleteComment()}
        >
            Cancel
        </button>
    </ModalComponent>
}

export default ModalConfirm
import store from "../../../app/store";

let profileElement;
let userHeadElement;
let feedTabProfileElement;
let heightUserHead = 0;
export const handedScrollProfile = (event) => {
    let windowSize = store.getState().windowSize;

    if (windowSize.width > 820)  return false;

    if (profileElement.scrollTop >= heightUserHead) {
        feedTabProfileElement.classList.add("profile-feed-tab-position");
        return false
    }
    feedTabProfileElement.classList.remove("profile-feed-tab-position");
}

export const getHeightUserHead = () => {
    profileElement = document.querySelector(`.wrapper-profile`);
    if (!profileElement) {
        resetElement();
        return false
    }
    userHeadElement = document.querySelector(`.user-header`);
    feedTabProfileElement = document.querySelector(`.div-share-layout-main .div-video-feed-tab`);
    heightUserHead = userHeadElement.clientHeight
}


export const btnTapMouseOver = (e) => {
    e.target.classList.add("mouse-over-currunt")
    e.target.parentElement.classList.add("tap-mouse-over")
}

export const btnTapMouseOut = (e) => {
    e.target.classList.remove("mouse-over-currunt")
    e.target.parentElement.classList.remove("tap-mouse-over")
}

const resetElement = () => {
     profileElement = null;
     userHeadElement = null;
     feedTabProfileElement = null;
}



import React, {useState, useEffect, useRef} from "react";
import IconNavicon from "../../Components/Icon/IconNavicon";
import TabPostComponent from "./Tab/TabPostComponent";

export default function TabComponent({setLastId, loading}) {
    return <div className="div-share-layout-main">
        <div className="div-video-feed-tab">
            <button
                className={`post active`}
            >
                <IconNavicon/>
                <span>Post</span>
            </button>
        </div>

        <div className="div-three-column-container" style={{display: ""}}>
            <TabPostComponent
                loadding={loading}
                setLastId={setLastId}
            />

        </div>
    </div>
}
import store from "../../app/store";
import {setShowComment} from "../Slice/CommentToggle";
import {setUserData, showForm, removeUserData, setToken} from "../Slice/AuthSlice";
import {eventEmit} from "../../Lib/Socket";

export const showCommentAction = (show, id) => {
    store.dispatch(setShowComment({show, id}));
}

export const saveUser = (userData) => {
    //delete userData.token;
    let user = {...userData};
    eventEmit('subscribeUser', {id: user.id})
    delete user.token;
    delete user.status;
    delete user.online;
    delete user.block;
    delete user.last_online;
    delete user.created_at;
    store.dispatch(setUserData(user));
}

export const setShowAuth = (active) => {
    store.dispatch(showForm(active));
}

export const getUserAuth = () => {
    return store.getState().auth;
}
export const logout = () => {
    clearUserData();
    window.location.reload();
}
export const saveToken = (token) => {
    store.dispatch(setToken(token));
}
export const checkAuth = () => {
    const user = getUserAuth().user;
    if (!user) {
        return false
    }

    return true;
}
export const clearUserData = () => {
    store.dispatch(removeUserData());

}




import Axios from "../../../Lib/Axios";
import {updateBookmark, updateLike, updateCountCommentPost, removeItemInList} from "../../Actions/FeedsAction";
import {removeComment, updateLikeComment} from "../../Actions/CommentList";
import {updateBookmarkProfile, updateLikeProfile} from "../../Actions/PostProfileAction";
let requestLike = false;
let requestBookmark = false;
let requestLikeComment = false;
export const likeRequest = (id) => {
    if (requestLike) return false;
    requestLike = true
    Axios.get(`post/like/${id}`).then(result => {
        updateLike(id, result.data.isLiked, result.data.like)
        updateLikeProfile(id, result.data.isLiked, result.data.like)
    }).finally(() => {
        requestLike = false
    }).catch(error => {

    })
}
export const bookmarkRequest = (id) => {
    if (requestBookmark) return false;
    requestBookmark = true
    Axios.get(`post/bookmark/${id}`).then(result => {
        updateBookmark(id, result.data.isBookmark, result.data.bookmark);
        updateBookmarkProfile(id, result.data.isBookmark, result.data.bookmark);
    }).finally(() => {
        requestBookmark = false
    }).catch(error => {

    })
}
export const likeCommentRequet = (id, parent) => {
    if (requestLikeComment) return false;
    requestLikeComment = true
    Axios.get(`/comment/post/like/${id}`).then(result => {
        updateLikeComment(id, parent, result.data.isLiked, result.data.like)
    }).finally(() => {
        requestLikeComment = false
    }).catch(error => {

    })
}

export const deleteCommentRequest = (iteam) => {
    Axios.delete(`/comment/post/${iteam.id}`).then(result => {
        removeComment(iteam.id, iteam.parent_id, result.data.replyCount);
        updateCountCommentPost(iteam.post_id, result.data.commentCount);
    }).catch(error => {
        console.log(error)
    })
}


export const deleteItemRequest = (iteam) => {
    // removeItemInList(iteam.id);
    // return false;
    Axios.delete(`/post/${iteam.id}`).then(result => {
        removeItemInList(iteam.id);
    }).catch(error => {
        console.log(error)
    })
}




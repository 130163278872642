
import React, {useEffect, useRef, useState} from "react";
import {useSelector} from "react-redux";
import {addEventListenerMousdown, resetVideoPosition} from "../EventListener/FeedItemMouseConmentAndVideo";
import {setMute} from "../Slice/MuteSlice";
import {updateMute} from "../Actions/MuteAction";
import IconMute from "./Icon/IconMute";
import IconUnMute from "./Icon/IconUnMute";
import {handleVideoControll} from "../../Lib/Video";

function VideoElementComponent() {
    const muteState = useSelector(state => state.mute);
    return <>
        <div className={`meidia-video`} id={`video-slide`}>
            <video
                className={`video-active`}
                controls={false}
                playsInline
                muted={muteState.mute}
                // muted={true}
                loop
                autoPlay={true}
                id={`video-active`}
                preload="metadata"
                onLoadedData={(e) => handleVideoControll(e.target)}
            />

        </div>
        <div className={"wrapper-controls-media"} id={`wrapper-controls-media`}>
            <div id={`controls-video-content`}>
                <div className={`wrapper-seek-bar`}>
                    <input type="range" id="seek-bar" min={0} name="seek-bar" step={0.001}/>
                </div>
                <div className="loader-line"></div>
                <button className='btn-volume' type="button" onClick={() => updateMute(!muteState.mute)}>
                    {(muteState.mute) ? <IconMute/> : <IconUnMute/>}
                </button>
            </div>
        </div>
    </>
}

export default VideoElementComponent;
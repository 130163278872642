import React, {useCallback, useMemo, useRef, useState} from 'react';
import IconClose from "../Icon/IconClose";
import imageLoading from "../../../LibMedias/loading.gif";

export default function MediaComment({fileMedia, removeFile, uploadPending}) {

    if (!fileMedia) {
        return <></>
    }

   // let url = URL.createObjectURL(fileMedia.file);
    return <div className={`wrapper-file-comment ${uploadPending?`is-upload`: ``}`}>
        <div className={`file-comment`}>
           <span className={`remove-file`}
                 onClick={() => removeFile()}
           >
               <IconClose/>
           </span>
            {
                (uploadPending) ? <i className="fa fa-spinner fa-spin"></i> : ''
            }
            <img id={`thumbnail-media-comment-${fileMedia.uuuid}`} src={fileMedia.thumb} draggable={false}/>
        </div>

    </div>
}
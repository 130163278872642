import React, {useState, useRef, useEffect} from "react";
import {useFormik} from "formik";

import ModalComponent from "../../Components/ModalComponent";
import {numberCash} from "../../../Lib/Helpers";
import {Link} from "react-router-dom";
import {getListFollowers, getListFollowings} from "./ProfileRequet";
import LoadingComponent from "../../Components/LoadingComponent";
import Skeleton from "react-loading-skeleton";
import iconTotalLike from '../../../LibMedias/heart-shape-emoji.png';
import {followUser, unFollowUser} from "../../Components/FlolowComponent/FollowRequet";

function Followers(props) {
    const userInfo = props.userInfo;
    const tabFollowing = 1;
    const tabFollower = 2;
    const tabLiked = 3;
    const [pageCurrent, setPageCurrent] = useState(1)
    const [loadMore, setLoadMore] = useState(true)
    const [listFollows, setListFollows] = useState([])
    const [isCallApi, setIsCallApi] = useState(false);
    const [isCallFollow, setIsCallFollow] = useState(false);
    const useRefFollow = useRef(null);
    const formik = useFormik({
        validateOnChange: true,
        validateOnBlur: true,
        initialValues: {
            search: ''
        },
        onSubmit: values => {

            handleSubmit(1)
        },
    });

    useEffect(() => {
        formik.resetForm();
        setIsCallApi(false);
        handleSubmit(1);
    }, [props.show]);

    const handleSubmit = (page) => {
        if (!props.activeTab || isCallApi || props.show === tabLiked) {
            return false;
        }
        setIsCallApi(true);
        if (page === 1) {
            setListFollows([]);
        }


        if (props.show === tabFollowing) {
            getListFollowings(userInfo.id, page, formik.values).then(result => {
                handleResult(result.data, page);
            }).catch(error => {
                setListFollows([])
            }).finally(() => {
                setIsCallApi(false);
                setPageCurrent(page)
            })
        }

        if (props.show === tabFollower) {
            getListFollowers(userInfo.id, page, formik.values).then(result => {
                handleResult(result.data, page);
            }).catch(error => {
                setListFollows([])
            }).finally(() => {
                setIsCallApi(false);
                setPageCurrent(page)
            })
        }
    }

    const handleResult = (result, page) => {

        let countItem = result.list.length;
        if (!countItem || countItem < result.limit) {
            setLoadMore(false);
        }

        if (page === 1) {
            setListFollows(result.list)
            return false;
        }

        let list = [...listFollows, ...result.list];
        setListFollows(list)
    }

    const closeList = () => {
        props.close(0)
    }

    if (!props.activeTab || !userInfo) {
        return false;
    }

    const handleScroll = (event) => {
        let element = event.target;

        if (element.scrollHeight - element.scrollTop !== element.clientHeight || !loadMore) return false
        handleSubmit(pageCurrent + 1);
        // let coutListItem = listFollows.length;
        //
        // if (coutListItem === 0) return false
        // console.log(listFollows[coutListItem-1].id)
    }

    const activeTap = (tab) => {
        if (isCallApi || isCallFollow) return false;
        setListFollows([]);
        props.setActiveTab(tab)
    }

    const loadSkeleton = () => {
      //  return <></>
        if (!useRefFollow.current) return <></>;
        let clientHeight = useRefFollow.current.clientHeight;
        return [...Array(Math.round(clientHeight / 80))].map((x, i) =>
            <div className={`list-follow_item`} key={`key-load-${i}`}>
                <div className={`list-follow_item_avatar styled-link`}>
                    <Skeleton circle={true} style={{height: 50, width: 50}}></Skeleton>
                </div>
                <div className={`list-follow_item_content`}>
                    <div className={`list-follow_item_name`}><Skeleton></Skeleton></div>
                    <div className={`list-follow_item_alias`}><Skeleton></Skeleton></div>
                </div>
                <div className={`list-follow_item_status btn-follow`}><Skeleton className={`btn-follow`}/></div>
            </div>
        )
    }

    const unFollow = (id) => {
        if (isCallFollow) return true;
        setIsCallFollow(id);

        unFollowUser(id).then(result => {
            updateStatus(result.data, id, 0);
        }).finally(() => {
            setIsCallFollow(false);
        })
    }

    const follow = (id) => {
        if (isCallFollow) return true;
        setIsCallFollow(id);

        followUser(id).then(result => {
            updateStatus(result.data, id, 1);
        }).finally(() => {
            setIsCallFollow(false);
        })
    }

    const updateStatus = (data, id, status) => {

        setIsCallFollow(false);
        let list = [...listFollows];

        let indexTtem = list.findIndex(item => item.id === id);

        if (indexTtem === undefined) {
            return false;
        }
        list[indexTtem].isFollow = status
        setListFollows(list);
        if (props.isAuthor) {
            let user = {...props.userInfo};
            user.following = data.following;
            user.followers = data.followers;
            props.updateUserInfo(user)
        }
    }

    return <div className="wrapper-list-follow">
        <ModalComponent
            show={props.show}
            close={closeList}
            title={userInfo.name}
            style={{maxWidth: 450}}
        >
            <div className="list-popup-follow">
                <ul className={'ul-tab'}>
                    <li
                        className={(props.show === tabFollowing) ? 'li-tab active' : 'li-tab'}
                        onClick={() => activeTap(1)}
                    >
                        Followings {userInfo.following ? <strong>{numberCash(userInfo.following)}</strong> : ""}
                    </li>
                    <li
                        onClick={() => activeTap(2)}
                        className={(props.show === tabFollower) ? 'li-tab active' : 'li-tab'}
                    >
                        Followers {userInfo.followers ? <strong>{numberCash(userInfo.followers)}</strong> : ""}
                    </li>
                    <li
                        className={(props.show === tabLiked) ? 'li-tab active' : 'li-tab'}
                        onClick={() => activeTap(3)}
                    >
                        Likes {userInfo.likes ? <strong>{numberCash(userInfo.likes)}</strong> : ""}
                    </li>
                </ul>
                {props.show !== tabLiked ? <>
                    <form
                        onSubmit={formik.handleSubmit}
                        autoComplete="off"
                        className={`form-search-follow`}
                    >
                        <div className={'form-group'}>
                            <svg width="24" data-e2e="" height="24" viewBox="0 0 48 48" fill="rgba(22, 24, 35, .34)"
                                 xmlns="http://www.w3.org/2000/svg">
                                <path fillRule="evenodd" clipRule="evenodd"
                                      d="M22 10C15.3726 10 10 15.3726 10 22C10 28.6274 15.3726 34 22 34C28.6274 34 34 28.6274 34 22C34 15.3726 28.6274 10 22 10ZM6 22C6 13.1634 13.1634 6 22 6C30.8366 6 38 13.1634 38 22C38 25.6974 36.7458 29.1019 34.6397 31.8113L43.3809 40.5565C43.7712 40.947 43.7712 41.5801 43.3807 41.9705L41.9665 43.3847C41.5759 43.7753 40.9426 43.7752 40.5521 43.3846L31.8113 34.6397C29.1019 36.7458 25.6974 38 22 38C13.1634 38 6 30.8366 6 22Z"></path>
                            </svg>
                            <input
                                type="search"
                                name="search"
                                className='search'
                                placeholder="Search"
                                value={formik.values.search}
                                onChange={formik.handleChange}
                                maxLength={24}
                            />
                        </div>
                    </form>
                    <div
                        ref={useRefFollow}
                        className={`list-follow`}
                        onScroll={(event) => handleScroll(event)}
                    >
                        {(listFollows.length) ?
                            listFollows.map((item, index) => {
                                return <div className={`list-follow_item`} key={`${item.id}-${index}`}>
                                    <Link to={`/${item.alias}`} className={`list-follow_item_avatar styled-link`}>
                                        <img src={item.avatar}/>
                                    </Link>
                                    <div className={`list-follow_item_content`}>
                                        <div className={`list-follow_item_name`}><Link
                                            to={`/${item.alias}`}>{item.name}</Link></div>
                                        <div className={`list-follow_item_alias`}><Link
                                            to={`/${item.alias}`}>{item.alias}</Link></div>
                                    </div>
                                    <div className={`list-follow_item_status`}>
                                        {(item.isFollow) ?
                                            <button
                                                type="button"
                                                aria-label={item.name}
                                                className={`btn-follow btn-unfollow-button`}
                                                onClick={() => unFollow(item.id)}
                                            >
                                                {(isCallFollow === item.id) ?
                                                    <i className="fa fa-circle-o-notch fa-spin"></i> : 'Following'}

                                            </button>
                                            :
                                            <button
                                                type="button"
                                                aria-label={item.name}
                                                className="btn-follow btn-follow-button"
                                                onClick={() => follow(item.id)}
                                            >
                                                {(isCallFollow === item.id) ?
                                                    <i className="fa fa-circle-o-notch fa-spin"></i> : 'Follow'}
                                            </button>
                                        }
                                    </div>
                                </div>;
                            }) : (isCallApi)? <>{
                                loadSkeleton()
                            }</>: ''
                        }

                        {/*{(isCallApi) }*/}

                        {/*<LoadingComponent show={true} className={`loading-list-follow`}/>*/}

                    </div>

                </> : <div className={`list-follow`}>
                    <div className={`text-total`}>
                        <img src={iconTotalLike}/>
                        <label>Total likes</label>
                        <p>{userInfo.name} receive a total of {numberCash(userInfo.likes)} likes across all video</p>
                    </div>
                </div>}

                <LoadingComponent show={(isCallApi && listFollows.length)} className={`loading-list-follow`}/>
            </div>
        </ModalComponent>
    </div>
}

export default Followers

import React, {useEffect, useState} from "react";
import Parser from 'html-react-parser';
function ReadMoreComponent({children, maxLenght = 70, callback=null, showMore = true}) {
    const [isReadMore, setIsReadMore] = useState(true);
    if (!children) {
        return '';
    }

    useEffect(()=>{
        setIsReadMore(showMore)
    },[showMore])

    const toggleReadMore = () => {
        if (callback) {
            callback(!isReadMore)
        }
        setIsReadMore(!isReadMore);
    };

    let removeHtml = children.replace(/<[^>]*>?/gm, '');
    if (removeHtml.length <= maxLenght) {
        return <div className="text-read-more">
            {Parser(children)}
        </div>;
    }

    return <div className="text-read-more">
            {isReadMore ?  Parser(children.slice(0, maxLenght)) : Parser(children)}
            <span onClick={toggleReadMore} className={["read-or-hide", isReadMore ? '' : 'show-less'].join(' ')}>
            {isReadMore ? "...read more" : " show less"}
            </span>
        </div>;
}

export default ReadMoreComponent;
import React from "react";

export default function IconNavicon() {

    return <svg
        fill="#000000"
        width="35px"
        height="35px"
        viewBox="0 0 50 50"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path d="M10 12h30v4H10z"/>
        <path d="M10 22h30v4H10z"/>
        <path d="M10 32h30v4H10z"/>
    </svg>

}
import store from "../../app/store";
import {showForm, setPostData, closeForm} from "../Slice/FormPostSlice";

export const closeFormAction = () => {
    store.dispatch(closeForm());
}
export const getFormCreatePostAction = () => {
    store.dispatch(showForm());
}


import {createSlice, current} from "@reduxjs/toolkit";

const PostProfileSlice = createSlice({
    name: "PostProfileSlice",
    initialState: {
        list: [],
        ids: [],
        stop: false,
        error: false,
    },
    reducers: {
        setListItemProfile: (state, action) => {
            state.list = state.list.concat(action.payload);
        },
        clearListItemProfile:(state, action) => {
            state.list = [];
        },

        callApiErrorProfile:(state) => {
            state.error = true;
        },

        updateListItemProfile:(state, action) => {
            state.list = [...action.payload];
        },
    }
});

const {reducer, actions} = PostProfileSlice;
export const {
    setListItemProfile,
    clearListItemProfile,
    callApiErrorProfile,
    updateListItemProfile
} = actions;
export default reducer;
import React, {useRef, useState, useEffect, useMemo} from 'react';
import {v4 as uuidv4} from 'uuid';
import ModalComponent from "../ModalComponent";
import FormContentComponent from "./FormContentComponent";
import {getRandomInt, mediaExtension, videoType} from "../../../Lib/Helpers";
import GridItem from "./GridItem";
import FormEditFile from "./FormEditFile";
import {chunkUpload, gennerateHashTagsVideo, deleteChunk} from "./ChunkFile";
import {createPost, updatePost} from "./FormPostRequest";
import {toastError, toastSuccess} from "../../Actions/ToastAction";
import {gennerateHashTagsImage} from "./GennerateHashTags";
import {ffmpegCreateThumbnail} from "../../../Lib/Ffmpeg";
import imageLoading from "../../../LibMedias/loading.gif";

let changeTempale = false;
let timeOutError = false;
let timeThumbnail = null;
let totalFileUpload = 0;
let numberFileChunkDone = 0;
let hashTagMedias = [];

function FormCreateNewFeeds(props) {
    const inputFileRef = useRef(null);
    const [fileMedias, setFileMedias] = useState(props.formData ? props.formData.media : []);
    const [grildTemplate, setGrildTemplate] = useState(props.formData ? props.formData.template : '');
    const [editfiles, setEditfiles] = useState(false);
    const [uploadChunkStatus, setUploadChunkStatus] = useState(false);
    const [chunkUploadDone, setChunkUploadDone] = useState(0);
    const [contentPost, setContentPost] = useState(props.formData ? props.formData.content : null);
    const [disableButtonPost, setDisableButtonPost] = useState(true);
    const [deletePath, setDeletePath] = useState([]);
    const [loadingButon, setLoadingButon] = useState(false);
    const [thumbnails, setThumbnails] = useState([]);
    const [uuidError, setUuidError] = useState([])
    let postId = props.formData ? props.formData.id : null;

    useEffect(() => {
        document.getElementById("root").classList.add("createPost");
        document.getElementById('video-active').pause();
        return () => {
            changeTempale = false;
            totalFileUpload = 0;
            numberFileChunkDone = 0;
            setFileMedias([]);
            setContentPost(null);
            document.getElementById("root").classList.remove("createPost");
        }

    }, []);
    useEffect(() => {
        checkTemplateGrid();
    }, [fileMedias]);

    useEffect(() => {
        updateThumbnail();

    }, [thumbnails]);

    const updateThumbnail = () => {
        if (!thumbnails.length) {
            return false
        }
        clearTimeout(timeThumbnail);
        timeThumbnail = setTimeout(() => {
            let listMedias = [...fileMedias];
            for (let i = 0; i < thumbnails.length; i++) {
                let index = fileMedias.findIndex(x => x.uuuid === thumbnails[i].uuuid);
                if (index < 0) {
                    continue;
                }
                listMedias[index].thumb = thumbnails[i].url
            }
            setFileMedias(listMedias);
        }, 1000);
    }

    useEffect(() => {
        clearTimeout(timeOutError);
        timeOutError = setTimeout( () => {
            for (let ie = 0; ie < uuidError.length; ie++) {
                let index = fileMedias.findIndex(x => x.uuuid === uuidError[ie]);
                if (index < 0) {
                    continue;
                }
                toastError("Loading error file " + fileMedias[index].file.name);
                remmoveFileItem(index);
            }
        }, 1000);
    }, [uuidError]);


    useEffect(() => {
        if ((!contentPost && !fileMedias.length) || uploadChunkStatus) {
            setDisableButtonPost(true);
        } else {
            setDisableButtonPost(false);
        }
    }, [fileMedias, contentPost, uploadChunkStatus]);

    const memoGridItem = useMemo(() => <>
        <GridItem
            files={fileMedias}
            grildTemplate={grildTemplate}
        />
    </>, [fileMedias, grildTemplate]);

    const memoContentPost = useMemo(() => <>
        <FormContentComponent
            onChange={setContentPost}
            content={props.formData ? props.formData.content : ''}
        />
    </>, [contentPost]);

    const callBackSetThumbnail = (thumb) => {
        let thumbnail = [...thumbnails];
        thumbnail.push(thumb);
        setThumbnails(thumbnail)
    }
    const closeForm = () => {
        props.close(false);
    }
    const checkTemplateGrid = async () => {
        let fileLenght = fileMedias.length;
        if (!fileLenght || (fileLenght !== 3 && fileLenght !== 4)) {
            setGrildTemplate('')
            return false
        }

        if (!changeTempale) {
            return false
        }

        let urlFile = '';
        if (!fileMedias[0].id) {
            if (fileMedias[0].type === 'video') {
               urlFile = fileMedias[0].thumb;
           }else {
                urlFile = URL.createObjectURL(fileMedias[0].file);
            }
        } else {
            if (fileMedias[0].type === 'video') {
                urlFile = fileMedias[0].thumb;
            }else {
                urlFile = fileMedias[0].url;
            }
        }
        let imgElement = document.createElement('img');
        imgElement.onload = function () {
            setTeamplateGrid(imgElement.width, imgElement.height);
        };
        imgElement.src = urlFile;
    }

    const setTeamplateGrid = async (width, height) => {
        if (width >= height || width / height >= 0.7) {
            setGrildTemplate('')
            return false;
        }

        let ramdomNumeber = await getRandomInt(10);
        let ramdomTemplate = 'grilLeft';
        if (ramdomNumeber % 2 === 1) {
            ramdomTemplate = 'grilRight'
        }

        setGrildTemplate(ramdomTemplate)
    }

    const handleChangeFile = async (e) => {
        changeTempale = true;
        let allowedExtension = mediaExtension();
        let videoExtension = videoType();
        if (!e.target.files.length) return false;
        let files = e.target.files
        totalFileUpload = files.length;
        let fileItems = [...fileMedias];
        let totalFile = fileItems.length;
        for (let i = 0; i < files.length; i++) {
            if (
                allowedExtension.indexOf(files[i].type) < 0
                || files[i].size > process.env.REACT_APP_MAX_SIZE_UPLOAT
                || totalFile > 15
            ) {
                continue;
            }

            let uuuid = uuidv4();
            totalFile++;
            chunkUpload(files[i], totalFile, uuuid, processUploadFile, setuploadFileError);

            let fileType = 'image';
            if (videoExtension.indexOf(files[i].type) >= 0) {
                gennerateHashTagsVideo(files[i], uuuid, updateHashtagMedias);
                fileType = 'video';
                ffmpegCreateThumbnail(files[i], uuuid, callBackSetThumbnail)
            } else {
                gennerateHashTagsImage(files[i], uuuid, updateHashtagMedias)
            }


            fileItems.push({
                file: files[i],
                type: fileType,
                fileName: files[i].name,
                uuuid: uuuid,
                thumb:imageLoading,
                id: null
            });
        }

        setUploadChunkStatus(true)
        setFileMedias(fileItems);
        e.target.value = null;
    }

    const setuploadFileError = (uuid) => {
        setUuidError([...uuidError, uuid]);
    }

    const updateHashtagMedias = (hashtagItem) => {

        let indexTag = hashTagMedias.findIndex(x => x.key === hashtagItem.key);
        if (indexTag < 0) {
            hashTagMedias.push({
                'key':hashtagItem.key,
                'hashTags': [...new Set(hashtagItem.hashTags)]
            });
            return false;
        }

        let newHastags = hashTagMedias[indexTag].hashTags.concat(hashtagItem.hashTags);
        hashTagMedias[indexTag].hashTags = [...new Set(newHastags)];
    }
    const processUploadFile = () => {
        numberFileChunkDone++;
        if (numberFileChunkDone < totalFileUpload) {
            setChunkUploadDone(numberFileChunkDone);
            return false;
        }
        setUploadChunkStatus(false);
        totalFileUpload = 0;
        setChunkUploadDone(0);
    }

    const clearAllFile = () => {
        let chunkUuuid = [];
        let pathDelete = [];
        hashTagMedias = [];
        let listMedias = [...fileMedias];
        for (let i = 0; i < listMedias.length; i++) {
            if (!listMedias[i].id) {
                chunkUuuid.push(listMedias[i].uuuid)
            } else {
                pathDelete.push(listMedias[i].path)
            }
        }
        setDeletePath(pathDelete);
        setFileMedias([]);
        setGrildTemplate('');
        if (chunkUuuid.length > 0) {
            deleteChunk(chunkUuuid)
        }
    }

    const remmoveFileItem = (index) => {
        let pathDelete = [...deletePath];
        let listMedias = [...fileMedias];
        if (!listMedias[index].id) {
            hashTagMedias.splice(hashTagMedias.findIndex(x => x.key === listMedias[index].uuuid), 1);
            deleteChunk([listMedias[index].uuuid])
        } else {
            pathDelete.push(listMedias[index].path)
        }

        listMedias.splice(index, 1);
        setFileMedias(listMedias);
        setDeletePath(pathDelete);

    }

    const handleSubmit = async () => {
        let parts = [];
        for (let i = 0; i < fileMedias.length; i++) {
            if (fileMedias[i].id) {
                continue;
            }
            parts.push(fileMedias[i].uuuid)
        }

        let hashtags = await getHashTag();
        let data = {
            content: contentPost ? contentPost.replaceAll(' contenteditable="false"', "") : null,
            grildTemplate,
            parts,
            hashtags,
            deletePath,
            hashTagMedias
        }
        setLoadingButon(true)
        if (!postId) {
            createPost(data).then(result => {
                toastSuccess('The post is being processed.');
                closeForm();
            }).catch(error => {
                if (error.status === 500) {
                    alert("Serrver Error")
                    toastError("Serrver Error");
                    return false;
                }
                toastError(error.data.error);
                alert(error.data.error)
            }).finally(() => {
                setLoadingButon(false)
            })
            return false
        }

        updatePost(postId, data).then(result => {
            toastSuccess('The post is being processed.');
            closeForm();
        }).catch(error => {
            if (error.status === 500) {
                alert("Serrver Error")
                toastError("Serrver Error");
                return false;
            }
            toastError(error.data.error);
            alert(error.data.error)
        }).finally(() => {
            setLoadingButon(false)
        })
    }
    const getHashTag = async () => {
        let hashtagElements = document.getElementById("contentEditableField").getElementsByClassName("hashtag");
        let hashtags = [];
        for (let i = 0; i < hashtagElements.length; i++) {
            hashtags.push(hashtagElements[i].textContent.replace("#", ""))
        }

        for (let indexHashtagMedia = 0; indexHashtagMedia < hashTagMedias.length; indexHashtagMedia++) {
            hashtags = hashtags.concat(hashTagMedias[indexHashtagMedia].hashTags)

        }
        return hashtags;
    }

    return <div className={`wrapper-create-post-container`}>
        <FormEditFile
            close={setEditfiles}
            show={editfiles}
            files={fileMedias}
            addFile={inputFileRef}
            removeItem={remmoveFileItem}
        />
        <ModalComponent
            show={props.show}
            close={closeForm}
            showHeader={true}
            title={"Create Posts"}
            classContainer={`create-post-container`}
        >
            <div className={`create-new-feeds`}>
                <div className={`create-new-feeds-content`}>
                    {memoContentPost}
                    <div className={`box-text-add-mediafile`}>
                        {
                            !fileMedias.length ?
                                <div className={`box-icon-empty`} onClick={() => inputFileRef.current.click()}>
                                    <span className={`icon-img-empty`}><i></i></span>
                                    <p>Add photos/video</p>
                                    <p>Limit 15 file, Max 250MB/file</p>
                                </div> : <>
                                    {memoGridItem}
                                    {
                                        uploadChunkStatus ? <>
                                                <div className={`chunk-loading`}>
                                                    {/*<LoadingComponent show={true} className={`loading-list-follow`}/>*/}
                                                    <div className={`chunk-loading-process`}
                                                         style={{width: ((chunkUploadDone / totalFileUpload) * 100) + "%"}}></div>
                                                    <span>Loading {chunkUploadDone} / {totalFileUpload}</span>
                                                </div>

                                            </> :
                                            <div className={`mediafile-action`}>
                                                <button
                                                    className={`edit-media`}
                                                    onClick={() => setEditfiles(true)}
                                                >
                                                    <i></i>Edit
                                                </button>
                                                <button
                                                    className={`add-media`}
                                                    onClick={() => inputFileRef.current.click()}
                                                >
                                                    <i></i> Add photos/video
                                                </button>
                                                <button
                                                    className={`clear-media`}
                                                    onClick={() => clearAllFile()}
                                                >
                                                    <svg width="24" data-e2e="" height="24" viewBox="0 0 48 48"
                                                         fill="rgba(22, 24, 35, .75)"
                                                         xmlns="http://www.w3.org/2000/svg">
                                                        <path fillRule="evenodd" clipRule="evenodd"
                                                              d="M21.1718 23.9999L10.2931 13.1212C9.90261 12.7307 9.90261 12.0975 10.2931 11.707L11.7074 10.2928C12.0979 9.90228 12.731 9.90228 13.1216 10.2928L24.0002 21.1715L34.8789 10.2928C35.2694 9.90228 35.9026 9.90228 36.2931 10.2928L37.7073 11.707C38.0979 12.0975 38.0979 12.7307 37.7073 13.1212L26.8287 23.9999L37.7073 34.8786C38.0979 35.2691 38.0979 35.9023 37.7073 36.2928L36.2931 37.707C35.9026 38.0975 35.2694 38.0975 34.8789 37.707L24.0002 26.8283L13.1216 37.707C12.731 38.0975 12.0979 38.0975 11.7074 37.707L10.2931 36.2928C9.90261 35.9023 9.90261 35.2691 10.2931 34.8786L21.1718 23.9999Z"></path>
                                                    </svg>
                                                </button>
                                            </div>
                                    }
                                </>
                        }
                    </div>
                </div>
                <button
                    className={`btn-post`}
                    disabled={disableButtonPost}
                    onClick={() => handleSubmit()}
                >
                    {
                        (uploadChunkStatus) ? <div className={`chunk-loading`}>
                            {/*<LoadingComponent show={true} className={`loading-list-follow`}/>*/}
                            <div className={`chunk-loading-process`}
                                 style={{width: ((chunkUploadDone / totalFileUpload) * 100) + "%"}}></div>
                            <span>Loading {chunkUploadDone} / {totalFileUpload}</span>
                        </div> : loadingButon ? <i className="fa fa-circle-o-notch fa-spin"></i> : 'Post'

                    }

                </button>
                <input
                    type={`file`}
                    ref={inputFileRef}
                    onChange={handleChangeFile}
                    multiple={true}
                    style={{display: "none"}}
                />

            </div>
        </ModalComponent>
        <div id={`list-mages-test`}>

        </div>
    </div>
}

export default FormCreateNewFeeds
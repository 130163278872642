import store from "../../app/store";
import {setListComment, setSkipCommentId} from "../Slice/CommentSlice";
import {getUserAuth} from "./authAction";

export const updateCommentList = (list) => {
    store.dispatch(setListComment(list));
}
export const getCommentList = () => {
    return store.getState().listComment.list
}
export const pushCommentToList = async (list, parentId = 0) => {
    const comments = [...await getCommentList()];
    if (!parentId) {
        updateCommentList(comments.concat(list));
        return true;
    }

    let indexParent = comments.findIndex(item => item.id === parentId);
    let itemParent = {...comments[indexParent]};
    itemParent.child = itemParent.child.concat(list)
    comments[indexParent] = itemParent;

    updateCommentList(comments);
    return true;
}
export const removeCommentChild = async (parentId = 0) => {
    const comments = [...await getCommentList()];
    let indexParent = comments.findIndex(item => item.id === parentId);
    if (indexParent < 0) {
        return false;
    }
    let itemParent = {...comments[indexParent]};
    let comentIds = itemParent.child.map(({id}) => id);
    let skipId = getSkipIds();
    let ids = skipId.filter((el) => !comentIds.includes(el));
    updateSkipId(ids);
    itemParent.child = [];
    comments[indexParent] = itemParent;
    updateCommentList(comments);
    return true;
}

export const changeCommentItem = async (item) => {
    const comments = [...await getCommentList()];
    const userStore = getUserAuth();
    let userId = userStore.user ? userStore.user.id : null;
    item.child = [];
    if (!item.parent_id) {
        let indexParent = comments.findIndex(x => x.id === item.id);
        if (indexParent < 0) {
            updateCommentList(comments.concat(item));
            pustSkipId(item.id);
            return false
        }
        comments[indexParent] = item;
        updateCommentList(comments);
        return false;
    }

    let indexParent = comments.findIndex(x => x.id === item.parent_id);
    if (indexParent < 0) {
        return false;
    }

    let comment = {...comments[indexParent]}
    let commentChild = [...comment.child]
    let indexChild = commentChild.findIndex(i => i.id === item.id);
    if (indexChild >= 0) {
        commentChild[indexChild] = item;
    } else if (commentChild.length || item.user_id === userId) {
        commentChild.push(item);
        pustSkipId(item.id);
        comment.reply_count = comment.reply_count + 1;
    }

    comment.child = commentChild;

    comments[indexParent] = comment;
    updateCommentList(comments);
}
export const updateSkipId = (ids) => {
    store.dispatch(setSkipCommentId(ids));
}
export const pustSkipId = (id) => {
    const skipId = [...getSkipIds()];
    skipId.push(id);
    store.dispatch(setSkipCommentId(skipId));
}

export const getSkipIds = () => {
    return store.getState().listComment.skipId
}

export const updateLikeComment = async (id, parent, isLike, count) => {
    const comments = [...await getCommentList()];
    if (!parent) {
        let indexComment = comments.findIndex(x => x.id === id);
        if (indexComment < 0) {
            return false;
        }
        let comment = {...comments[indexComment]}
        comment.isLike = isLike;
        comment.like = count;
        comments[indexComment] = comment;
        updateCommentList(comments);
        return false;
    }

    let indexParent = comments.findIndex(x => x.id === parent);
    if (indexParent < 0) {
        return false;
    }

    let commentParent = {...comments[indexParent]}
    let commentChild = [...commentParent.child]
    let indexChild = commentChild.findIndex(i => i.id === item.id);
    if (indexChild < 0) {
        return false;
    }

    commentChild[indexChild].isLike = isLike;
    commentChild[indexChild].like = count;
    commentParent.child = commentChild;
    comments[indexParent] = commentParent;
    updateCommentList(comments);
}

export const removeComment = async (id, parent, replyCount = 0) => {
    let comments = [...await getCommentList()];
    if (!parent) {
        let indexComment = comments.findIndex(x => x.id === id);
        if (indexComment < 0) {
            return false;
        }

        comments.splice(indexComment, 1);
        updateCommentList(comments);
        return false;
    }

    let indexParent = comments.findIndex(x => x.id === parent);
    if (indexParent < 0) {
        return false;
    }

    let commentParent = {...comments[indexParent]}
    let commentChild = [...commentParent.child]
    let indexChild = commentChild.findIndex(i => i.id === id);
    if (indexChild < 0) {
        return false;
    }
    commentChild.splice(indexChild, 1);
    commentParent.child = commentChild;
    commentParent.reply_count = replyCount;
    comments[indexParent] = commentParent;
    updateCommentList(comments);
}



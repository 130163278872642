import React, {useEffect, useMemo, useState} from 'react';
import {useNavigate, useParams} from "react-router-dom";
import {getListNewFeeds, getPostDetail} from "./Component/newFeedsRequest";
import ListFeeds from "../Components/Feeds";
import {useSelector} from "react-redux";
import Post from "../Post";
import ButtonCreateNewFeeed from "../Components/FormPostComponent/ButtonCreateNewFeeed";
import {activeMenu} from "../../Lib/Menu";

let lastScrollY = 0;

function NewFeeds(props) {
    const user = useSelector(state => state.auth.user);
    let {id} = useParams();
    const navigate = useNavigate();
    const [loadding, setLoadding] = useState(false);
    const [item, setItem] = useState(null);
    const [mediaIndex, setMediaIndex] = useState(0);
    const newFeeds = useSelector(state => state.newFeeds);
    useEffect(() => {
        if (newFeeds.list.length === 0 && !id) {
            document.body.scrollTop = 0;
            document.documentElement.scrollTop = 0;
            listNewFeeds([])
        } else {
            getPostSelect(id)
        }
        if (!id) {
            setItem(null);
            setMediaIndex(0)
        }
    }, [id]);

    useEffect(() => {
        activeMenu('menu-home');
        let feedsElement = document.getElementById("feeds")
        if (feedsElement && lastScrollY) {
            feedsElement.scrollTo(0, lastScrollY)
        }
        setTimeout(() => {

        }, 200)

    }, []);

    const getPostSelect = (id) => {
        if (!id) {
            return false;
        }
        let itemSelect = newFeeds.list.find(x => x.id === Number(id));
        if (itemSelect) {
            setItem(itemSelect);
            return false;
        }
        getPostDetail(id).then(result => {
            setItem(result);
        })
    }
    const listNewFeeds = async (ids) => {
        if (loadding || newFeeds.stop) return false;
        setLoadding(true)
        await getListNewFeeds(ids);
        setLoadding(false)
    };

    useEffect(() => {
        if (newFeeds.list.length !== 0 && item) {
            const index = newFeeds.list.findIndex(x => x.id === item.id);

            if (index >= 0) {
                setItem(newFeeds.list[index]);
            }
        }
    }, [newFeeds.list]);

    const handleScroll = (event) => {
        let element = event.target;
        lastScrollY = element.scrollTop;
        let positionScroll = (element.scrollHeight - element.scrollTop) - 20
        let elementHeight = element.clientHeight
        if (positionScroll > elementHeight || loadding) {
            return false
        }
        listNewFeeds(newFeeds.ids);
    }
    const selectItem = (item, selectedItem = 0) => {
        setMediaIndex(selectedItem);
        navigate(`/post/${item.id}`)

    }
    const postComponent = useMemo(() => <Post item={item} mediaIndex={mediaIndex} updateItem={setItem}/>, [item]);

    return <>
        {/*<ButtonCreateNewFeeed/>*/}
        <ListFeeds
            selectItem={selectItem}
            items={newFeeds.list}
            loadding={loadding}
            handleScroll={handleScroll}
            user={user}
        />
        {
            (item) ? postComponent : ''
        }
    </>
}

export default NewFeeds
let menuActiveLog = null;
export const activeMenu = async (active = null) => {
    if (!active && !menuActiveLog) {
        return false
    }

    if (!active) {
        active = menuActiveLog;
    } else {
        menuActiveLog = active;
    }

    const memus = document.querySelectorAll('.menu-site');
    await memus.forEach((menu) => {
        menu.classList.remove('active')
    });

    const menuActive = document.querySelectorAll(`.${active}`);
    menuActive.forEach((menu) => {
        menu.classList.add('active')
    });
}
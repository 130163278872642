import {configureStore} from "@reduxjs/toolkit";

import muteSlice from "../Funlove/Slice/MuteSlice";
import commentToggle from "../Funlove/Slice/CommentToggle";
import windowSizeSlice from "../Funlove/Slice/WindowSizeSlice";
import authSlice from "../Funlove/Slice/AuthSlice";
import formPostSlice from "../Funlove/Slice/FormPostSlice";
import newFeedSlice from "../Funlove/Slice/NewFeedSlice";
import listComment from "../Funlove/Slice/CommentSlice";
import videoSlice from "../Funlove/Slice/VideoSlice";
import ThumbnailSlice from "../Funlove/Slice/ThumbnailSlice";
import FollowSlice from "../Funlove/Slice/FollowSlice";
import PostProfileSlice from "../Funlove/Slice/PostProfileSlice";

const store = configureStore({
    reducer: {
        mute: muteSlice,
        commentToggle: commentToggle,
        windowSize: windowSizeSlice,
        auth: authSlice,
        formPost: formPostSlice,
        newFeeds: newFeedSlice,
        listComment: listComment,
        videos: videoSlice,
        thumbnail:ThumbnailSlice,
        follow: FollowSlice,
        postProfile:PostProfileSlice
    }
});

export default store;

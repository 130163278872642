import React, {useState, useEffect} from "react";

function LoadingComponent(props) {

    if (!props.show) return;

    let className = 'load-ding';
    if (props.className) {
        className = className+' '+props.className
    }
    return  <div className={className}>
            <div></div>
            <div></div>
    </div>
}

export default LoadingComponent;

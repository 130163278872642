import {createSlice, current} from "@reduxjs/toolkit";

const NewFeedSlice = createSlice({
    name: "NewFeedSlice",
    initialState: {
        list: [],
        ids: [],
        stop: false,
        error: false,
    },
    reducers: {
        setListItem: (state, action) => {
            // state.list = [...current(state.list),  action.payload.list];
             state.list = state.list.concat(action.payload.list);
             state.ids = state.ids.concat(action.payload.ids);
             state.stop = action.payload.stop;
             state.error = false;
        },
        clearListItem:(state, action) => {
            state.show = [];
            state.ids = [];
            state.stop = false;
            state.error = false;
        },

        callApiError:(state) => {
            state.error = true;
        },

        updateListItem:(state, action) => {
            state.list = [...action.payload];
        },

        rollbackListItem:(state, action) => {
            state.list = action.payload.list;
            state.ids = action.payload.ids;
            state.stop = false;
            state.error = false;
        },
    }
});

const {reducer, actions} = NewFeedSlice;
export const {
    setListItem,
    clearListItem,
    callApiError,
    updateListItem,
    rollbackListItem
} = actions;
export default reducer;
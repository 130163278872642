import React, {useState} from "react";
import {useFormik} from "formik";
import * as Yup from "yup";

import {loginRequest} from "./AuthRequest";
import {saveToken, saveUser} from "../../Actions/authAction";

function LoginComponent(props) {
    const [validateForm, setValidateForm] = useState(false);
    const [serverError, setServerError] = useState(false);
    const [showPass, setShowPass] = useState(true);
    const [formSubmit, setFormSubmit] = useState(false);
    let timeoutHandler = null;
    const formik = useFormik({
        validateOnChange: true,
        validateOnBlur: true,
        initialValues: {
            email: 'langtuhoabinh2010@gmail.com',
            password: 'Funlove@2002'
        },
        validationSchema: Yup.object({
            email: Yup.string().required("Email or username is required"),
            password: Yup.string().required('Password is required')
        }),

        onSubmit: values => {
            handleSubmit(values);
        },
    });
    const handleSubmit = (values) => {

        if (formSubmit) {
            return false
        }

        setFormSubmit(true);
        loginRequest(values).then(result => {
            saveToken(result.data.token);
            window.location.reload();
            // setTimeout(() => {
            //
            // }, 200);
        }).catch(error => {
            setServerError(true);
        }).finally(() => {
            setFormSubmit(false);
        })
    }

    const onFocus = () => {
        setServerError(false);
    }

    if (timeoutHandler) {
        clearTimeout(timeoutHandler)
    }

    if (!Object.keys(formik.errors).length && formik.values.email !== '' && formik.values.password !== '') {
        timeoutHandler = setTimeout(() => {
            setValidateForm(true);
        }, 500);
    } else {
        timeoutHandler = setTimeout(() => {
            setValidateForm(false)
        }, 500);
    }

    return <>
        <h1 className='auth-title'>Login</h1>
        <form
            onSubmit={formik.handleSubmit}
            className='auth-form'
            autoComplete="off"
        >
            <div className="div-description">Email or username</div>
            <div className={(serverError) ? 'form-group field-error' : 'form-group'}>
                <input
                    type="text"
                    name="email"
                    placeholder="E-mail Address"
                    value={formik.values.email}
                    onChange={formik.handleChange}
                    autoComplete="off"
                    onFocus={onFocus}
                />
                <i className="fa fa-warning"></i>
            </div>

            <div className={(serverError) ? 'form-group field-error' : 'form-group'}>
                <input
                    type={(!showPass) ? 'password' : 'text'}
                    id="password"
                    className="form-control"
                    placeholder="Password"
                    autoComplete="off"
                    name="password"
                    value={formik.values.password}
                    onChange={formik.handleChange}
                    //required="required"
                    onFocus={onFocus}
                />
                <i className="fa fa-warning fa-warning-pass"></i>
                <i
                    className={(!showPass) ? 'show-pass fa fa-eye' : 'show-pass fa fa-eye-slash'}
                    onClick={() => setShowPass(!showPass)}
                />
            </div>
            <div className='forgot'><span onClick={() => props.setShowAuth(3)}>Forgot password?</span></div>
            <div className="form-group">
                <button
                    className="login-btn auth-btn btn"
                    style={{marginBottom: 10}}
                    type="submit"
                    disabled={(!validateForm)}
                >
                    {formSubmit ? <i className="fa fa-circle-o-notch fa-spin"></i> : 'Login'}
                </button>
                {serverError ? <span className="error" id="errorLogin">Username or password doesn't match our records. Try again.</span> : ''}
            </div>
        </form>
    </>

}

export default LoginComponent;


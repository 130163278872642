import React from "react";
import {Link} from "react-router-dom";
import TimeAgoComponent from "../TimeAgoComponent";
import APP_CONSTANTS from "../../../Constant";
import FolowButtonComponent from "../FlolowComponent/FolowButtonComponent";
import {updateFollowUserNewFeed} from "../../Actions/FeedsAction";
import {updateFollowUserVideo} from "../../Actions/VideoAction";
export default function UserInfoContainer({item, user}) {

    const updateFollowNewFeed = (data, status) => {
        updateFollowUserNewFeed(item.user.id, status)
        updateFollowUserVideo(item.user.id, status)
    }

    return <>
        <div className={`feed-item-head-avatar`}>
            <Link to={`/profile/${item.user.alias}`}><img src={item.user.avatarUrl}/></Link>
        </div>
        <div className={`feed-item-head-content`}>
            <div className={`feed-item-head-content-user`}>
                <div className={`user-name`}>
                    <Link to={`/profile/${item.user.alias}`}>{item.user.name}</Link>
                </div>
                <div className={`post-time`}>
                    <TimeAgoComponent date={item.created}/>
                </div>
            </div>
            <div className="btn-info-action">
                <FolowButtonComponent
                    isFollow={item.following}
                    user={user}
                    userInfo={item.user}
                    callBack={updateFollowNewFeed}
                />
            </div>
        </div>

    </>
}
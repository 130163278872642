import React, {useRef, useState} from 'react';
import {v4 as uuidv4} from "uuid";
import {chunkUpload} from "../FormPostComponent/ChunkFile";
import {gennerateHashTagsImage} from "../FormPostComponent/GennerateHashTags";
import {mediaExtension, videoType} from "../../../Lib/Helpers";
import {ffmpegCreateThumbnail} from "../../../Lib/Ffmpeg";
import imageLoading from "../../../LibMedias/loading.gif";
let timeThumbnail = null;
export default function UploadMediaComment({fileMedia, onUpload}) {
    const inputFileRef = useRef(null);
    const handleChangeFile = (e) => {

        if (!e.target.files.length) return false;
        let file = e.target.files[0];
        let allowedExtension = mediaExtension();
        let videoExtension = videoType();
        e.target.value = null;
        if (allowedExtension.indexOf(file.type) < 0 || file.size > process.env.REACT_APP_MAX_SIZE_UPLOAT) {
            return false
        }

        let uuuid = uuidv4();
        onUpload(true)
        chunkUpload(file, 1, uuuid, processUploadFile);
        let fileType = 'image';
        if (videoExtension.indexOf(file.type) >= 0) {
            fileType = 'video';
            ffmpegCreateThumbnail(file, uuuid, callbackThumbnail)
        }
        let thumb = imageLoading;
        if (fileType === 'image') {
            thumb = URL.createObjectURL(file);
        }
        fileMedia({
            file: file,
            type: fileType,
            fileName: file.name,
            thumb: thumb,
            uuuid: uuuid
        })
        e.target.value = null;
    }


    const processUploadFile = () => {
        onUpload(false)
    }
    const callbackThumbnail = (data) => {
        document.getElementById('thumbnail-media-comment-'+data.uuuid).src=data.url
        // clearTimeout(timeThumbnail);
        // timeThumbnail = setTimeout(() => {
        //     let fileDemo = {...fileComment};
        //     fileDemo.thumb = data.url;
        //     console.log(fileDemo)
        //     fileMedia(fileDemo);
        //  //   setFileComment(file);
        // }, 1000);

    }

    return <>
        <div className={`select-file-comment`} onClick={() => inputFileRef.current.click()}><i></i></div>
        <input
            type={`file`}
            ref={inputFileRef}
            onChange={handleChangeFile}
            multiple={false}
            style={{display: "none"}}
        />
    </>
}
import React, {useState, useEffect, useRef} from "react";
import * as moment from 'moment'
import TimeAgo from "react-timeago";
import buildFormatter from "react-timeago/lib/formatters/buildFormatter";
const configTime = {
    prefixAgo: null,
    prefixFromNow: null,
    suffixAgo: '',
    suffixFromNow: 'now',
    second: 'now',
    seconds: 'now',
    minute: '1 minute',
    minutes: '%d minute',
    hour: '1 hour',
    hours: '%d hours',
    day: '1 day',
    days: '%d days',
    month: '1 month',
    months: '%d month',
    year: '1 year',
    years: '%d year',
    wordSeparator: ' '
}

export default function TimeAgoComponent({date}) {
    const formatter = buildFormatter(configTime)
    const start = moment(date);
    const end = moment();
    const duration = moment.duration(end.diff(start));
    if (duration.days() > 7 || duration.months() > 1 || duration.years() > 1 ) {
        return <time>{moment(date).format("YYYY-MM-DD")}</time>
    }

    return <TimeAgo date={date} formatter={formatter}/>
}
const rootElement = document.getElementById("root");
export const controls = (wrapperElement, thumbnailVideoElement) => {
    // return false;
    // let videoControls = document.createElement("div");
    // console.log()
    // videoControls.className = 'wrapper-controls-media';
    // videoControls.id = 'controls-media';
    // let playPauseElement = document.createElement("div");
    // let buttonMuteElement = document.createElement("button");
    // let inputSeekBar = document.createElement("input");
    // playPauseElement.className = 'controls-play-pause';
    // playPauseElement.id = 'controls-play-pause';
    // playPauseElement.onclick = handlePlayAndPause;
    // videoControls.appendChild(playPauseElement);
    //
    // inputSeekBar.type = "range";
    // inputSeekBar.id = "seek-bar";
    // inputSeekBar.step = 0.001;
    // inputSeekBar.min = 0;
    // // inputSeekBar.a
    // // inputSeekBar.width = thumbnailVideoElement.naturalWidth
    //
    // buttonMuteElement.className = 'btn-volume';
    // buttonMuteElement.onclick = handleMute;
    // videoControls.appendChild(inputSeekBar);
    //
    //
    // wrapperElement.appendChild(videoControls)
}


export const setVideoPostion = (idElement) => {
    let wraperVideoElement = document.getElementById(`video-slide`);
    let wrapperControlsElement = document.getElementById(`wrapper-controls-media`);
    let controlsElement = document.getElementById(`controls-video-content`);
    let videoElement = document.getElementById(`video-active`);
    // let seekBarElement = document.getElementById("seek-bar");
    if (window.innerWidth < 932) {

        wraperVideoElement.style = null
        wrapperControlsElement.style = null
        videoElement.style = null
        return false;
    }
    let contentElement = document.getElementById(idElement);
    if (!contentElement) {
        return false;
    }
    let rect = contentElement.getBoundingClientRect();
    wraperVideoElement.style.top = rect.top + 'px'
    wraperVideoElement.style.bottom = rect.bottom + 'px'
    wraperVideoElement.style.right = rect.right + 'px'
    wraperVideoElement.style.left = rect.left + 'px'
    wraperVideoElement.style.width = contentElement.offsetWidth + 'px'
    wraperVideoElement.style.height = contentElement.offsetHeight + 'px'

    videoElement.style.top = rect.top + 'px'
    videoElement.style.bottom = rect.bottom + 'px'
    videoElement.style.right = rect.right + 'px'
    videoElement.style.left = rect.left + 'px'
    videoElement.style.width = contentElement.offsetWidth + 'px'
    videoElement.style.height = contentElement.offsetHeight + 'px';
    wrapperControlsElement.style.right = rect.right + 'px';
    wrapperControlsElement.style.left = rect.left + 'px';
    wrapperControlsElement.style.display = 'block';
    wrapperControlsElement.style.width = contentElement.offsetWidth + 'px'
    wrapperControlsElement.style.height = 10 + "px"
    controlsElement.style.width = contentElement.offsetWidth + 'px'
    // seekBarElement.style.width = "99%"
}
export const resetVideo = () => {

    let wraperVideoElement = document.getElementById(`video-slide`);
    let wrapperControlsElement = document.getElementById(`wrapper-controls-media`);
    let videoElement = document.getElementById(`video-active`);
    wraperVideoElement.style = null
    wrapperControlsElement.style = null
    videoElement.style = null
    videoElement.src = null;
    videoElement.poster = null;
}

export const videoTimeline = (videoElement) => {
    let seekBarElement = document.getElementById("seek-bar");
    seekBarElement.value = videoElement.currentTime;
    seekBarElement.max = videoElement.duration;
    // seekBarElement.style.backgroundSize = 0;
    if (videoElement.videoWidth / videoElement.videoHeight >= 0.7) {
        videoElement.classList.add("full-width");
    } else {
        videoElement.classList.add("full-height");
    }
    videoElement.style.opacity = 1;
    seekBarElement.max = videoElement.duration;
    let thumbStatus = false;
    videoElement.addEventListener("timeupdate", function () {
        seekBarElement.value = videoElement.currentTime;
        seekBarElement.style.backgroundSize = (videoElement.currentTime / videoElement.duration) * 100 + '% 100%';
        if(videoElement.currentTime > 0.2 && videoElement.currentTime < 1) {
            rootElement.classList.add('hide-thumbnail')
        }
        handleVideoReadyState(videoElement.readyState)
    });

    seekBarElement.addEventListener("input", function () {
        seekBarElement.style.backgroundSize = (seekBarElement.value / videoElement.duration) * 100 + '% 100%'
        videoElement.currentTime = seekBarElement.value;
    });
}

let videoLoading = false
export const handleVideoReadyState = (status) => {
    if (!status || status === 2) {
        rootElement.classList.add('video-loading');
        videoLoading = true
        return false;
    }
    if (videoLoading === true) {
        videoLoading = false
        rootElement.classList.remove('video-loading')
    }
}

let timeoutLoadedData
export const handleVideoControll = (videoElement) => {
    clearTimeout(timeoutLoadedData)
    timeoutLoadedData = setTimeout(() => {
        handleVideoReadyState(videoElement.readyState);

    }, 1000);
   // rootElement.classList.add('video-loading');
    videoLoading = false;
    rootElement.classList.remove('video-loading','hide-thumbnail')
    videoTimeline(videoElement)


}

let isVideoPlay = 0;
export const handleVideoPlay = (id) => {
    let videoElement = document.getElementById(`video-active`);
    let controlsPlay = document.querySelector('#fa-play' + id);
    if (videoElement.readyState !== 4) {
        return false;
    }
    if (videoElement.paused) {
        isVideoPlay = 1;
        videoElement.play();
        controlsPlay.style.display = 'none';
        return false;
    }
    videoElement.pause();
    controlsPlay.style.display = 'block';
    return false;
}

let isPlay = false;
export const pauseVideo = () => {
    let videoElement = document.getElementById(`video-active`);
    let urlVideo = videoElement.getAttribute('src');
    if (urlVideo === 'null') {
        return false;
    }

    isPlay = false;
    if (!videoElement.paused) {
        isPlay = true;
    }
    videoElement.pause();
}

export const playVideo = () => {
    let videoElement = document.getElementById(`video-active`);
    let urlVideo = videoElement.getAttribute('src');
    if (urlVideo === 'null' || !isPlay) {
        return false;
    }
    videoElement.play();
}

export const checkVideoPlay = async () => {
    await handleVideoPlay();
    return isVideoPlay;
}

let observer = null;
let timeoutScroll = null;
export const initVideosObserver = (element, observeCallBack) => {
    if (observer) {
        observer.disconnect()
    }

    observer = new IntersectionObserver((entries, observer) => {
        entries.forEach(entry => {
            if (entry.intersectionRatio === 1) {
                clearTimeout(timeoutScroll);
                observeCallBack(Number(entry.target.dataset.idItem), Number(entry.target.dataset.indexItem))
                entry.target.classList.add('active-item')
                timeoutScroll = setTimeout(() => {
                    rootElement.classList.remove("videos-slide-scroll");
                }, 200)
            } else {
                entry.target.classList.remove('active-item')
                rootElement.classList.add("videos-slide-scroll");
            }
        })
    }, {
        root: document.querySelector("#videos-slide"),
        rootMargin: '10px',
        threshold: 1
    })

    const elements = document.querySelectorAll(element)

    elements.forEach(element => {
        observer.observe(element)
    })
}